import { DateV2 } from "../../util/DateV2";
import { DataDRequest } from "../DRequest";
import { ILicenciaConfigOrganizacion, ILicenciaConfigOrganizacionDetalleAdeudo, ILicenciaPlantilla } from "../entidad/Licencia";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

export function _SvLicenciaObtenerConfiguracionOrganizacion(): Promise<DataDRequest.IRequestResponseA<ILicenciaConfigOrganizacion>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ObtenerConfiguracionOrg"
    const params = {
        Id: DataUtil._Usuario.IdOrganizacion
    }
    const evalFechaToleranciaLicenciaItemAdeudo = (d: ILicenciaConfigOrganizacionDetalleAdeudo) => {
        const dtTolerancia = new Date(d.FechaTolerancia)
        const today = new DateV2()._Truncate("Hour")
        return today > dtTolerancia
    }
    if (Object.keys(Global._LICENCIA).length == 0) {
        Global._LICENCIA._GetDetalleAdeudosArray = () => Object.values(Global._LICENCIA.DetalleAdeudos || {})
        Global._LICENCIA._GetAdeudosExpirados = () => Object.values(Global._LICENCIA.DetalleAdeudos || {}).filter(evalFechaToleranciaLicenciaItemAdeudo)
        Global._LICENCIA._HasAdeudosExpirados = () => !Global._LICENCIA.DetalleAdeudos
            ? false
            : Object.values(Global._LICENCIA.DetalleAdeudos).some(evalFechaToleranciaLicenciaItemAdeudo)
        Global._LICENCIA._ValidarEsEscuelaExpirada = (idEscuela: number) => {
            if (!Global._LICENCIA.DetalleAdeudos) return false
            const detalleAdeudo = Global._LICENCIA.DetalleAdeudos[idEscuela]
            if (!detalleAdeudo) return false
            const escuelaExpirada = new DateV2()._Truncate("Hour") > new Date(detalleAdeudo.FechaTolerancia)
            return escuelaExpirada
        }
    }
    if (Global._GLOBAL_CONF.RELEASE_MODE || Global._GLOBAL_CONF.BETA_MODE) {
        Global._LICENCIA.IdConfig = -1
        Global._LICENCIA.ModulosExtra = [1]
        return new Promise(resolve => resolve({ Resultado: 1, Datos: Global._LICENCIA }))
    }
    return DataDRequest._Request5<ILicenciaConfigOrganizacion>(url, "GET", params)
        .then(res => {
            for (const k in Global._LICENCIA) {
                if (k.startsWith("_")) continue
                delete Global._LICENCIA[k]
            }
            for (const k in (res.Datos || {})) Global._LICENCIA[k] = res.Datos[k]
            if (Global._LICENCIA.DetalleAdeudos)
                for (const k in Global._LICENCIA.DetalleAdeudos) {
                    const det = Global._LICENCIA.DetalleAdeudos[k]
                    det.FechaAplicacion += 'T00:00'
                    det.FechaTolerancia += 'T00:00'
                    det.FechaInicio += 'T00:00'
                    det.FechaFin += 'T00:00'
                    det._IdEscuela = +k
                    det._EsExpirado = () => evalFechaToleranciaLicenciaItemAdeudo(det)
                }
            return res
        })
}

export function _SvLicenciaObtenerPlantillas(): Promise<DataDRequest.IRequestResponseA<ILicenciaPlantilla[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ObtenerPlantillas"
    return DataDRequest._Request5<ILicenciaPlantilla[]>(url, "GET", {})
        .then(res => (
            res.Datos.forEach(d => (d.ModulosExtra = (d.ModulosExtra as unknown as string).split(",").map(d => +d) || [])),
            res
        ))
}

export function _SvLicenciaActivarOrganizacion(idLicenciaPlantilla: number | null): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ActivarOrganizacion"
    const params = {
        "Id": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdLicenciaPlantilla": idLicenciaPlantilla || 0
    }
    return DataDRequest._Request5(url, "POST", params)
}