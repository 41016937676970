import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DICC_ALUMNO } from "../../data/modulo/Alumno";
import { _FinanzasObtenerPagosPorRecibirV2 } from "../../data/modulo/FinanzasPagosPendientes";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewFinanzasIngresoUtil } from "../utilView/FinanzasIngresoUtil";
import { CFinanzasTabs } from "./AlumnosPanelV2";

interface IPagoPendiente extends Entidad.IFinanzaPagoPendiente {
    NombreCompletoAlumno: string;
    KinderFiltro: number[];
    StrEscuela: string;
}

type IItemComboYear = UIUtilViewFinanzasIngresoUtil.IItemComboYear
type IItemComboMonth = UIUtilViewFinanzasIngresoUtil.IItemComboMonth

export class UIVentanaFinanzasPagosPendientes extends VentanaGrid<IPagoPendiente> {
    private comboYear: SelectV2<IItemComboYear, "Id", "multiselect">;
    private comboMonth: SelectV2<IItemComboMonth, "Id", "multiselect">;
    private dataBySchool: Map<number, IPagoPendiente[]>;

    private forceDownloadData: boolean;

    constructor(content: d3.Selection<HTMLDivElement, any, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, "finanzaspagospendientes");
    }

    public _OnServiceEvent(requestID: DataModuloMain.TipoRequestMonitorId) {
        super._OnServiceEvent(requestID);
        if (requestID == Entidad.CTipoRequest.Alumno) {
            this.forceDownloadData = false;
            this.GridUpdateData();
        }
    }

    protected GridInitTable() {
        this.dataBySchool = new Map();

        super.GridInitTable();
        this.AjustaDrawGrid();
        this.forceDownloadData = true;
    }

    private AjustaDrawGrid() {
        // Area combos-filtros
        let filterCont = this.ctrlTabla._Control.select(".filter_container");
        let searchwrapper = filterCont.select(".search-wrapper");

        let areaCombos = filterCont.append("div")
            .attr("class", UIUtilGeneral.FBoxOrientation.Horizontal)
            .style("column-gap", "calc(var(--padding2) * 4)")
            .style("padding", "0 var(--padding3) var(--padding2)")
            .style("box-sizing", "border-box")

        searchwrapper.raise();

        this.comboYear = (() => {
            const select = new SelectV2<IItemComboYear, "Id", "multiselect">({
                Parent: areaCombos,
                Type: "multiselect",
                ValueMember: "Id",
                DisplayMember: "Name",
                ListWidth: "200px",
                Data: UIUtilViewFinanzasIngresoUtil._ObtenerAniosDisponibles(this.kinders),
                OnChange: () => {
                    const monthsA = [...this.comboMonth._dataitems.map(d => d.Id)]
                    this.comboMonth._RefreshList()
                    if (this.comboMonth._dataSelected.length == 0) {
                        this.comboMonth._SelectAll(true)
                    } else {
                        const monthsB = [...this.comboMonth._dataitems.map(d => d.Id)]
                        const monthsSelect: string[] = []
                        for (const mB of monthsB) {
                            if (monthsA.includes(mB)) {
                                continue
                            }
                            monthsSelect.push(mB)
                        }
                        this.comboMonth._valueSelect(this.comboMonth._dataValueMemberSelected.concat(...monthsSelect))
                    }
                    this.forceDownloadData = false
                    this.GridUpdateData();
                }
            })
            areaCombos.append(() => ElementWrapper._WrapperToSelectControl(select, _L("time.anio") + ":")
                .style("height", "35px")
                .node())
            return select
        })()

        this.comboMonth = (() => {
            const select = new SelectV2<IItemComboMonth, "Id", "multiselect">({
                Type: "multiselect",
                Parent: areaCombos,
                ValueMember: "Id",
                DisplayMember: "Name",
                ListWidth: "250px",
                Data: () => {
                    const idYears = this.comboYear._dataValueMemberSelected
                    return UIUtilViewFinanzasIngresoUtil._ObtenerMesesDisponibles(idYears)
                },
                OnChange: () => {
                    this.forceDownloadData = false
                    this.GridUpdateData()
                }
            })
            areaCombos.append(() => ElementWrapper._WrapperToSelectControl(select, _L("time.mes") + ":").node())
            return select
        })()

        this.ctrlTabla._Control.select(".space_pie")
            .classed("hide", false)
            .style("display", "flex")
            .style("justify-content", "end")
            .text(_L("finanzaspagospendientes.tag_totalcobrar") + ": " + UIUtilFormat._CurrencyFmt(0));

        // INIT VALUES
        this.comboYear._SelectAll(true)
        this.comboMonth._RefreshList()
        this.comboMonth._SelectAll(true)
        this.GridUpdateData()
    }

    private UpdateCurrentMonthTotal(data: Table.TDataItemTableAux<IPagoPendiente, undefined>[]) {
        const total = this.CalcularTotalCobrar("table", data);

        this.ctrlTabla._Control
            .select(".space_pie")
            .text(_L("finanzaspagospendientes.tag_totalcobrar") + ": " + UIUtilFormat._CurrencyFmt(total));
    }

    // ***********************************************************************
    // Get data
    // ***********************************************************************

    private CalcularTotalCobrar<T extends "table" | "data">(type: T, datos: T extends "table" ? Table.TDataItemTableAux<IPagoPendiente>[] : IPagoPendiente[]): number {
        return datos.reduce((total, d) => (type == "table"
            ? (total + (d as Table.TDataItemTableAux<IPagoPendiente>).data.TotalCobrar)
            : (total + (d as IPagoPendiente).TotalCobrar)), 0)
    }

    // ***********************************************************************
    // Table config
    // ***********************************************************************

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IPagoPendiente[]): Table.ITableMenuDataSelectedOptionConfig<IPagoPendiente>[] {
        return null;
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IPagoPendiente> {
        return {
            IdTabla: "FinanzasIngresos-PagosPendientes",
            Title: "",
            DefaultSort: "NombreCompletoAlumno",
            IdData: "Id",
            MinWidth: 1200,
            Columns: [
                { Field: "NombreCompletoAlumno", Label: "Alumn@", MinWidth: "100px", Width: "15%" },
                { Field: "TotalCargo", Label: "Total de cargo", MinWidth: "80px", Width: "9%" },
                { Field: "TotalDescuentos", Label: "Total de descuento", MinWidth: "90px", Width: "9%" },
                { Field: "TotalCobrar", Label: "Total por cobrar", MinWidth: "80px", Width: "9%" },
                { Field: "Concepto", Label: "Concepto", MinWidth: "80px", Width: "12%" },
                { Field: "FechaAplicacion", Label: "Fecha aplicación", MinWidth: "90px", Width: "8%" },
                { Field: "FechaVencimiento", Label: "Fecha vencimiento", MinWidth: "100px", Width: "8%" },
                { Field: "StrEscuela", Label: "Escuela", MinWidth: "80px", Width: "8%" },
            ]
        }

        // NOTE Al final de la tabla mostrar el total
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IPagoPendiente> {
        return {
            OnEndUpdateDataInView: (currentDataInTable, dataChecked) => {
                let pagos: Table.TDataItemTableAux<IPagoPendiente, undefined>[] = [
                    ...currentDataInTable,
                    ...dataChecked
                ];

                this.UpdateCurrentMonthTotal(pagos);
            },
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IPagoPendiente, undefined, any>>{
                OnStepCellTable: (container, pago, field: keyof IPagoPendiente) => {
                    switch (field) {
                        case "NombreCompletoAlumno":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", pago.IdAlumno);
                            break;
                        case "TotalCargo":
                        case "TotalCobrar":
                            container.text(UIUtilFormat._CurrencyFmt(pago[field]));
                            break;
                        case "TotalDescuentos":
                            container.text(UIUtilFormat._CurrencyFmt(pago[field] || 0));
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", pago.IdAlumno, null, CFinanzasTabs.EdoCuenta);
                            break;
                        case "FechaAplicacion":
                        case "FechaVencimiento":
                            container.text(UIUtilTime._DateFormatStandar(new Date(pago[field])));
                            break;
                    }
                }
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IPagoPendiente>> {
        return [
            { Label: "Nombre", Field: "NombreCompletoAlumno", Level: 1 },
            { Label: "Total de cargo $", Field: "TotalCargo", Type: "number", Level: 1 },
            { Label: "Total de descuento $", Field: "TotalDescuentos", Type: "number", Level: 1 },
            { Label: "Total por cobrar $", Field: "TotalCobrar", Type: "number", Level: 1 },
            {
                Label: "Concepto", Field: "Concepto", Level: 1, Type: "select",
                Options: () => DataModuloMain._GetReqDataArrayByName("FinanzaCargo")
                    .map(d => ({ Id: d.Nombre, Name: d.Nombre }))
                    .concat(this.ctrlTabla._data.map(d => ({ Id: d.Concepto, Name: d.Concepto })))
                    .filter(d => (!!d.Name))
                    .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase())),
            },
            { Label: "Fecha aplicación", Field: "FechaAplicacion", Type: "date", Level: 1 },
            { Label: "Fecha vencimiento", Field: "FechaVencimiento", Type: "date", Level: 1 }
        ]
    }

    // protected GridRefreshKinderList(): void {
    //     super.GridRefreshKinderList();

    //     // const aniosDisponibles = this.ObtenerAniosDisponibles();
    //     // if (aniosDisponibles.length > 0) {
    //     //     let anioInicial = aniosDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b));
    //     //     this.comboYear._UpdateList(aniosDisponibles);
    //     //     this.comboYear._valueSelect(anioInicial?.Id);

    //     //     if (anioInicial) {
    //     //         this.UpdateOrResetMonthSelect(anioInicial);
    //     //     }
    //     // }
    // }

    protected GridOnSyncData() {
        this.forceDownloadData = true;
        return super.GridOnSyncData();
    }

    protected async GridGetData() {
        // console.warn("GridGetData"); // FIXME Al crear la ventana, se repiten dos veces las peticiones. Origen > VentanaGrid, SelectControlAño
        if (this.dataBySchool.size == 0 || this.forceDownloadData) {
            const res = await this.GetPagosPendientesList();
            let hasError: boolean
            res.forEach(d => {
                if (!hasError && d.Resultado <= 0) {
                    hasError = true
                }
                if (d.Resultado > 0) {
                    this.dataBySchool.set(d.Resultado, d.Datos)
                }
            })
            if (hasError) {
                this.notificacion._Mostrar(_L("general.notif_fail_infoupdate"), "ADVERTENCIA")
            }
        }
        const monthFilter = this.comboMonth._dataSelected;
        const dataFilter: IPagoPendiente[] = [];

        this.dataBySchool.forEach((dataEscuela, idEscuela) => {
            dataEscuela.forEach(p => {
                const dt = new DateV2(p.FechaAplicacion)._SetTimeZoneByIdSchool(idEscuela)
                const idValid = monthFilter.some(f => (dt.getFullYear() == f.Year && dt.getMonth() == f.Month))
                if (idValid) {
                    dataFilter.push(p)
                }
            })
        })
        return dataFilter;
    }

    // ***********************************************************************
    //
    // ***********************************************************************

    // ***********************************************************************
    // Data final things
    // ***********************************************************************

    private async GetPagosPendientesList(): Promise<DataDRequest.IRequestResponseA<IPagoPendiente[]>[]> {
        // const escuelas = Array.from(DataModuloEscuela._DiccEscuela.values());
        const result: DataDRequest.IRequestResponseA<IPagoPendiente[]>[] = []
        for (let { IdKinder, Nombre: NombreEscuela } of this.kinders) {
            const res = await _FinanzasObtenerPagosPorRecibirV2(IdKinder)
            if (res.Resultado <= 0) {
                result.push(res as any)
                // resultado = -1;
                continue
            }
            const pagospendientes: IPagoPendiente[] = res.Datos
                .map<IPagoPendiente>(cargoPago => {
                    const alumno = _DICC_ALUMNO.get(cargoPago.IdAlumno);
                    if (!alumno) {
                        console.warn("-d", "Student no found", cargoPago.IdAlumno);
                        return null;
                    }
                    return {
                        ...cargoPago,
                        NombreCompletoAlumno: alumno.NombreCompleto,
                        KinderFiltro: [IdKinder], // FIXME (1) Los pagos van a "cambiar de escuela" si se cambia al alumno. (2) Los pagos no se verán cuando el alumno no esté
                        StrEscuela: NombreEscuela,
                    }
                })
                .filter(d => Boolean(d));

            result.push({
                Resultado: IdKinder,
                Datos: pagospendientes,
            })
        }
        return result
    }

    protected GRID_GetExportarConfig(dataGrid: IPagoPendiente[]): IConfigGridExcelExport<any> {
        type IDataToExportPick = Pick<IPagoPendiente,
            "NombreCompletoAlumno"
            | "TotalCargo"
            | "TotalDescuentos"
            | "TotalCobrar"
            | "Concepto"
            | "FechaAplicacion"
            | "FechaVencimiento"
            | "StrEscuela">;
        type IDataToExportPickAux = { [k in keyof IDataToExportPick]: string };
        interface IDataToExport extends IDataToExportPickAux {
            IdEscuela: number;
        }

        let idsEscuelas = [...new Set(dataGrid.map(d => d.KinderFiltro[0]))];

        return <IConfigGridExcelExport<IDataToExport>>{
            IdsEscuelas: idsEscuelas,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IDataToExport>>(d => ({
                    Field: d.Field as keyof IDataToExport,
                    HeaderTag: d.Label,
                    WidthCell: d.Field == "NombreCompletoAlumno" ? 35 : 25
                })),
            OnGetDataBySheets: async () => {
                const groupedDataExport: Map<number, IDataToExport[]> = new Map(idsEscuelas.map(idEscuela => ([idEscuela, []])));
                const groupedDataCalc = d3Group(dataGrid, d => d.KinderFiltro[0])

                for (let item of dataGrid) {
                    let dPago = this.ctrlTabla._SelectItemData(item.Id);
                    let idEscuela = dPago.Data.KinderFiltro[0];
                    let datosEnEscuela = groupedDataExport.get(idEscuela);

                    datosEnEscuela.push({
                        IdEscuela: idEscuela,
                        NombreCompletoAlumno: dPago.Data.NombreCompletoAlumno,
                        TotalCargo: UIUtilFormat._CurrencyFmt(dPago.Data.TotalCargo),
                        TotalDescuentos: UIUtilFormat._CurrencyFmt(dPago.Data.TotalDescuentos),
                        TotalCobrar: UIUtilFormat._CurrencyFmt(dPago.Data.TotalCobrar),
                        Concepto: dPago.Data.Concepto,
                        FechaAplicacion: UIUtilTime._DateFormatStandar(dPago.Data.FechaAplicacion),
                        FechaVencimiento: UIUtilTime._DateFormatStandar(dPago.Data.FechaVencimiento),
                        StrEscuela: dPago.Data.StrEscuela
                    });
                }

                return Array.from(groupedDataExport)
                    .map(([idEscuela, datosEscuela]) => ({
                        IdSheet: idEscuela,
                        SheetName: datosEscuela[0].StrEscuela,
                        Data: datosEscuela,
                        ExtraPie: [{
                            Label: _L("finanzaspagospendientes.tag_totalcobrar"),
                            Value: UIUtilFormat._CurrencyFmt(this.CalcularTotalCobrar("data", groupedDataCalc.get(idEscuela))),
                            Alignment: "right",
                        }]
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].StrEscuela
        }
    }
}
