import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataModuloMain } from "../ModuloMain";
import { DataSecurity } from "../Security";
import { DataIndexedDB } from "../indexedDB/DB";
import { DMUsuarioSesion } from "../model/MUsuarioSesion";
import { _SvAdmin_GetVersionConfig } from "../modulo/Admin";
import { DataUtilLocalStorage } from "./LocalStorage";

export namespace DataUtil {
    export let _ConsoleInDebugMode = (Global._GLOBAL_CONF.DEBUG_MODE || Global._GLOBAL_CONF.BETA_MODE);

    const DB_TABLESINFO = new Array<Entidad.ITabla>();
    /** Map<storeName, Entidad.ITabla> */
    const DB_TABLESINFO_MAP = new Map<string, Entidad.ITabla>();
    const DB_APPTABLES = {
        tblUsuarioSesion: "IdUsuario",
        tblAcciones: "IdAccion",
        tblModulos: "IdModulo",
        tblPermisosDisponibles: "IdPermiso",
        tblPermisosCurrentUser: "IdAsignacionPermiso",
        tblViewInfo: "Id",
        // tblAdminVersionInfo: "versionCode" // deprecated
    }

    export async function _Init(): Promise<boolean> {
        InitTablasInfo();
        // DataIndexedDB._Init(DB_NAME);
        return await DataSecurity._Ini("contraseña", "elevenminds", "untextochido");
    }

    // **********************************************************************************************
    // Tables
    // **********************************************************************************************

    function InitTablasInfo() {
        for (let tableName in DB_APPTABLES) {
            const storeInfo = CrearEntidadTabla(DB_TABLESINFO.length, tableName, DB_APPTABLES[tableName], ["data"]);
            DB_TABLESINFO.push(storeInfo);
            DB_TABLESINFO_MAP.set(tableName, storeInfo);
        }

        CreateServiceTablesInfo("tbl", DB_TABLESINFO.length, ["data"]);
        CreateServiceTablesInfo("tblFecha", DB_TABLESINFO.length + 1, ["fecha"], "Id");
    }

    function CreateServiceTablesInfo(tablePrefix: string, startIdentifier: number, columns: string[], tablePK: string = null) {
        let identifier = startIdentifier;
        let modulesConfig = DataModuloMain._GetModulesConfig();

        for (let key in modulesConfig) {
            let requestName = key as DataModuloMain.TipoRequestMonitorName;
            let moduleConfig = modulesConfig[requestName];

            if (_EvalItemEnvironment(moduleConfig.Environment) && moduleConfig.SaveInLocalBD) {
                const idTabla = (tablePK || moduleConfig.IdData);
                const finalStoreName = tablePrefix + requestName;
                const storeInfo = CrearEntidadTabla(identifier, finalStoreName, idTabla, columns);
                DB_TABLESINFO.push(storeInfo);
                DB_TABLESINFO_MAP.set(finalStoreName, storeInfo);
                identifier++;
            }
        }
    }

    function CrearEntidadTabla(id: number, table: string, key: string, columns: Array<string>): Entidad.ITabla {
        return {
            ID: id,
            storeName: table,
            key,
            columns,
        }
    }

    export function _GetDBTableKey(storeName: string): Entidad.ITabla | null {
        return DB_TABLESINFO_MAP.get(storeName) || null;
    }

    // **********************************************************************************************
    // User
    // **********************************************************************************************

    export var _Usuario: DMUsuarioSesion = null;
    /** @example Promise<[tblExist, User]> */
    export async function _GetInfoUsuario(): Promise<[boolean, Entidad.IUsuarioSesion]> {
        let userSesion: Entidad.IUsuarioSesion = null;
        let tblExist = await DataIndexedDB._StoreExists("tblUsuarioSesion")
            .then(res => res)
            .catch((res) => false);

        if (tblExist) {
            userSesion = await DataIndexedDB._GetRows<Entidad.IUsuarioSesion>("tblUsuarioSesion")
                .then((users) => (users[0] || null))
                .catch(() => null);

            _Usuario = new DMUsuarioSesion(userSesion); // FIXME puede quedarse con un usuario diferente WKr
        } else {
            _Usuario = new DMUsuarioSesion(null); // FIXME
        }

        return [tblExist, userSesion];
    }

    export async function _GuardarUsuario(usuario: Entidad.IUsuarioSesion): Promise<boolean> {
        // console.debug("Creating stores", DB_TABLESINFO);
        if (!await DataIndexedDB._DBDelete(false) || !await DataIndexedDB._CreateStores(DB_TABLESINFO)) {
            console.warn("Error to create tables!");
            return false;
        }
        // //  console.debug("Saving user");

        var res = new Array();
        res.push(usuario);


        return await DataIndexedDB._SetRowsV2({ storeName: "tblUsuarioSesion", listRows: res });

        // return new Promise(function (result, resolve) {
        //     result(true)
        // });
    }

    // **********************************************************************************************
    // Version
    // **********************************************************************************************

    type IAdminVersionInfo = Entidad.IAdminVersionInfo;

    function GetLocalStorageVersionInfo(): IAdminVersionInfo {
        if (!DataUtilLocalStorage._HasItem("appvinfo", Global._APP_VERSION_BASE)) {
            if (localStorage.length) {
                console.debug("STORAGE -> Limpiando info de versión anterior", localStorage.length, "items");
                DataUtilLocalStorage._RemoveGroup("appvinfo");
                DataUtilLocalStorage._RemoveAll(); // REMOVER en v1.1.9
            }
            return null;
        }

        return <IAdminVersionInfo>{
            [Global._APP_VERSION_BASE + ""]: DataUtilLocalStorage._GetItem("appvinfo", Global._APP_VERSION_BASE),
            "versionCode": Number(DataUtilLocalStorage._GetItem("appvinfo", "versionCode")),
            "version": DataUtilLocalStorage._GetItem("appvinfo", "version"),
            "versionName": DataUtilLocalStorage._GetItem("appvinfo", "versionName"),
            "logout": DataUtilLocalStorage._GetItem("appvinfo", "logout") == "true",
            "forceMatchUIVersion": DataUtilLocalStorage._GetItem("appvinfo", "forceMatchUIVersion") == "true",
            "releaseDate": DataUtilLocalStorage._GetItem("appvinfo", "releaseDate"),
        };
    }

    function SetLocalStorageVersionInfo(versionData: IAdminVersionInfo) {
        DataUtilLocalStorage._SetItem("appvinfo", Global._APP_VERSION_BASE, new Date().toISOString());
        DataUtilLocalStorage._SetItem("appvinfo", "versionCode", versionData.versionCode + "");
        DataUtilLocalStorage._SetItem("appvinfo", "version", versionData.version);
        DataUtilLocalStorage._SetItem("appvinfo", "versionName", versionData.versionName);
        DataUtilLocalStorage._SetItem("appvinfo", "logout", versionData.logout + "");
        DataUtilLocalStorage._SetItem("appvinfo", "forceMatchUIVersion", versionData.forceMatchUIVersion + "");
        DataUtilLocalStorage._SetItem("appvinfo", "releaseDate", versionData.releaseDate);
    }

    export async function _GetUpdatedVersionInfo(forceRequest = true): Promise<IAdminVersionInfo | null> {
        return new Promise(async (resolve, reject) => {
            let localStorageInfo = GetLocalStorageVersionInfo();

            if (!localStorageInfo && forceRequest) {
                const resUpdatedVInfo = await _SvAdmin_GetVersionConfig(Global._APP_VERSION_BASE);

                if (resUpdatedVInfo.Resultado > 0) {
                    localStorageInfo = resUpdatedVInfo.Data;
                    SetLocalStorageVersionInfo(localStorageInfo);
                }
            }

            resolve(localStorageInfo);
        })
    }

    export function _UpdateVersionInfo<K extends keyof IAdminVersionInfo & string>(key: K, val: IAdminVersionInfo[K]) {
        if (key != Global._APP_VERSION_BASE) {
            DataUtilLocalStorage._SetItem("appvinfo", key + "", val + "");
        }
    }

    // **********************************************************************************************
    // General
    // **********************************************************************************************

    export type TEnvironment = "debug" | "beta" | "release";
    /**
     * @param environment `TEnvironment`
     * @param includeLowLevel Toma a `environment` como el entorno máximo
     * @returns `boolean`
     */
    export function _EvalItemEnvironment(environment: TEnvironment, includeLowLevel = false) {
        if (environment == null) {
            return true;
        }
        const fnEval = (environment: TEnvironment) => {
            switch (environment) {
                case "beta":
                    return Global._GLOBAL_CONF.BETA_MODE;
                case "debug":
                    return Global._GLOBAL_CONF.DEBUG_MODE;
                case "release":
                    return Global._GLOBAL_CONF.RELEASE_MODE;
                default:
                    return true;
            }
        }
        if (includeLowLevel) {
            const envs: TEnvironment[] = ["release", "beta", "debug"];
            const envIndex = envs.indexOf(environment);
            const envsRes: TEnvironment[] = [];
            for (let i = envIndex; i < envs.length; i++) {
                envsRes.push(envs[i]);
            }
            return envsRes.some((env) => fnEval(env));
        }
        return fnEval(environment);
    }

    export async function _BuildOpenPay() {
        OpenPay.setId(Global._GLOBAL_CONF.IncOpenPay_MerchantId);
        OpenPay.setApiKey(Global._GLOBAL_CONF.IncOpenPay_PublicKey);
        OpenPay.setSandboxMode(Global._GLOBAL_CONF.IncOpenPay_SandboxMode);
    }

    /** @deprecated */
    export interface IRequestResult<T> {
        data: T
        finish: () => Promise<void>
        error: Error
    }

    type TItemMaxDate<TD> = { [key: string]: TD };
    const REQUESTSMAXDATES: TItemMaxDate<TItemMaxDate<string>> = {};

    export function _SaveDateInMap(requestID: DataModuloMain.TipoRequestMonitorId, dtMax: string, id: number = requestID) {
        if (!REQUESTSMAXDATES[requestID]) {
            REQUESTSMAXDATES[requestID] = {};
        }
        REQUESTSMAXDATES[requestID][id] = dtMax;
    }

    export async function _SaveDate(requestID: DataModuloMain.TipoRequestMonitorId, dtMax: string, id: number = requestID): Promise<boolean> {
        const moduleConfig = DataModuloMain._GetModuleConfig(requestID as any);

        if (!moduleConfig || !_EvalItemEnvironment(moduleConfig.Environment)) {
            return false;
        }

        const moduleSaveData = moduleConfig.SaveInLocalBD && moduleConfig.__StorageAvailable;
        const requestName = Entidad.CTipoRequest[requestID];
        const resSave = [{
            Id: id,
            fecha: dtMax
        }]

        // console.info(requestName, "=> Saving date");
        let saved = false;
        if (moduleSaveData) {
            await DataIndexedDB._SetRowsV2({ storeName: "tblFecha" + requestName, listRows: resSave, encrypted: false })
                .then(() => {
                    _SaveDateInMap(requestID, dtMax, id);
                    // console.debug(requestName, "=> Fecha maxima guardada:", dtMax);
                    saved = true;
                })
                .catch((err) => {
                    // console.warn(requestName, "=> Error al guardar fecha:", err);
                })
        }
        else {
            _SaveDateInMap(requestID, dtMax, id);
            saved = true;
        }
        // console.debug(requestName, "=> Fecha maxima guardada:", dtMax, saved);
        return saved;
    }

    export function _GetMaxDateFromLocalTbl(requestID: DataModuloMain.TipoRequestMonitorId, id = requestID): Promise<string | null> {
        const requestName = Entidad.CTipoRequest[requestID];
        return DataIndexedDB._GetRowById("tblFecha" + requestName, id, false)
            .then(res => (res ? res["fecha"] : null))
            .catch(() => null);
    }

    export async function _GetMaxDate(requestID: DataModuloMain.TipoRequestMonitorId, id: number = requestID): Promise<string> {
        const moduleConfig = DataModuloMain._GetModuleConfig(requestID);

        if (!moduleConfig || !_EvalItemEnvironment(moduleConfig.Environment)) {
            return null;
        }

        const moduleSaveData = moduleConfig.SaveInLocalBD && moduleConfig.__StorageAvailable;
        var strDtMaxRemote: string = null;

        if (new Object(REQUESTSMAXDATES).hasOwnProperty(requestID) && new Object(REQUESTSMAXDATES[requestID]).hasOwnProperty(id)) {
            strDtMaxRemote = REQUESTSMAXDATES[requestID][id];
        }
        else if (moduleSaveData) {
            strDtMaxRemote = await _GetMaxDateFromLocalTbl(requestID, id);
            if (strDtMaxRemote) {
                _SaveDateInMap(requestID, strDtMaxRemote, id);
            }
        }

        // console.warn("IDREQUEST_" + Entidades.CTipoRequest[requestID].toUpperCase(), requestID, "ID", id, "MaxDate", strDtMaxRemote);
        return strDtMaxRemote;
    }

    // CONSOLE OVERRIDE

    const _fnLog = console.log;
    const _fnDebug = console.debug;
    const _fnWarn = console.warn;
    const _fnInfo = console.info;
    export function _RefreshConsoleMode() {
        if (Global._DEVELOPMENT_UTILS || _ConsoleInDebugMode) {
            console.log = _fnLog;
            console.debug = _fnDebug;
            console.warn = _fnWarn;
            console.info = _fnInfo;
        }
        else if (!_ConsoleInDebugMode) {
            console.log = function () {
                if (Global._DEVELOPMENT_UTILS || _ConsoleInDebugMode) {
                    _fnLog.apply(console, arguments as any); // DOTEST
                }
            }

            console.debug = function () {
                if (Global._DEVELOPMENT_UTILS || _ConsoleInDebugMode) {
                    _fnDebug.apply(console, arguments as any); // DOTEST
                }
            }

            console.warn = function () {
                let d = arguments[0];
                let dev = (d == "-d");
                if (Global._DEVELOPMENT_UTILS || _ConsoleInDebugMode || !dev) {
                    if (dev) {
                        arguments[0] = "DEV:";
                    }
                    _fnWarn.apply(console, arguments as any); // DOTEST
                }
            }

            console.info = function () {
                let d = arguments[0];
                let dev = (d == "-d");
                if (Global._DEVELOPMENT_UTILS || _ConsoleInDebugMode || !dev) {
                    if (dev) {
                        arguments[0] = "DEV:";
                    }
                    _fnInfo.apply(console, arguments as any); // DOTEST
                }
            }
        }
    }
    _RefreshConsoleMode();
}
