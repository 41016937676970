import saveAs from "file-saver";
import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataModuloMain } from "../ModuloMain";
import { DataUtil } from "../util/Util";
import DataModuloHorarioAlumno from "./HorarioAlumno";

export const _DiccGrupo: Map<number, IGrupo> = new Map();
import CDia = Entidad.CDiaSemanal;
import IGrupo = Entidad.IGrupo;
import IEscuela = Entidad.IEscuela;
const REQUESTID = Entidad.CTipoRequest.Grupo;
const URL_SVCGRUPO = Global._GLOBAL_CONF.URL_RSTV2 + "grupo/";

export async function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IGrupo>[]> {
    const url = URL_SVCGRUPO + "ObtenerListaV2";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(REQUESTID, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, REQUESTID, IdKinder);
        })
}

export async function _AltaGrupoV2(grupo: Pick<IGrupo, "Nombre" | "IdKinder" | "IdEscolaridad" | "IdNivel" | "EsPrincipal">) {
    const url = URL_SVCGRUPO + "NuevoGrupoV2";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Nombre": grupo.Nombre,
        "IdEscuela": grupo.IdKinder,
        "IdEscolaridad": grupo.IdEscolaridad,
        "IdGrado": grupo.IdNivel,
        "EsPrincipal": grupo.EsPrincipal,
    }

    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

/**
 * @param idGrado == EntidadGrupo.IdNivel
 */
export async function _ActualizarGrupoV3(idGrupo: number, nombre: string, idGrado: number, idEscolaridad: number, esPrincipal: boolean) {
    const url = URL_SVCGRUPO + "ActualizarInfoV2";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdGrupo": idGrupo,
        "IdGrado": idGrado,
        "IdEscolaridad": idEscolaridad,
        "Nombre": nombre,
        "EsPrincipal": esPrincipal
    }

    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

interface IActualizaHrOperacionResponse {
    IdAlumno: number;
    IdGrupo: number;
    IdHorario: number;
    // Valido: boolean;
}

export async function _ActualizarHorarioOperacion(idGrupo: number, horarios: Entidad.IHorarioDia[]): Promise<DataDRequest.IResultadoPeticion<IActualizaHrOperacionResponse[]>> {
    var url = URL_SVCGRUPO + "ActualizarHrOperacion"; //"AdminJS/Grupo/ActualizarGrupoV3"; // "Grupo/ActualizarGrupo";

    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdGrupo": idGrupo,

        "LunesEntrada": horarios?.find(d => d.IdDia == CDia.Lunes)?.Entrada || null, // "2020-01-01T13:00:00.000Z",
        "LunesSalida": horarios?.find(d => d.IdDia == CDia.Lunes)?.Salida || null,
        "MartesEntrada": horarios?.find(d => d.IdDia == CDia.Martes)?.Entrada || null,
        "MartesSalida": horarios?.find(d => d.IdDia == CDia.Martes)?.Salida || null,
        "MiercolesEntrada": horarios?.find(d => d.IdDia == CDia.Miercoles)?.Entrada || null,
        "MiercolesSalida": horarios?.find(d => d.IdDia == CDia.Miercoles)?.Salida || null,
        "JuevesEntrada": horarios?.find(d => d.IdDia == CDia.Jueves)?.Entrada || null,
        "JuevesSalida": horarios?.find(d => d.IdDia == CDia.Jueves)?.Salida || null,
        "ViernesEntrada": horarios?.find(d => d.IdDia == CDia.Viernes)?.Entrada || null,
        "ViernesSalida": horarios?.find(d => d.IdDia == CDia.Viernes)?.Salida || null,
        "SabadoEntrada": horarios?.find(d => d.IdDia == CDia.Sabado)?.Entrada || null,
        "SabadoSalida": horarios?.find(d => d.IdDia == CDia.Sabado)?.Salida || null,
        "DomingoEntrada": horarios?.find(d => d.IdDia == CDia.Domingo)?.Entrada || null,
        "DomingoSalida": horarios?.find(d => d.IdDia == CDia.Domingo)?.Salida || null,
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: DataDRequest.IResultadoPeticion<IActualizaHrOperacionResponse[]>) => {
            resolve({
                Resultado: (result?.Resultado || -100),
                Data: result?.Data,
                TipoRequest: REQUESTID
            });
        })
    })
}

export function _EliminarGrupoV2(idGrupo: number) {
    const url = URL_SVCGRUPO + "EliminarGrupoV2";
    const params = {
        "IdGrupo": idGrupo,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    };

    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

export interface IGrupoCargaMasivaV2 extends Pick<IGrupo, "Nombre" | "EsPrincipal"> { }
export function _CargaMasivaV2(idEscuela: number, idEscolaridad: number, idGrado: number, grupos: IGrupoCargaMasivaV2[]) {
    const url = URL_SVCGRUPO + "GruposCargaMasivaV2";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela,
        "IdEscolaridad": idEscolaridad,
        "IdGrado": idGrado,
        "Grupos": grupos
    };

    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

export async function _DownloadExcelTemplate(): Promise<boolean> {
    const url = Global._GLOBAL_CONF.URL_SrvRoot + "archivo/importar/plantilla/ObtenerExcel?IdEscuela=0&IdCategoria=21";
    const filename = "Grupos.xlsx";

    return DataDRequest._XMLHttpRequestFile(url, filename)
        .then(file => {
            saveAs(file)
            return true
        })
        .catch(() => false)
}

// /** Resultado
//  * * (> 0) - Exitoso
//  * * (-1) - Grupo no encontrada
//  * * (-2) - Calendario no configurado (no vinculado)
//  */
// export async function _AsignarCalendarioGoogle(idGrupo: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
//     const url = URL_SVCGRUPO + "AsignarCalendario";
//     const params = {
//         "IdUsuario": DataUtil._Usuario.IdUsuario,
//         "IdGrupo": idGrupo
//     }

//     return new Promise(async (resolve) => {
//         await DataDRequest._Request2<{ Resultado: number }>(url, "POST", params)
//             .then((res) => {
//                 resolve({
//                     Resultado: res.data?.Resultado ? res.data.Resultado : -10,
//                     TipoRequest: REQUESTID
//                 })
//             })
//     })
// }

// ********************************************************************************
// Asignaciones Grupos-Maestros
// ********************************************************************************

export async function _ObtenerAsignacionesGrupoMaestros(idGrupo: number) {
    const url = URL_SVCGRUPO + "ObtenerMaestros";
    const params = {
        "IdGrupo": idGrupo
    }

    return DataDRequest._Request5<Entidad.IMaestroGrupo[]>(url, "GET", params, Entidad.CTipoRequest.Grupo);
}

export async function _AsignarGrupoMaestro(idGrupo: number, idMaestro: number) {
    const url = URL_SVCGRUPO + "AsignarMaestro";
    const params = {
        "IdGrupo": idGrupo,
        "IdMaestro": idMaestro,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion
    }

    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Grupo);
}

export async function _EliminarAsignacionGrupoMaestro(idAsignacion: number) {
    const url = URL_SVCGRUPO + "FinalizarAsignacionMaestro";
    const params = {
        "IdAsignacion": idAsignacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }

    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Grupo);
}

// ********************************************************************************
// LOCAL DATA
// ********************************************************************************

/** @returns Map<IdAlumno, IChild> */
export function _LOCALDATA_GetAlumnosEnGrupo(idGrupo: number, includeStudensNoFounded = false) {
    let asignacionesGrupo = DataModuloHorarioAlumno._DiccAsignacionGrupoAlumnos.get(idGrupo);
    let alumnosMap = new Map<number, Entidad.IAlumno>();
    // let alumnosNoEncontrados = [];
    asignacionesGrupo?.forEach(asignacionH => {
        let alumno = DataModuloMain._GetReqDataMapById(Entidad.CTipoRequest.Alumno).get(asignacionH.IdNinio);
        if (alumno) {
            alumnosMap.set(asignacionH.IdNinio, alumno);
        } else {
            if (includeStudensNoFounded) {
                alumnosMap.set(asignacionH.IdNinio, null);
            }
            // alumnosNoEncontrados.push(asignacionH.IdNinio);
        }
    });
    // if (alumnosNoEncontrados.length) {
    //     console.warn("-d", ">> LOCALDATA -> Grupo:", idGrupo, ", Alumnos no encontrados:", alumnosNoEncontrados);
    // }
    return alumnosMap;
}

export default {
    _DiccGrupo,
    _AltaGrupoV2,
    _ActualizarGrupoV3,
    _ActualizarHorarioOperacion,
    _EliminarGrupoV2,
    _CargaMasivaV2,
    _DownloadExcelTemplate,
    _ObtenerAsignacionesGrupoMaestros,
    _AsignarGrupoMaestro,
    _EliminarAsignacionGrupoMaestro,
    _LOCALDATA_GetAlumnosEnGrupo
}
