import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import IEscuela = Entidad.IEscuela;
import IMateria = Entidad.IMateria;

const IDREQUEST = Entidad.CTipoRequest.MateriaV2;
const IDREQUESTALUMNO = Entidad.CTipoRequest.Alumno
const URLSVCMATERIA = Global._GLOBAL_CONF.URL_RSTV2 + "materia/";
const URLSVALUMNO = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/";

export const _DiccMateriasV2: Map<number, IMateria> = new Map();

export async function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IMateria>[]> {
    const url = URLSVCMATERIA + "ObtenerLista";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async (esc) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, esc.IdKinder);
            const params = {
                //"IdOrganizacion": Data.Util.prop_Usuario.IdOrganizacion,
                "IdEscuela": esc.IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, esc.IdKinder);
        })
}

interface ISendMateria extends Pick<Entidad.IMateria, "Nombre" | "IdEscuela" | "IdsGrupos" | "MinimoAprobar" | "TipoEvaluacion" | "UsaCriterios" | "IdEscolaridad" | "IdGrado" | "FrecuenciaEval" | "BloqueCalificarInicio" | "DiaCalificarInicio" | "DiasCalificar"> {
    Foto?: File
    Criterios: Entidad.ICriterio[];
    ConfiguracionEval: Entidad.IEvaluacionConfigItem[];
}

export interface ISendMateriaUpdt extends Pick<Entidad.IMateria, "Nombre" | "IdMateria" | "MinimoAprobar" | "EvalValor" | "EvalDescripcion" | "TipoEvaluacion" | "UsaCriterios" | "FrecuenciaEval" | "BloqueCalificarInicio" | "DiaCalificarInicio" | "DiasCalificar" | "IdEscolaridad" | "IdGrado"> {
    GruposEliminar: number[];
    GruposNuevos: number[];
    Foto?: File;
    EvalConfiEliminar?: number[];
    CriteriosEliminar?: number[];
    Criterios: Entidad.ICriterio[];
    ConfiguracionEval?: Entidad.IEvaluacionConfigItem[];
}

export async function _AltaMateria(dato: ISendMateria): Promise<DataDRequest.IRequestResponseA> {
    const url = URLSVCMATERIA + "NuevaMateria";
    const formData = new FormData();

    let criteriosProcessed = JSON.stringify(dato.Criterios.map(d => d.Descripcion)).slice(1).slice(0, -1);
    formData.append("Nombre", dato.Nombre);
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario.toString());
    formData.append("IdEscuela", dato.IdEscuela.toString());
    formData.append("IdEscolaridad", dato.IdEscolaridad.toString());
    formData.append("IdGrado", dato.IdGrado.toString());
    formData.append("IdsGrupos", dato.IdsGrupos.toString());
    formData.append("MinimoAprobatorio", dato.MinimoAprobar.toString());
    formData.append("TipoEvaluacion", dato.TipoEvaluacion.toString());
    formData.append("ConfiguracionEval", JSON.stringify(dato.ConfiguracionEval));
    formData.append("UsaCriterios", dato.UsaCriterios.toString());
    formData.append("Criterios", criteriosProcessed);
    formData.append("FrecuenciaEval", dato.FrecuenciaEval.toString());
    formData.append("DiaCalificarInicio", dato.DiaCalificarInicio.toString());
    formData.append("DiasCalificar", dato.DiasCalificar.toString());
    formData.append("BloqueCalificarInicio", dato.BloqueCalificarInicio.toString());
    if (dato.Foto) {
        formData.append("Imagen", dato.Foto, dato.Foto.name);
    }

    return DataDRequest._XMLHttpRequestFormHandler(url, "POST", formData, IDREQUEST);
}

export async function _ActualizarMateria(dato: ISendMateriaUpdt, CambioConfig: boolean = false): Promise<DataDRequest.IRequestResponseA> {
    const url = URLSVCMATERIA + "ActualizarMateria";
    const formData = new FormData();

    formData.append("Nombre", dato.Nombre);
    formData.append("IdMateria", dato.IdMateria.toString());
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario.toString());
    formData.append("GruposEliminar", dato.GruposEliminar.toString());
    formData.append("GruposNuevos", dato.GruposNuevos.toString());
    formData.append("MinimoAprobatorio", dato.MinimoAprobar.toString());
    formData.append("TipoEvaluacion", dato.TipoEvaluacion.toString());
    formData.append("EvalConfigEliminar", dato.EvalConfiEliminar.toString());
    formData.append("EvalConfig", JSON.stringify(dato.ConfiguracionEval));
    formData.append("UsaCriterios", dato.UsaCriterios.toString());
    formData.append("Criterios", JSON.stringify(dato.Criterios));
    formData.append("CriteriosEliminar", dato.CriteriosEliminar.toString());
    formData.append("FrecuenciaEval", dato.FrecuenciaEval.toString());
    formData.append("DiaCalificarInicio", dato.DiaCalificarInicio.toString());
    formData.append("DiasCalificar", dato.DiasCalificar.toString());
    formData.append("BloqueCalificarInicio", dato.BloqueCalificarInicio.toString());
    formData.append("CambioConfig", String(CambioConfig));
    formData.append("IdEscolaridad", dato.IdEscolaridad.toString());
    formData.append("IdGrado", dato.IdGrado.toString());
    if (dato.Foto) {
        formData.append("Imagen", dato.Foto, dato.Foto.name);
    }

    return DataDRequest._XMLHttpRequestFormHandler(url, "POST", formData, IDREQUEST);
}

export async function _Eliminar(idMateria: number): Promise<DataDRequest.IRequestResponseA> {
    const url = URLSVCMATERIA + "EliminarMateria";
    const params = {
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdMateria: idMateria
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export async function _EliminarAsignacion(idMateria: number, idGrupo: number) {
    const url = URLSVCMATERIA + "EliminarAsignacion";
    const params = {
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdMateria: idMateria,
        IdGrupo: idGrupo
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export async function _GetCriterios(idMateria: number) {
    const url = URLSVCMATERIA + "ObtenerCriterios";
    const params = {
        IdMateria: idMateria
    }
    return DataDRequest._Request5<Entidad.ICriterio[]>(url, "GET", params, IDREQUEST);
}

export async function _GetEvaluacionConfig(idMateria: number) {
    const url = URLSVCMATERIA + "ObtenerConfiguracionEvaluacion";
    const params = {
        IdMateria: idMateria
    }
    return DataDRequest._Request5<Entidad.IEvaluacionConfigItem[]>(url, "GET", params, IDREQUEST);
}

/** @deprecated este servicio ya no debe de ser utilizado*/
export async function _GetMateriasDisponibles(idsAlumnos: number[], idEscuela: number) {
    const url = URLSVALUMNO + "ObtenerMateriasDisponibles";
    const params = {
        IdsAlumnos: idsAlumnos.join(","),
        IdEscuela: idEscuela
    }

    return DataDRequest._Request5<Entidad.IMateriaDisponible[]>(url, "GET", params, IDREQUESTALUMNO);
}
export async function _GetMateriasAsignadas(idEscuela: number, idAlumno: number) {
    //const currentDateStr = await Util.fn_GetMaxDate(IDREQUEST, idEscuela);
    const url = URLSVALUMNO + "ObtenerMateriasAsignadas";
    const params = {
        IdEscuela: idEscuela,
        IdAlumno: idAlumno,
        Modificacion: null
    }
    return DataDRequest._Request5<Entidad.IAsignacionAlumnoMateria[]>(url, "GET", params, IDREQUESTALUMNO);
}

export async function _GetCriteriosAsignados(idEscuela: number, idCicloEscolar: number, idsAlumnos: number[], idsGrupos: number[], idsMaterias: number[]) {
    const url = URLSVALUMNO + "ObtenerCriteriosAsignados";
    const params = {
        IdEscuela: idEscuela,
        IdCicloEscolar: idCicloEscolar,
        IdsAlumnos: idsAlumnos,
        IdsGrupos: idsGrupos,
        IdsMaterias: idsMaterias
    }
    return DataDRequest._Request5<Entidad.ICriterioAsignado[]>(url, "GET", params, IDREQUESTALUMNO)
}

export async function _GetCalificacionesAsignacion(idAsignacion: number) {
    const url = URLSVALUMNO + "ObtenerCalificaciones"
    const params = {
        IdAsignacion: idAsignacion
    }
    return DataDRequest._Request5<Entidad.IRepeticionAsignAlumnoMateria[]>(url, "GET", params, IDREQUESTALUMNO);
}
export interface AsignacionMateriaToSend {
    IdGrupo: number;
    IdMateria: number;
    IdConfiguracionMateria: number;
    Criterio?: string;
    FrecuenciaEval?: number;
    BloqueCalInicio: number;
    DiaCalInicio: number;
    DiasCalDuracion: number;
    Repeticiones: RepeticionAsig[];
}

interface RepeticionAsig {
    Inicio: string;
    Fin: string;
    CalificarInicio: string;
    CalificarFin: string;
}

interface IResultResolved extends IRequestAsignacionesResponse {
    ResolvedData: IDataResolved;
}

interface IDataResolvedItem {
    IdAlumno: number;
    IdAsignacionP: number;
    IdCalificacion: number;
}

interface IDataResolved {
    /** Key -> IdAlumno*/
    [idAlumno: number]: IDataResolvedItem[];
}
interface IRequestAsignacionesResponse {
    IdsAlumnos: number[];
    IdsAsignacionesP: number[];
    IdsCalificaciones: number[];
    Resultado: number;
}
export async function _AsignarMateria(idAlumnos: number[], asignaciones: AsignacionMateriaToSend[], idCicloEscolar: number): Promise<IResultResolved> {
    const url = URLSVALUMNO + "AsignarMaterias";
    const params = {
        IdsAlumnos: idAlumnos,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdCicloEscolar: idCicloEscolar,
        Asignaciones: asignaciones
    }
    return new Promise((resolve, reject) => {
        DataDRequest._Request(url, "POST", params, ((result: IRequestAsignacionesResponse) => {
            let dataResolved = <IDataResolved>{};
            console.warn(result);
            if (result) {
                if (result.Resultado > 0) {
                    result.IdsAlumnos
                        .forEach((idAlumno, i) => {
                            if (dataResolved[idAlumno] == null) {
                                dataResolved[idAlumno] = [];
                            }

                            dataResolved[idAlumno]
                                .push({
                                    IdAlumno: result.IdsAlumnos[i], // IdAlumno
                                    IdAsignacionP: result.IdsAsignacionesP[i], // Asignaciones Padre
                                    IdCalificacion: result.IdsCalificaciones[i] // Repeticiones
                                })
                        });
                    console.debug("MateriaAsignación -> _InsertAsignaciónMateriaCriterio", result?.Resultado, [result.IdsAlumnos, result.IdsAsignacionesP, result.IdsCalificaciones], dataResolved)
                }
            }

            resolve({
                Resultado: (result?.Resultado || -100),
                IdsAlumnos: result?.IdsAlumnos,
                IdsAsignacionesP: result?.IdsAsignacionesP,
                IdsCalificaciones: result?.IdsCalificaciones,
                ResolvedData: dataResolved
            });
        }));
    });
}

export async function _EliminarMateriasAsignadas(idsAsignaciones: number[]) {
    const url = URLSVALUMNO + "EliminarMateriasAsignadas";
    const params = {
        IdUsuario: DataUtil._Usuario.IdUsuario,
        Ids: idsAsignaciones
    }
    return DataDRequest._Request5(url, "POST", params, IDREQUESTALUMNO);
}

type IEliminarCalifRes = { [idPadre: string]: number[] };

export async function _EliminarCalificaciones(asignaciones: Object) {
    const url = URLSVALUMNO + "EliminarCalificaciones";
    const params = {
        Asignaciones: asignaciones,
        IdUsuario: DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5<IEliminarCalifRes>(url, "POST", params, IDREQUESTALUMNO);
}

export async function _ObtenerMateriasCalificadas(idEscuela: number, fechaInicio: string, fechaFin: string) {
    const url = URLSVCMATERIA + "ObtenerMateriasCalificadas";
    const params = {
        IdEscuela: idEscuela,
        Inicio: fechaInicio,
        Fin: fechaFin
    }
    return DataDRequest._Request5<Entidad.IEvaluacion[]>(url, "GET", params, IDREQUEST);
}

export function _GetUrlObtenerLogoMateria(materia: IMateria, tamanio: 1 | 2 | 3) {
    const url = URLSVCMATERIA + "ObtenerLogo";
    if (materia && materia.ConImagen && materia.Modificacion) {
        return url + `?IdMateria=${materia.IdMateria}&Tamanio=${tamanio}&Modificacion=${materia.Modificacion}`;
    }
    return null;
}

export default {
    _DiccMateriasV2,
    _AltaMateria,
    _ActualizarMateria,
    _Eliminar,
    _EliminarAsignacion,
    _GetCriterios,
    _GetEvaluacionConfig,
    _GetMateriasDisponibles,
    _GetMateriasAsignadas,
    _GetCriteriosAsignados,
    _GetCalificacionesAsignacion,
    _AsignarMateria,
    _EliminarMateriasAsignadas,
    _EliminarCalificaciones,
    _ObtenerMateriasCalificadas,
    _GetUrlObtenerLogoMateria
}
