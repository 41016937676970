import * as d3 from "d3";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { Global } from "../../data/Global";
import { DataIndexedDB } from "../../data/indexedDB/DB";
import DataModuloPermisos, { IPermisosDisponiblesDatosResponse } from "../../data/modulo/Permisos";
import { _SvSesionIniciarSesion, _SvSesionObtenerInfo, _SvSesionRecuperarContrasenia } from "../../data/modulo/Sesion";
import DataModuloUsuario from "../../data/modulo/Usuario";
import { DataUtilPermission } from "../../data/util/Permission";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { Button } from "../controlD3/Button";
import { InputPassword } from "../controlD3/InputPassword";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { HTMLProgressElement } from "../controlWC/ProgressComponent";
import { ChangeSesionController } from "../util/ChangeSesionController";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilInstancePageEvalControl } from "../util/InstancePageEvalControl";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";

export class UIVentanaLogin extends VentanaBase {

    private ctrlProgress: TSelectionHTML<"wc-progress">;
    private form: HTMLFormElement;
    private enabledLogin: boolean;

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo?: Entidad.CModulo) {
        super(d3.select(document.body) as TSelectionHTML<"div">, modulo);
        this.enabledLogin = true;

        this.UI_Build();
    }

    private UI_Build() {
        this.windowContent
            .classed("login", true);

        const progressWrapper = this.windowContent.append("div")
            .style("position", "absolute")
            .style("left", "0px")
            .style("top", "0px")
            .style("width", "100%")
            .style("height", "3px");

        this.ctrlProgress = progressWrapper.append<HTMLProgressElement>("wc-progress").attr("oculto", true);

        const contentWrapper = this.windowContent.append("div")
            .classed("content_wrapper", true)

        // >> LOGO

        const logoWrapper = contentWrapper.append("div")
            .classed("logo_wrapper", true);

        logoWrapper.append<HTMLImageElement>("img")
            .attr("src", UIUtilIconResources.CGeneral.App);

        logoWrapper.append("label")
            .classed("lbl_version", true)
            .text(Global._APP_VERSION_NAME_TAG);

        // >> FORM
        const formWrapper = contentWrapper.append("div")
            .classed("form_wrapper", true)
            .classed((UIUtilGeneral.FBoxOrientation.Vertical + " " + UIUtilGeneral.FBoxAlign.CenterCenter), true)
            .style("row-gap", "15px");

        this.form = formWrapper
            .append("form")
            .style("width", "100%")
            .node();

        // - USERNAME INPUT
        this.form
            .append(this.CrearInput("Usuario", _L("sesion.user"), "text"));

        this.form.append((() => {
            const inputPass = new InputPassword.InputPassword()
            inputPass._InputInside
                .attr("placeholder", _L("sesion.pass"))
                .attr("id", "txtContraseña")
                .attr("name", "txtContraseña")
                .attr("autocomplete", "on")
                .property("required", true)
            return inputPass._ControlContainer.node()
        })())

        let formButton = d3.select(this.form).append("button")
            .text(_L("sesion.login"))
            .node();

        formWrapper.append("label")
            .text(_L("sesion.recoverpass"))
            .style("font-size", "calc(var(--fontsize) - 1px)")
            .style("text-decoration", "underline")
            .style("cursor", "pointer")
            .on("click", () => {
                this.RecuperarContrasenia();
            });

        // - ONSUBMIT

        this.form
            .onsubmit = e => UIVentanaLogin.IniciarLogin(e, this);

        formButton
            .onclick = e => UIVentanaLogin.IniciarLogin(e, this);

        ChangeSesionController._EvalAndStartRequiredSesion();
    }

    private CrearInput(sufix: string, placeholder: string, tipo: string): HTMLDivElement {
        const inputWrapper = d3.create("div")

        inputWrapper.append("input")
            .classed("input-form", true)
            .attr("type", tipo)
            .attr("id", "txt" + sufix)
            .attr("name", "txt" + sufix)
            .attr("placeholder", placeholder)
            .attr("maxlength", "200")
            .attr("required", "")
            .attr("autocomplete", "on");

        // inputWrapper.append("label").text(placeholder);

        return inputWrapper.node()
    }

    // **********************************************************
    // Iniciar sesión
    // **********************************************************

    public static IniciarLogin(e: Event, _this: UIVentanaLogin) {
        e?.preventDefault();
        const usuario = (_this.form.elements.namedItem("txtUsuario") as HTMLInputElement).value.trim();
        const contrasenia = (_this.form.elements.namedItem("txtContraseña") as HTMLInputElement).value.trim();
        UIVentanaLogin._IniciarLoginFinal(usuario, contrasenia, _this);
    }

    public static _IniciarLoginExtern(_this: UIVentanaLogin, usuario: string, contrasenia: string) {
        (_this.form.elements.namedItem("txtUsuario") as HTMLInputElement).value = usuario;
        (_this.form.elements.namedItem("txtContraseña") as HTMLInputElement).value = contrasenia;
        setTimeout(() => {
            // _this.form.submit()
            UIVentanaLogin.IniciarLogin(null, _this)
        }, 1000);
    }

    public static async _IniciarLoginFinal(usuario: string, contrasenia: string, _this?: UIVentanaLogin) {
        const instanceEvalRes = await UIUtilInstancePageEvalControl._EvalPageInstance();
        if (instanceEvalRes != null && instanceEvalRes != UIUtilInstancePageEvalControl.CSesionResultStatus.VALID) {
            return;
        }

        if (_this && !_this?.enabledLogin) {
            _this?.notificacion._Mostrar(_L("sesion.notif_logindisabled"), "ADVERTENCIA");
            return;
        }

        d3.select(_this?.form)
            .classed("form_verified", true);

        const validData = _this == undefined || _this?.CheckFormValidity()

        if (validData) {
            if (_this) {
                Button._EnableButton(d3.select(_this?.form.querySelector<HTMLButtonElement>("button")), false);
            }
            _this?.ctrlProgress.attr("oculto", false);
            const resUserLogin = await _SvSesionIniciarSesion(usuario, contrasenia);

            if (resUserLogin.Resultado == 1) {
                const userData = resUserLogin.Datos;
                const resSesionInfo = await _SvSesionObtenerInfo(userData.IdUsuario);

                if (resSesionInfo.Resultado >= 0) {
                    let dataPermisosDisponibles: IPermisosDisponiblesDatosResponse;
                    let resPermisosUser: Entidad.IPermisoAsignacionUser[];

                    userData.NSesiones = resSesionInfo.Data;

                    await DataModuloPermisos._ObtenerPermisosDisponibles()
                        .then(async resPermisosDisp => {
                            if (resPermisosDisp.Resultado > 0) {
                                dataPermisosDisponibles = resPermisosDisp.Datos;
                            } else {
                                UIVentanaLogin.ShowFailMessage(resPermisosDisp, "obtenerpermisos", _this);
                            }
                        });

                    if (userData.Perfil != Entidad.CTipoPerfil.Admin) {
                        await DataModuloUsuario._ObtenerPermisosUsuario(userData.IdUsuario)
                            .then(res => {
                                if (res.Resultado > 0 && res.Datos?.length > 0) {
                                    resPermisosUser = res.Datos;
                                } else {
                                    UIVentanaLogin.ShowFailMessage(res, "login_getpermisosusuario", _this);
                                }
                            })
                    }

                    if (dataPermisosDisponibles && (userData.Perfil == Entidad.CTipoPerfil.Admin || resPermisosUser?.length > 0)) {
                        UIUtilInstancePageEvalControl._StopPageInstanceEval();
                        if (await _this?.GuardarUserData(userData, dataPermisosDisponibles, resPermisosUser)) {
                            setTimeout(() => {
                                // INICIAR
                                UIUtilInstancePageEvalControl._SetLocalStorageSesionID(userData.IdSesion)
                                _this?.windowContent.classed("hide", true);
                                window.location.replace("#loading");
                                window.location.reload();
                            }, 1500);
                        } else {
                            UIVentanaLogin.ShowFailMessage("Error", null, _this);
                            DataIndexedDB._DBDelete(false);
                            UIUtilInstancePageEvalControl._StartPageInstanceEval();
                        }
                    }
                } else {
                    UIVentanaLogin.ShowFailMessage(resSesionInfo, "getinfo", _this);
                }
            } else {
                UIVentanaLogin.ShowFailMessage(resUserLogin, "iniciar", _this);
            }
        } else {
            _this?.notificacion._Mostrar(_L("sesion.notif_invaliddata"), "REQUIRED");
        }
    }

    static ShowFailMessage(res: (DataDRequest.IResultadoPeticion<any> | string), accion: string, _this?: UIVentanaLogin) {
        let mensaje = typeof res == "string" ? res : UIUtilLang._GetHTTPMessage(res, accion);
        NotificacionV2._Mostrar(mensaje, "ADVERTENCIA");
        if (_this) {
            _this.ctrlProgress.attr("oculto", true);
            Button._EnableButton(d3.select(_this.form.querySelector<HTMLButtonElement>("button")), true);
        }
    }

    private GuardarUserData(userInSesion: Entidad.IUsuarioSesion, permisosDisp: IPermisosDisponiblesDatosResponse, permisos?: Array<Entidad.IPermisoAsignacionUser>): Promise<boolean> {
        console.debug("Session:", userInSesion);

        return new Promise(async (resolve, reject) => {
            // >> Save user data
            let userSaved = false;
            await DataUtil._GuardarUsuario(userInSesion)
                .then(isUserSave => {
                    userSaved = isUserSave;
                })
                .catch(res => { });

            if (!userSaved) {
                console.warn("-d", "Error al guardar USUARIO en DB, intentar de nuevo");
                resolve(false);
                return;
            }

            // >> Save available permissions data
            let permissionsSaved = false;
            await DataUtilPermission._GuardarPermisosDisponibles(permisosDisp)
                .then(isPermissionDisSave => {
                    permissionsSaved = isPermissionDisSave;
                })
                .catch(res => { });

            if (!permissionsSaved) {
                console.warn("-d", "Error al guardar PERMISOS DISPONIBLES en DB, intentar de nuevo");
                resolve(false);
                return;
            }

            // >> 
            let start = false;
            if (userInSesion.Perfil == Entidad.CTipoPerfil.Admin) {
                start = true;
            }
            else if (permisos?.length) {
                // permisos = data.Utils.fn_CreateTemporal_AllMissingPermissions(permisos, permisosDisp.PermisosDisponibles, userInSesion.IdUsuario, userInSesion.Perfil);
                // >> Save user permissions data
                await DataUtilPermission._GuardarPermisosUsuario(permisos)
                    .then((isSuccess) => {
                        start = isSuccess;
                    })
                    .catch(res => { });
            }
            if (!start) {
                console.warn("-d", "Error al guardar PERMISOS USUARIO en DB");
            }

            resolve(start);
        });
    }

    private CheckFormValidity() {
        return this.form.checkValidity()
            && (this.form.elements.namedItem("txtUsuario") as HTMLInputElement).value.trim()
            && (this.form.elements.namedItem("txtContraseña") as HTMLInputElement).value.trim();
    }

    // **********************************************************
    // Recuperar contraseña
    // **********************************************************

    private async RecuperarContrasenia() {
        const inputUser = (this.form.elements.namedItem("txtUsuario") as HTMLInputElement)
        const userName = inputUser.value.trim();

        this.ctrlProgress.attr("oculto", false);

        if (userName) {
            let res = await _SvSesionRecuperarContrasenia(userName);
            this.notificacion._Mostrar(UIUtilLang._GetHTTPMessage(res, "recover"), (res.Resultado > 0 ? "INFO" : "ADVERTENCIA"));
        }
        else {
            inputUser.focus();
            this.notificacion._Mostrar(_L("sesion.notif_invalid1"), "ADVERTENCIA");
        }

        this.ctrlProgress.attr("oculto", true);
    }
}
