import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { Button } from "../controlD3/Button";
import { CardInfoV2 } from "../controlD3/CardV2Info";
import { ExpanderItemListCardStyle } from "../controlD3/ExpanderItemList";
import { FormGenerator, Fields } from "../controlD3/Formulario";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Tabs } from "../controlD3/Tabs";
import { UIPanelCardEscuelasNivelAlumnos } from "../panelCard/EscuelasNivelAlumnos";
import { UIPanelCardEscuelasNivelEventos } from "../panelCard/EscuelasNivelEventos";
import { UIPanelCardEscuelasNivelGrupos } from "../panelCard/EscuelasNivelGrupos";
import { UIPanelCardEscuelasNivelInfoGeneral } from "../panelCard/EscuelasNivelInfoGeneral";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewEscuelas } from "../utilView/Escuelas";
import { UIWindowManager } from "./WindowManager";
import { UIPanelCardEscuelasCicloEscolar } from "../panelCard/EscuelasCicloEscolar";
import { UIPanelCardEscuelasInfoGral } from "../panelCard/EscuelasInfoGral";
import { Global } from "../../data/Global";
import _L from "../../util/Labels";
import IEscuela = Entidad.IEscuela;
const DEFAULTMAPZOOM = 18;

enum CMainTabs {
    Niveles = "niveles",
    Ciclos = "ciclos",
    Info = "info"
}
enum CNivelTabs {
    InfoGen = "infogen",
    Eventos = "eventos",
    Alumnado = "alumnado",
    Grupos = "grupos",
}

export class UIVentanaEscuelasPanelV2 extends VentanaBase {
    private currentIdEscuela: number;
    private currentEscuela: IEscuela;

    private ctrCardInfo: CardInfoV2;
    private ctrlMainTabs: Tabs;
    private ctrlNivelesList: ExpanderItemListCardStyle;
    private cardCicloEscolar: UIPanelCardEscuelasCicloEscolar;
    private cardEscuelaInfoGral: UIPanelCardEscuelasInfoGral;
    private ctrlNivelTabs: Tabs;
    private cardNivelInfoGeneral: UIPanelCardEscuelasNivelInfoGeneral;
    private cardNivelEventos: UIPanelCardEscuelasNivelEventos;
    private cardNivelAlumnos: UIPanelCardEscuelasNivelAlumnos;
    private cardNivelGrupos: UIPanelCardEscuelasNivelGrupos;
    private btnAgregarNivelContainer: TSelectionHTML<"div">;

    constructor(contenedor: d3.Selection<HTMLDivElement, any, any, any>, modulo: Entidad.CModulo) {
        super(contenedor, modulo, "escuelaspanel");
        this.InitPanel();
        this.InitInicialParams();
    }

    private InitPanel() {
        this.windowContent.classed("panel", true);

        // >> LEFT: INFO
        this.ctrCardInfo = new CardInfoV2()
            ._SetTitulo(null)
            ._MainInfo_CreateImageControl({
                AspectRatio: "contain",
                ControlForm: InputFileControl.ControlForm.Semicuadrado,
                Border: { // FIXME el CardInfo no deberia de controlar el borde
                    Width: 0,
                    Color: null
                },
                UpdateImageCallbackService: async (src64: string, file: File) => {
                    let res = await UIUtilViewEscuelas._SvUpdateFoto(this.currentEscuela, file);
                    if (res.Resultado > 0) {
                        return true;
                    }
                    if (res.Mensaje) {
                        this.notificacion._Mostrar(res.Mensaje, (res.Resultado > 0 ? "INFO" : "ADVERTENCIA"));
                    }
                    return false;
                },
            });
        this.windowContent.append(() => this.ctrCardInfo._CardNode);

        // >> RIGHT: EXTRA
        this.ctrlMainTabs = new Tabs();
        this.ctrlNivelTabs = new Tabs();

        this.cardEscuelaInfoGral = new UIPanelCardEscuelasInfoGral();
        this.cardCicloEscolar = new UIPanelCardEscuelasCicloEscolar();
        this.cardNivelInfoGeneral = new UIPanelCardEscuelasNivelInfoGeneral();
        this.cardNivelEventos = new UIPanelCardEscuelasNivelEventos();
        this.cardNivelAlumnos = new UIPanelCardEscuelasNivelAlumnos();
        this.cardNivelGrupos = new UIPanelCardEscuelasNivelGrupos();

        this.ctrlNivelesList = new ExpanderItemListCardStyle({
            OnCollapseItem: (id: number, item) => {
                this.ctrlMainTabs._HeaderSelection
                    .classed("hide", false)
                    .style("opacity", 0)
                    .style("height", "0px")
                    .transition()
                    .duration(350)
                    .style("opacity", 1)
                    .style("height", "40px");
                auxNivelItem
                    .classed("hide", false);

                if (this.PermissionNivelAgregar) {
                    this.ctrlNivelesList._ControlContainerNode
                        .append(this.btnAgregarNivelContainer.node());
                    this.btnAgregarNivelContainer // Mostrar btn para agregar nivel
                        .style("opacity", 0)
                        .transition()
                        .duration(350)
                        .style("opacity", 1);
                }

                if (this.ctrlNivelTabs._CurrentTabIDSelected) {
                    this.ctrlNivelTabs._ClearSelection();
                }
            },
            OnSelectItem: (id: number, item) => {
                this.ctrlMainTabs._HeaderSelection
                    .style("opacity", 1)
                    .style("height", "40px")
                    .transition()
                    .duration(350)
                    .style("opacity", 0)
                    .style("height", "0px")
                    .end()
                    .then(() => {
                        this.ctrlMainTabs._HeaderSelection
                            .classed("hide", true);
                    })
                auxNivelItem
                    .classed("hide", true);

                const escolaridad = DataModuloMain._GetItemDataByName("Escolaridad", id);
                this.cardNivelInfoGeneral._UpdateCardData(true, escolaridad);
                this.cardNivelEventos._UpdateCardData(true, escolaridad);
                this.cardNivelAlumnos._UpdateCardData(true, escolaridad);
                this.cardNivelGrupos._UpdateCardData(true, escolaridad);

                if (this.PermissionNivelAgregar) {
                    this.btnAgregarNivelContainer // Ocultar btn Agregar nivel
                        .style("opacity", 1)
                        .transition()
                        .duration(350)
                        .style("opacity", 0)
                        .remove();
                }

                if (!this.ctrlNivelTabs._CurrentTabIDSelected) {
                    this.ctrlNivelTabs._SetSelectedTab(this.PermissionFirstNivelTagAvailable);
                }
            },
        });

        // >> Ajusta border-top para cards dentro de nivel_tabs

        this.cardCicloEscolar._CardSelection
            .style("border-top", "none");
        this.cardEscuelaInfoGral._CardSelection
            .style("border-top", "none");
        this.cardNivelInfoGeneral._CardSelection
            .style("border-top", "none");
        this.cardNivelEventos._CardSelection
            .style("border-top", "none");
        this.cardNivelGrupos._CardSelection
            .style("border-top", "none");
        this.cardNivelAlumnos._CardSelection
            .style("border-top", "none");

        // >> Ajusta estilos de lista de niveles

        let auxNivelItem = this.ctrlNivelesList._ControlContainerSelection
            .append("div")
            .attr("class", "aux");

        this.btnAgregarNivelContainer = this.ctrlNivelesList._ControlContainerSelection
            .append("div")
            .attr("class", "accion_container")
            .style("box-sizing", "border-box")
            .style("position", "sticky")
            .style("bottom", "-2px")
            .style("border-top", "1px solid var(--color_borderbox1)")
            .style("padding", "10px 0 1px 0")
            .style("background-color", "var(--color_primary0)");

        new Button(this.btnAgregarNivelContainer.node(), this.VB_GetUIStringModule("agregar_nivel"))
            ._d3Selection
            .on("click", () => this.OpenModal_Escolaridad_FormularioAgregar());

        new MutationObserver((entries) => {
            if (entries[0].type == "childList" && this.PermissionNivelAgregar) {
                this.btnAgregarNivelContainer.raise();
            }
        })
            .observe(this.ctrlNivelesList._ControlContainerNode, {
                "childList": true
            })

        this.windowContent.append(() => this.ctrlMainTabs._ContainerNode);
    }

    private InitInicialParams() {
        const paramsAux = UIWindowManager._GetCurrentParams();
        const success = this.UpdateDataPanelByURLParams(paramsAux);

        if (success) {
            const onchangeparams: ((e: UIWindowManager.HashEvent) => void) = (e) => {
                this.UpdateDataPanelByURLParams(e.detail.Params);
            }
            this["__onchangeparams"] = onchangeparams;
            UIWindowManager._AddOnParamsChangeEventListener(onchangeparams);
        }
    }

    private UpdateDataPanelByURLParams(URLParams: URLSearchParams) {
        const idEscuela: number = (URLParams.get("id")?.split(",").map(d => Number(d))[0] || null);
        const mainTab: CMainTabs = (URLParams.get("maintab")?.split(",")[0] as CMainTabs || null);
        const idNivel: number = (URLParams.get("nivel")?.split(",").map(d => Number(d))[0] || null);
        const nivelTab: CNivelTabs = (URLParams.get("niveltab")?.split(",")[0] as CNivelTabs || null);

        if (idEscuela == null) {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_fail"));
            return false;
        }
        const escuelaChanged = this.currentIdEscuela != idEscuela;
        this.currentIdEscuela = idEscuela;
        this.currentEscuela = DataModuloMain._GetItemDataByName("Escuela", idEscuela);
        if (!this.currentEscuela) {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_infonodisponible"));
            return false;
        }

        this.UpdateCardInfo();
        this.RefreshNivelesList(!escuelaChanged);
        if (escuelaChanged) {
            this.RefreshNivelTabs();
            this.RefreshMainTabs();
        }

        if (mainTab != null && this.ctrlMainTabs._CurrentTabIDSelected != mainTab && this.ctrlMainTabs._HasTabID(mainTab)) {
            this.ctrlMainTabs._SetSelectedTab(mainTab);
            if (mainTab == "ciclos" && this.ctrlNivelesList._IdSelected != null) {
                this.ctrlNivelesList._RemoveCurrentItemFocus(); // Forza que el item invoque su callback collapse (this usa el callback para definir diseño)
            }
        }
        if (idNivel != null && this.ctrlMainTabs._CurrentTabIDSelected == CMainTabs.Niveles && this.ctrlNivelesList._IdSelected != idNivel && this.ctrlNivelesList._HasIDItem(idNivel)) {
            this.ctrlNivelesList._SetIDSelected(idNivel);
        }
        if (nivelTab != null && this.ctrlMainTabs._CurrentTabIDSelected == CMainTabs.Niveles && this.ctrlNivelesList._IdSelected != null && this.ctrlNivelTabs._CurrentTabIDSelected != nivelTab && this.ctrlNivelTabs._HasTabID(nivelTab)) {
            this.ctrlNivelTabs._SetSelectedTab(nivelTab);
        }

        if (this.ctrlMainTabs._CurrentTabIDSelected == null) {
            this.ctrlMainTabs._SetSelectedTab(this.PermissionFirstMainTabAvailable);
        }
        if (this.ctrlNivelTabs._CurrentTabIDSelected == null) {
            this.ctrlNivelTabs._SetSelectedTab(this.PermissionFirstNivelTagAvailable);
        }

        if (escuelaChanged) {
            if (this.PermissionNivelAgregar) {
                this.ctrlNivelesList._ControlContainerNode
                    .append(this.btnAgregarNivelContainer.node());
            } else {
                this.btnAgregarNivelContainer.remove();
            }
        }

        if (this.PermissionCicloEscolar) {
            this.cardCicloEscolar
                ._UpdateCardData(false, this.currentEscuela);
        }

        if (this.PermissionInfoGeneral) {
            this.cardEscuelaInfoGral
                ._UpdateCardData(false, this.currentEscuela);
        }
        return true;
    }

    private UpdateCardInfo() {
        let alumnosEnEscuela = DataModuloEscuela._LOCALDATA_GetAlumnosEnEscuela(this.currentIdEscuela);
        let alumnosActivos = Array.from(alumnosEnEscuela.values())
            .filter(d => (d.IdChildMovimiento == Entidad.CNinioMovimiento.Activo));

        console.warn("Update card info", this.currentEscuela.Logo);

        this.ctrCardInfo
            ._MainInfo_SetURLResourceToImageControl(this.currentEscuela.Logo)
            ._MainInfo_ImageControlDisable(!this.HasActionPermission(Entidad.CAccionPermiso.Editar))
            ._MainInfo_SetDescripcionGeneralPrimaryInfo(this.currentEscuela.Nombre)
            ._MainInfo_SetDescripcionGeneralSecondaryInfo([
                {
                    TopicTag: UIUtilLang._GetUIString("escuelas", "tag_alumactivos"),
                    Description: `${alumnosActivos.length}/${alumnosEnEscuela.size}`,
                },
                {
                    TopicTag: UIUtilLang._GetUIString("escuelas", "tag_tipolicencia"),
                    Description: (this.currentEscuela.TipoLicencia && Entidad.CTipoLicencia[this.currentEscuela.TipoLicencia] != null)
                        ? UIUtilViewData._GetStr_TipoLicencia(this.currentEscuela.TipoLicencia)
                        : UIUtilLang._GetUIString("general", "nodisponible"),
                },
            ])
            ._SetSections([
                {
                    ID: "descripcion_extra",
                    GetTemplateContent: (seccion) => {
                        seccion
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                            .style("row-gap", "10px")
                            .html(`
                                        <div class="dir ${UIUtilGeneral.FBoxOrientation.Vertical}" style="row-gap:5px;">
                                            <b>${UIUtilLang._GetUIString("escuelas", "tag_dir")}</b>
                                            <label></label>
                                        </div>
                                        <div class="loc ${UIUtilGeneral.FBoxOrientation.Vertical}" style="row-gap:5px;">
                                            <b>${UIUtilLang._GetUIString("escuelas", "tag_locat")}</b>
                                            <div id="map2" style="height:200px;"></div>
                                            <label class="hide">${UIUtilLang._GetUIString("escuelas", "sinconfig")}</label>
                                        </div>
                                        <div class="dia ${UIUtilGeneral.FBoxOrientation.Vertical}" style="row-gap:5px;">
                                            <b>${UIUtilLang._GetUIString("escuelas", "frm1_diasopera")}</b>
                                            <label></label>
                                        </div>
                                        <div class="hor ${UIUtilGeneral.FBoxOrientation.Vertical}" style="row-gap:5px;">
                                            <b>${UIUtilLang._GetUIString("escuelas", "tag_horarioopera")}</b>
                                            <label></label>
                                        </div>
                                    `);
                    },
                    OnUpdate: (seccion) => {
                        // >> DIRECCIÓN
                        seccion.select(":scope > .dir > label")
                            .text(UIUtilViewEscuelas._GetDirectionStr(this.currentEscuela));

                        // >> UBICACIÓN
                        const latlong = this.currentEscuela.Direccion?.split("\n")[7]?.split(",");
                        const hasLocation = (latlong?.length == 2);

                        const mapDiv = seccion
                            .select<HTMLDivElement>(":scope > .loc > #map2")
                            .classed("hide", !hasLocation);
                        seccion
                            .select(":scope > .loc > label")
                            .classed("hide", hasLocation);

                        if (hasLocation) {
                            let map = mapDiv.node()["map"] as google.maps.Map;
                            let markerLocation = mapDiv.node()["marker"] as google.maps.Marker;

                            UIUtilGeneral._GetGoogleLoad()
                                .then(() => {
                                    let mapOptions = <google.maps.MapOptions>{
                                        zoom: DEFAULTMAPZOOM,
                                        // center: initLatLong,
                                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                                        fullscreenControl: false,
                                        streetViewControl: false,
                                        mapTypeControl: false
                                    };

                                    map = new google.maps.Map(mapDiv.node(), mapOptions);
                                    markerLocation = new google.maps.Marker({
                                        map: map,
                                        title: UIUtilLang._GetUIString("general", "nodisponible")
                                    });

                                    let latLong = new google.maps.LatLng(Number(latlong[0]), Number(latlong[1]));
                                    markerLocation.setPosition(latLong);
                                    markerLocation.setTitle(this.currentEscuela.Nombre);
                                    map.setCenter(latLong);
                                    map.setZoom(DEFAULTMAPZOOM);

                                    mapDiv.node()["map"] = map;
                                    mapDiv.node()["marker"] = markerLocation;
                                })
                                .catch(() => {
                                    mapDiv.text(UIUtilLang._GetUIString("general", "notif_fail"));
                                })
                                .finally(() => {
                                    this.ctrCardInfo._RefeshTooltipResumenGral();
                                    setTimeout(() => {
                                        this.ctrCardInfo._RefeshTooltipResumenGral(); // Update Marker
                                    }, 300);
                                });
                        }

                        // >> DÍAS DE OPERACIÓN
                        let sonConsecutivos = true;
                        let lastDay = -1;
                        let diasOperativos = this.currentEscuela.HoraEntradas
                            .map((d, i) => {
                                if (d) {
                                    if (lastDay !== -1 && lastDay != (i - 1)) {
                                        sonConsecutivos = false;
                                    }
                                    lastDay = i;

                                    if (i + 1 == 7) {
                                        //0 es domingo
                                        return UIUtilTime._GetDayName(0);
                                    } else
                                        return UIUtilTime._GetDayName(i + 1);
                                }
                                return null;
                            })
                            .filter(d => (d !== null));

                        let diasStr = UIUtilLang._GetUIString("general", "sinconfig");
                        if (diasOperativos.length > 0) {
                            if (diasOperativos.length == 1) {
                                diasStr = diasOperativos[0];
                            } else {
                                diasStr = (sonConsecutivos ? (diasOperativos[0] + " - " + diasOperativos[diasOperativos.length - 1]) : diasOperativos.join(", "));
                            }
                        }
                        seccion.select(":scope > .dia > label").text(diasStr);

                        // >> HORARIO OPERACIÓN
                        let horasStr: string;
                        if (this.currentEscuela.Entrada && this.currentEscuela.Salida) {
                            const strDateBase = this.currentEscuela.Modificacion.split("T")[0];
                            const dtEntrada = UIUtilTime._GetDateConcatenatedDateTime(strDateBase, this.currentEscuela.Entrada);
                            const dtSalida = UIUtilTime._GetDateConcatenatedDateTime(strDateBase, this.currentEscuela.Salida);
                            horasStr = UIUtilTime._DateFormatStandar(dtEntrada, "h12:mm") + " - " + UIUtilTime._DateFormatStandar(dtSalida, "h12:mm");
                        } else {
                            horasStr = UIUtilLang._GetUIString("general", "sinconfig");
                        }
                        seccion.select(":scope > .hor > label").text(horasStr);
                    }
                }
            ]);
    }

    private ErrorInPanelsGoBack(message: string = "") {
        if (message) {
            NotificacionV2._Mostrar(message, "ADVERTENCIA");
        }
        UIWindowManager._HistoryBackEval("escuelas/escuelas");
    }

    private RefreshNivelesList(useAnimation = true) {
        let escuelaNiveles: Entidad.IEscolaridad[];
        if (this.PermissionNivel) {
            escuelaNiveles = DataModuloMain._GetReqDataArrayByName("Escolaridad", true)
                .filter(d => d.IdEscuela == this.currentIdEscuela);
        }
        else {
            escuelaNiveles = [];
        }
        this.ctrlNivelesList
            ._SetItems(
                escuelaNiveles.map(d => ({
                    Id: d.Id,
                    Label: d.Nombre,
                    ContentSelection: this.ctrlNivelTabs._ContainerSelection,
                })),
                useAnimation
            )
    }

    private RefreshMainTabs() {
        this.ctrlMainTabs
            ._SetTabs(
                [
                    {
                        ID: CMainTabs.Niveles,
                        TabName: this.VB_GetUIStringModule("niveles"),
                        Content: this.ctrlNivelesList._ControlContainerSelection,
                    },
                    {
                        ID: CMainTabs.Ciclos,
                        TabName: this.VB_GetUIStringModule("cicloesc"),
                        Content: this.cardCicloEscolar._CardSelection,
                        OnFocus: (content) => this.cardCicloEscolar
                            ._Expandir(),
                        OnUnFocus: (content) => this.cardCicloEscolar
                            ._CancelarEdicionCard()
                            ._Colapsar(),
                    },
                    {
                        ID: CMainTabs.Info,
                        TabName: this.VB_GetUIStringModule("infogeneral"),
                        Content: this.cardEscuelaInfoGral._CardSelection,
                        OnFocus: () => this.cardEscuelaInfoGral._Expandir(),
                        OnUnFocus: () => this.cardEscuelaInfoGral._CancelarEdicionCard()._Colapsar()
                    }
                ].filter(d => (
                    (d.ID == CMainTabs.Niveles && this.PermissionNivel)
                    || (d.ID == CMainTabs.Ciclos && this.PermissionCicloEscolar)
                    || (d.ID == CMainTabs.Info && this.PermissionInfoGeneral)
                ))
            );
        let lblNoPermissions = this.ctrlMainTabs._HeaderSelection
            .select(":scope > .no_permissions");
        if (this.ctrlMainTabs._TabsLength == 0 && !lblNoPermissions.node()) {
            this.ctrlMainTabs._HeaderSelection
                .append("b")
                .attr("class", "no_permissions")
                .style("font-size", "calc(var(--fontsize) + 4px)")
                .style("width", "100%")
                .style("text-align", "center")
                .text(UIUtilLang._GetUIString("general", "nodisponible"));
        }
        else if (this.ctrlMainTabs._TabsLength > 0 && lblNoPermissions.node()) {
            lblNoPermissions.remove();
        }
    }

    private RefreshNivelTabs() {
        if (this.PermissionNivel) {
            this.ctrlNivelTabs
                ._SetTabs(
                    [
                        {
                            ID: CNivelTabs.InfoGen,
                            TabName: this.VB_GetUIStringModule("infogen"),
                            Content: this.cardNivelInfoGeneral._CardSelection,
                            OnFocus: (content) => this.cardNivelInfoGeneral
                                ._Expandir(),
                            OnUnFocus: (content) => this.cardNivelInfoGeneral
                                ._CancelarEdicionCard()
                                ._Colapsar(),
                        },
                        {
                            ID: CNivelTabs.Eventos,
                            TabName: this.VB_GetUIStringModule("eventos"),
                            Content: this.cardNivelEventos._CardSelection,
                            OnFocus: (content) => this.cardNivelEventos
                                ._Expandir(),
                            OnUnFocus: (content) => this.cardNivelEventos
                                ._CancelarEdicionCard()
                                ._Colapsar(),
                        },
                        {
                            ID: CNivelTabs.Alumnado,
                            TabName: this.VB_GetUIStringModule("alumnado"),
                            Content: this.cardNivelAlumnos._CardSelection,
                            OnFocus: (content) => this.cardNivelAlumnos
                                ._Expandir(),
                            OnUnFocus: (content) => this.cardNivelAlumnos
                                ._CancelarEdicionCard()
                                ._Colapsar(),
                        },
                        {
                            ID: CNivelTabs.Grupos,
                            TabName: this.VB_GetUIStringModule("grupos"),
                            Content: this.cardNivelGrupos._CardSelection,
                            OnFocus: (content) => this.cardNivelGrupos
                                ._Expandir(),
                            OnUnFocus: (content) => this.cardNivelGrupos
                                ._CancelarEdicionCard()
                                ._Colapsar(),
                        },
                    ].filter(d => (
                        (d.ID == CNivelTabs.InfoGen && this.PermissionNivel)
                        || (d.ID == CNivelTabs.Eventos && this.PermissionNivel)
                        || (d.ID == CNivelTabs.Alumnado && this.PermissionAlumnos)
                        || (d.ID == CNivelTabs.Grupos && this.PermissionGrupos)
                    ))
                );
        }
    }

    private OpenModal_Escolaridad_FormularioAgregar() {
        type IEscolaridad = Entidad.IEscolaridad;
        let dato = <IEscolaridad>{
            IdEscuela: this.currentIdEscuela
        }

        ModalThings._GetModalToForm<IEscolaridad>({
            Title: "agregar_nivel",
            Action: Entidad.CAccionPermiso.Agregar,
            Modulo: Entidad.CModulo.PanelConfiguracionNiveles,
            Width: 400,
            LangModuleKeyInContext: this.labelsKeyBase,
            GetForm: (content) => {
                return new FormGenerator<IEscolaridad>()
                    ._Crear({
                        LangModuleKeyInContext: Entidad.CModulo[Entidad.CModulo.Escuelas].toLowerCase(),
                        schema: [
                            {
                                model: "Nombre",
                                type: Fields.input,
                                inputAttr: { type: "text", required: true },
                                labelAttr: { text: "frm_level_name" }
                            }
                        ],
                        //Validation: (value, field, daaForm, controls) => value && Boolean(String(value).trim()),
                        LabelMaxWidth: 100
                    }, dato);
            },
            OnAccept: async (form, modalThings) => {
                const res = await DataModuloEscolaridad._AltaEscolaridad(form._Data.IdEscuela, form._Data.Nombre, []);
                if (res.Resultado > 0) {
                    let reloadSuccess = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Escolaridad, dato.IdEscuela);
                    if (!reloadSuccess) {
                        setTimeout(() => {
                            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                        }, 2000);
                    }
                }
                return res
            }
        })
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    private get PermissionFirstMainTabAvailable() {
        if (this.PermissionNivel) {
            return CMainTabs.Niveles;
        }
        else if (this.PermissionCicloEscolar) {
            return CMainTabs.Ciclos;
        }
        else if (this.PermissionInfoGeneral) {
            return CMainTabs.Info;
        }
        return null;
    }

    private get PermissionFirstNivelTagAvailable() {
        if (this.PermissionNivelEditar) {
            return CNivelTabs.InfoGen;
        }
        else if (this.PermissionAlumnos) {
            return CNivelTabs.Alumnado;
        }
        else if (this.PermissionGrupos) {
            return CNivelTabs.Grupos;
        }
        return null;
    }

    private get PermissionNivel() {
        if (this.currentIdEscuela) {
            return UIUtilPermission._HasModulePermission(Entidad.CModulo.PanelConfiguracionNiveles, this.currentIdEscuela);
        }
        return false;
    }

    private get PermissionNivelAgregar() {
        if (this.currentIdEscuela) {
            return UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Agregar, Entidad.CModulo.PanelConfiguracionNiveles, this.currentIdEscuela);
        }
        return false;
    }

    private get PermissionNivelEditar() {
        if (this.currentIdEscuela) {
            return UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Editar, Entidad.CModulo.PanelConfiguracionNiveles, this.currentIdEscuela);
        }
        return false;
    }

    private get PermissionCicloEscolar() {
        if (this.currentIdEscuela) {
            return UIUtilPermission._HasModulePermission(Entidad.CModulo.PanelCicloEscolar, this.currentIdEscuela);
        }
        return false;
    }

    private get PermissionInfoGeneral() {
        if (this.currentEscuela) {
            return UIUtilPermission._HasModulePermission(Entidad.CModulo.PanelEscuelaInformacionGeneral, this.currentIdEscuela) && !Global._GLOBAL_CONF.RELEASE_MODE;
        }
        return false;
    }

    private get PermissionAlumnos() {
        if (this.currentIdEscuela) {
            return UIUtilPermission._HasModulePermission(Entidad.CModulo.Alumnos, this.currentIdEscuela);
        }
        return false;
    }

    private get PermissionGrupos() {
        if (this.currentIdEscuela) {
            return UIUtilPermission._HasModulePermission(Entidad.CModulo.Grupos, this.currentIdEscuela);
        }
        return false;
    }

    private HasActionPermission(action: Entidad.CAccionPermiso) {
        if (this.currentIdEscuela) {
            return UIUtilPermission._HasAccionPermission(action, this.modulo, this.currentIdEscuela);
        }
        return false;
    }

    // **************************************************************************
    // PUBLIC METHODS
    // **************************************************************************

    public _OnServiceEvent(idReq: DataModuloMain.TipoRequestMonitorId, reloadId?: number, error?: Error): void {
        super._OnServiceEvent(idReq, reloadId, error);
        switch (idReq) {
            case Entidad.CTipoRequest.Escuela:
                this.UpdateCardInfo();
                break;
            case Entidad.CTipoRequest.CicloEscolar:
                this.cardCicloEscolar._UpdateCardData(false, this.currentEscuela);
                break;
            case Entidad.CTipoRequest.Grado:
            case Entidad.CTipoRequest.Escolaridad:
            case Entidad.CTipoRequest.Alumno:
            case Entidad.CTipoRequest.Grupo:
                const idEscolaridad = this.ctrlNivelesList._IdSelected;
                const escolaridad = DataModuloMain._GetItemDataByName("Escolaridad", idEscolaridad);
                if (idReq == Entidad.CTipoRequest.Escolaridad) {
                    this.RefreshNivelesList();
                }
                if (escolaridad == null) {
                    return;
                }
                switch (idReq) {
                    case Entidad.CTipoRequest.Grado:
                    case Entidad.CTipoRequest.Escolaridad:
                        this.cardNivelInfoGeneral._UpdateCardData(false, escolaridad);
                        if (idReq != Entidad.CTipoRequest.Grado) {
                            this.cardNivelEventos._UpdateCardData(false, escolaridad);
                        }
                        break;
                    case Entidad.CTipoRequest.Alumno:
                        this.cardNivelAlumnos._UpdateCardData(false, escolaridad);
                        break;
                    case Entidad.CTipoRequest.Grupo:
                        this.cardNivelGrupos._UpdateCardData(true, escolaridad);
                        break;
                }
                break;
        }
    }

    public _Destroy(): void {
        super._Destroy();
        const onchangeparams: ((e: UIWindowManager.HashEvent) => void) = this["__onchangeparams"];
        if (onchangeparams) {
            UIWindowManager._RemoveOnParamsChangeEventListener(onchangeparams);
        }
    }
}
