import saveAs from "file-saver";
import { DataDRequest } from "../DRequest";
import { Global } from "../Global";


export async function _SvArchivoDescargarPlantillaExcel(): Promise<boolean> {
    const url = Global._GLOBAL_CONF.URL_SrvRoot + "archivo/importar/plantilla/ObtenerExcel?IdEscuela=0&IdCategoria=20";
    const filename = "Alumnos.xlsx";

    return DataDRequest._XMLHttpRequestFile(url, filename)
        .then(file => {
            saveAs(file)
            return true
        })
        .catch(() => false)
}
