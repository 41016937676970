import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";
import { _LOCALDATA_GetGruposHorariosDeAlumno } from "./Alumno";

import IEscuela = Entidad.IEscuela;
import ICircular = Entidad.ICircular;

const IDREQUEST = Entidad.CTipoRequest.Circular;
const URLSVCCIRCULAR = Global._GLOBAL_CONF.URL_RSTV2 + "noticia/";
export const _DICC_NOTICIA: Map<number, ICircular> = new Map();

export async function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<ICircular>[]> {
    const url = URLSVCCIRCULAR + "ObtenerLista";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async (esc) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, esc.IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": esc.IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, esc.IdKinder);
        });
}

/** Resultado:
 * * -1: Error
 * * -2: Sin nivel (grado)
 * * -3: No existe o ya fue procesada
 * * -4: Fuera de tiempo, la fecha programada de la noticia es anterior a la fecha actual
 */
export async function _Actualizar(circularData: Entidad.ICircular): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "noticia/ActualizarNoticiaV2"; // "AdminJS/Noticia/ActualizarNoticia";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdNoticia": circularData.IdNoticia,
        "Titulo": circularData.Titulo,
        "Mensaje": circularData.Mensaje,
        // "Grados": circularData.Niveles,
        // "Grupos": circularData.Grupos,
        "Programado": circularData.Programado,
        "ReqAutorizacion": circularData.ReqAutorizacion,
        "Expiracion": circularData.Expira
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: DataDRequest.IRequestResponseBase<undefined>) => {
            resolve({
                Resultado: (result?.Resultado || -100),
                TipoRequest: IDREQUEST,
            })
        })
    })
}

// FIXME Se va a cambiar el servicio ?
export async function _SvNoticiaEliminar(idNoticia: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV1 + "Noticia/EliminarNoticia";
    const params = {
        IdNoticia: idNoticia,
        IdUsuario: DataUtil._Usuario.IdUsuario
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: number) => {
            resolve({
                Resultado: (result || -100),
                TipoRequest: IDREQUEST,
            });
        })
    })
}

// /** @deprecated Los archivos se dan de alta en la misma peticion para Alta de Circular */
// export async function fn_AltaArchivo<DataDel, Data>(params: any): Promise<boolean> {
//     return new Promise(function (resolve) {

//         let url = Global._GLOBAL_CONF.URL_RSTV2 + "noticia/NuevoArchivo";
//         let item = { status: false, data: params };
//         var XHR = new XMLHttpRequest();
//         XHR.responseType = "blob";
//         XHR.open("POST", url);
//         XHR.send(params);
//         XHR.onload = function (oEvent) {
//             if (this.status == 200) {
//                 item.status = true;
//                 console.log("Registro ok");
//                 resolve(true);
//             } else {
//                 console.log("Error")
//                 resolve(false);
//             }
//         };
//         XHR.onerror = function (e) {
//             console.error(e, "error");
//             resolve(false);
//         };
//     });
// }

type PromiseCircularInfoArchivo = Promise<DataDRequest.IRequestResponseA<Entidad.IInfoArchivo[]>>;
interface ICircularGetInfoArchivoRecord {
    Datos: Entidad.IInfoArchivo[];
    Promise: PromiseCircularInfoArchivo;
}
/** Map<IdArchivo, IInfoArchivo> */
let circularGetInfoArchivoRecords = new Map<number, ICircularGetInfoArchivoRecord>();
export async function _GetInfoArchivos(idNoticia: number): PromiseCircularInfoArchivo {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "noticia/ObtenerInfoArchivos";
    const params = { "IdNoticia": idNoticia };

    let info = circularGetInfoArchivoRecords.get(idNoticia);
    if (info?.Promise) {
        // console.warn("CInfoFile -> Reutilizando promesa", idNoticia);
        return info.Promise;
    }
    else if (info?.Datos) {
        // console.warn("CInfoFile -> dato local", idNoticia);
        return {
            Resultado: 1,
            Datos: info.Datos,
            TipoRequest: IDREQUEST,
        }
    }
    else if (!info) {
        info = {
            Datos: null,
            Promise: null,
        }
        circularGetInfoArchivoRecords.set(idNoticia, info);
    }
    // console.warn("CInfoFile -> Nueva promesa", idNoticia);
    const res = DataDRequest._Request5<Entidad.IInfoArchivo[]>(url, "GET", params, IDREQUEST)
        .then(res => {
            res.Datos = res.Datos || [];
            if (res.Resultado > 0) {
                info.Datos = res.Datos;
            }
            return res;
        })
        .catch(() => ({
            Resultado: -100,
        }))
        .finally(() => {
            info.Promise = null;
        });
    info.Promise = res
    return res;
}

/** @deprecated // FIXME */
export async function _EliminarArchivo<TParam>(params: TParam): Promise<DataDRequest.IResultadoPeticion<TParam>> {
    var url = Global._GLOBAL_CONF.URL_RSTV2 + "Noticia/EliminarArchivoNoticia";

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: any) => {

            let item: DataDRequest.IResultadoPeticion<TParam> = { Resultado: -1, Data: params };
            item.TipoRequest = IDREQUEST;

            resolve(item as any);
        })
    })
}

type ISendCircularItem = Pick<Entidad.ICircular, "Titulo" | "Mensaje" | "Programado" | "ReqAutorizacion" | "Grupos" | "Niveles" | "Expira"> &
{
    IdEscuela: number;
    Archivo: File;
}

export interface IMapSendCincularItems {
    ByGrupos: ISendCircularItem & {

    },
    ByAlumnos: ISendCircularItem & {
        IdAlumnos: number[];
    }
}
export async function _SvNoticiaNueva<KOrigin extends keyof IMapSendCincularItems>(origin: KOrigin, dato: IMapSendCincularItems[KOrigin]): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "noticia/NuevaNoticia";
    let statusData = 1;

    const formData = new FormData();

    if (origin == "ByGrupos") {
        let datoGrid: IMapSendCincularItems["ByGrupos"] = dato as any;
        if (datoGrid.Niveles?.length > 0 && datoGrid.Grupos?.length > 0) {
            formData.append("Grados", datoGrid.Niveles.toString());
            formData.append("Grupos", datoGrid.Grupos.toString());
        } else {
            statusData = -2;
        }
    }
    else if (origin == "ByAlumnos") {
        let datoPAlumno: IMapSendCincularItems["ByAlumnos"] = dato as any;
        if (datoPAlumno.IdAlumnos && datoPAlumno.IdAlumnos.length) {
            datoPAlumno.Grupos = []
            datoPAlumno.Niveles = [] // Grados

            datoPAlumno.IdAlumnos.forEach(idAlumno => {
                let horariosGruposArray = Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(idAlumno, datoPAlumno.IdEscuela).values());
                let grupo = horariosGruposArray.find(d => (d.EsPrincipal))
                if (!grupo) grupo = horariosGruposArray[0];
                if (grupo && grupo.IdGrado) {
                    datoPAlumno.Grupos.push(grupo.IdGrupo);
                    datoPAlumno.Niveles.push(grupo.IdGrado);
                } else {
                    statusData = -1;
                }
            })

            formData.append("Grados", datoPAlumno.Niveles.toString());
            formData.append("Grupos", datoPAlumno.Grupos.toString());
            formData.append("Alumnos", datoPAlumno.IdAlumnos.toString());
        } else {
            statusData = -1;
        }
    }

    if (statusData < 0) {
        return {
            Resultado: statusData,
            TipoRequest: IDREQUEST
        }
    }

    formData.append("IdOrganizacion", DataUtil._Usuario.IdOrganizacion.toString());
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario.toString());

    formData.append("IdKinder", dato.IdEscuela.toString());
    formData.append("Titulo", dato.Titulo);
    formData.append("Mensaje", dato.Mensaje);
    formData.append("Programado", dato.Programado || "null");
    formData.append("ReqAutorizacion", dato.ReqAutorizacion as any || "false");
    if (dato.Expira != null)
        formData.append("Expiracion", dato.Expira)

    if (dato.Archivo) {
        formData.append("Archivo", dato.Archivo, dato.Archivo.name);
    }
    // else {
    //     formData.append("Archivo", "");
    // }

    return DataDRequest._XMLHttpRequestFormHandler(url, "POST", formData, IDREQUEST);
}

type CircularAutorizacionesRes = { IdNoticia: number; Grupos: Entidad.ICircularGrupoAutorizaciones[]; };
type PromiseCircularGrupoAutorizaciones = Promise<DataDRequest.IRequestResponseA<Entidad.ICircularGrupoAutorizaciones[]>>;
interface ICircularGetInfoRecord {
    Datos: Entidad.ICircularGrupoAutorizaciones[];
    DtLastRequest: string;
    Promise: PromiseCircularGrupoAutorizaciones;
}
let circularGetInfoRecords = new Map<number, ICircularGetInfoRecord>();
/**
 * Si no es una peticion forzada, las Autorizaciones se consultan a partir de 2 mins desde la ultima consulta. */
export async function _GetInfoAutorizacion(idCircular: number, force: boolean): PromiseCircularGrupoAutorizaciones {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "noticia/ObtenerInfoAutorizacionV2"; // ".../ObtenerInfoAutorizacion";
    const params = {
        "IdNoticia": idCircular,
    }
    const minMaxReq = 1;
    const minClearMap = 15;
    let lastR: ICircularGetInfoRecord = circularGetInfoRecords.get(idCircular);

    if (!force && lastR && lastR.DtLastRequest && !lastR.Promise) {
        // Verifica que hayan pasado un n mins para consultar
        let dtLastModificacion = new Date(lastR.DtLastRequest);
        let dtAuxRef = new Date();
        dtAuxRef.setMinutes(dtAuxRef.getMinutes() - minMaxReq);
        if (dtAuxRef < dtLastModificacion) {
            // console.warn("Circ -> Datos locales no ha pasado", minMaxReq, "mins", idCircular);
            return {
                Resultado: 1,
                TipoRequest: IDREQUEST,
                Datos: lastR.Datos || [],
            };
        }
        else {
            dtAuxRef.setMinutes(dtAuxRef.getMinutes() - minClearMap);
            if (dtAuxRef > dtLastModificacion) {
                // console.warn("Circ -> Limpiando Map", idCircular);
                circularGetInfoRecords.clear();
                circularGetInfoRecords.set(idCircular, lastR);
            }
        }
    }
    else if (!lastR) {
        lastR = {
            Datos: null,
            DtLastRequest: null,
            Promise: null,
        }
        circularGetInfoRecords.set(idCircular, lastR);
    }

    if (lastR.Promise) {
        // console.warn("Circ -> Reutilizando promesa", idCircular);
        return lastR.Promise;
    }

    const res = DataDRequest._Request5<CircularAutorizacionesRes>(url, "GET", params, IDREQUEST)
        .then(res => {
            if (res.Resultado > 0) {
                lastR.DtLastRequest = new Date().toISOString();
                lastR.Datos = res.Datos.Grupos || [];
            }
            // console.warn("Circ -> Promesa cumplida", idCircular);
            return {
                ...res,
                ...{
                    Datos: lastR.Datos,
                }
            };
        })
        .catch(res => ({
            Resultado: -100,
        }))
        .finally(() => {
            lastR.Promise = null;
        });
    lastR.Promise = res;
    // console.warn("Circ -> Nueva promesa", idCircular);
    return res;
}

export function _ObtenerDetalle(idCircular: number, idsGrupos: number[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "noticia/ObtenerDetalle";
    const params = {
        "IdNoticia": idCircular,
        "Grupos": idsGrupos.toString(),
    }
    type TResponse = {
        EsAutorizado: boolean;
        Estado: number;
        IdAlumno: number;
        IdGrupo: number;
        IdNoticia: number;
        IdTutorAutorizacion: number;
        Tutores: number[];
    }
    return DataDRequest._Request5<TResponse[]>(url, "GET", params, IDREQUEST);
}

export function _GetUrlObtenerArchivo(idArchivo: number, tamanio: 1 | 2 | 3) {
    // if (tamanio == 2 || tamanio == 3) {
    return `${Global._GLOBAL_CONF.URL_RSTV2}noticia/ObtenerArchivo?IdArchivo=${idArchivo}&Tamanio=${tamanio}`;
    // } else {
    //     return `${Data.Util._GLOBAL_CONF.URL_RSTV2}noticia/ObtenerArchivo?IdArchivo=${idArchivo}`;
    // }
}

export default {
    _Actualizar,
    _GetInfoArchivos,
    _EliminarArchivo,
    _GetInfoAutorizacion,
    _ObtenerDetalle,
    _GetUrlObtenerArchivo
}
