import { DataDRequest } from "../DRequest";

import IRequestResponseListA = DataDRequest.IRequestResponseListA;
import IRequestResponseA = DataDRequest.IRequestResponseA;
type TParams = { [key: string]: any; };
type TRequestFnRes<TDataRes> = Pick<(IRequestResponseListA<TDataRes> | IRequestResponseA<TDataRes>), "Datos"> & Omit<(IRequestResponseListA<TDataRes> & IRequestResponseA<TDataRes>), "Datos">;
type TRequestFn<TDataRes, TKey> = (url: string, params: TParams, key: TKey, lastResponse: TRequestFnRes<TDataRes>) => Promise<TRequestFnRes<TDataRes>>;

export class RequestManagerLocalSave<TData, TKey = number> {
    private dictEnUso: Map<TKey, Map<any, TData>>;
    private dictLastRes: Map<TKey, TRequestFnRes<TData>>;
    private url: string;
    private defaultParams: TParams | (() => TParams);
    private requestFn: TRequestFn<TData, TKey>;

    constructor() {
        this.dictEnUso = new Map();
        this.dictLastRes = new Map();
    }

    private async DoRequest(key: TKey, params: {}, keyToEnUso: (keyof TData & string)) {
        type TError = { __error: boolean };

        params = this.GetFullParams(params);
        const res = await this.requestFn(this.url, params, key, this.dictLastRes.get(key))
            .catch<TError>((e) => {
                console.warn("RequestManager [warn]: ", e)
                return { __error: true };
            });

        const resAsSuccess: TRequestFnRes<TData> = (res || {}) as TRequestFnRes<TData>;
        const __error: boolean = (res as TError)?.__error
        delete resAsSuccess["__error"]
        if (!res || __error || resAsSuccess.Resultado <= 0) {
            return {
                ...resAsSuccess,
                Datos: this.dictLastRes.get(key)?.Datos,
                Resultado: resAsSuccess.Resultado != null ? resAsSuccess.Resultado : DataDRequest.__RESCODE_UNKNOWNERROR,
            }
        }
        this.dictLastRes.set(key, {
            ...resAsSuccess,
            Maxima: resAsSuccess.Maxima ? resAsSuccess.Maxima : (this.dictLastRes.get(key)?.Maxima || null),
        });
        let dataResponse: TData | TData[];

        if (!keyToEnUso) {
            dataResponse = this.dictLastRes.get(key)?.Datos;
        }
        else {
            const resEval = resAsSuccess;
            const dict = this.dictEnUso.get(key) || new Map();
            const datos_ = (resEval.Datos as Array<any>)
            if (datos_["forEach"])
                datos_.forEach(d => {
                    if (d.EnUso)
                        dict.set(d.Id, d);
                    else
                        dict.delete(d.Id);
                });
            this.dictEnUso.set(key, dict);
            dataResponse = Array.from(dict.values());
        }
        return {
            ...resAsSuccess,
            Datos: dataResponse,
            Resultado: resAsSuccess.Resultado || 1,
        }
    }

    private GetFullParams(params?: TParams) {
        let p = params || {};
        let defaultParams: TParams = this.defaultParams || {};
        if (typeof this.defaultParams == "function") {
            defaultParams = this.defaultParams();
        }
        return { ...p, ...defaultParams, };
    }

    public _SetRequestProccess(fnReq: TRequestFn<TData, TKey>) {
        this.requestFn = fnReq;
        return this;
    }

    public _SetServiceURL(url: string): this {
        this.url = url;
        return this
    }

    public _SetDefaultParams(params: TParams | (() => TParams)): this {
        this.defaultParams = params;
        return this;
    }

    public _GetLastRequestResponse(key: TKey) {
        return this.dictLastRes.get(key);
    }

    public _GetLastRequestData(key: TKey) {
        return this.dictLastRes.get(key)?.Datos;
    }

    public _GetDataArrayEnUso(key: TKey) {
        return Array.from(this.dictEnUso.get(key)?.values() || []);
    }

    public _ClearKey(key): this {
        this.dictLastRes.delete(key);
        this.dictEnUso.delete(key);
        return this;
    }

    public _ClearAllKeys(): this {
        this.dictLastRes.clear();
        this.dictEnUso.clear();
        return this;
    }

    public _DoRequest(key: TKey, /* params: TParams, */ keyToEnUso?: null): Promise<TRequestFnRes<TData>>
    public _DoRequest(key: TKey, /* params: TParams, */ keyToEnUso?: null): Promise<{ Datos: TData, Resultado: number }>
    public _DoRequest(key: TKey, /* params: TParams, */ keyToEnUso?: keyof TData & string): Promise<{ Datos: TData[], Resultado: number }>
    public _DoRequest(key: TKey, /* params: TParams, */ keyToEnUso?: keyof TData & string) {
        return this.DoRequest(key, null, keyToEnUso) as any; // Promise<{ Datos: TData[], Resultado: number }>;
    }

    // public async _DoRequestMultiple(dataReq: [TKey, TParams][], keyToEnUso: (keyof TData & string)) {
    //     let res = null;
    //     for (const [key, params] of dataReq) {
    //         res = await this.DoRequest(key, params, keyToEnUso);
    //     }
    //     return res;
    // }

    // public _SetMonitorDefaultTimelapse(timelapseMs: number): this {
    //     this.monitorDefaultTimelapseMs = timelapseMs;
    //     return this;
    // }

    // public _StartMonitor(keys: TKey[], timelapseMs: number = this.monitorDefaultTimelapseMs): this {
    //     return this;
    // }

    // public _StopMonitor(): this {
    //     return this;
    // }
}
