import { group as d3Group } from "d3-array";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetGruposHorariosDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { _EventoObtenerEventosEscuela } from "../../data/modulo/Eventos";
import { _DiccGrupo } from "../../data/modulo/Grupo";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewGrupos } from "../utilView/Grupos";

import IAlumno = Entidad.IAlumno;
import CTipoEvento = Entidad.CReporteTipoEvento;

interface IEvento extends Entidad.IEventoEscuela {
    StrAsistencia?: string;
    HrEntrada?: string;
    HrSalida?: string;
    ZonaHoraria?: string;
}

interface IDataGrid extends IAlumno {
    Eventos: IEvento[];
    EvtInicio: string;
    // EvtFin: string;
    HrEntrada: string;
    HrSalida: string;
    StrAsistencia: string;
}

type IItemCombo = UIUtilViewData.IBaseData;

export class UIVentanaReportesAsistencia extends VentanaGrid<IDataGrid> {
    private comboYear: SelectV2<IItemCombo, "Id", "monoselect">;
    private comboMonth: SelectV2<IItemCombo, "Id", "monoselect">;
    /** Map<IdEscuela, Map<StrDate, IEvento[]>> */
    private dicctEventos: Map<number, Map<string, IEvento[]>>;

    constructor(container: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(container, modulo, {
            LabelsKeyBase: "reportesasistencia",
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.HorarioAlumno],
        });
        this.dicctEventos = new Map();
        // setTimeout(() => {
        //     this.GridSetEscuelasSeleccionadas(this.kinders.map(d => d.IdKinder), false);
        // });
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IDataGrid[]): Table.ITableMenuDataSelectedOptionConfig<IDataGrid>[] {
        return null;
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<IDataGrid>[] {
        return [
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_nombre", Field: "Nombre" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_appaterno", Field: "ApPaterno" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_apmaterno", Field: "ApMaterno" },
            { Label: "Matrícula", Field: "Matricula" },
            // { Label: "Fecha de nacimiento", Field: "FechaNacimiento", IsDate: true },
            {
                LangModuleKeyInContext: "alumnos",
                LabelLangKey: "d_field_strsexo",
                Field: "Sexo",
                Type: "select",
                Options: UIUtilViewData._GetList_Sexo()
            },
            { Label: "Escolaridad", Field: "StrEscolaridad" },
            { Label: "Grado", Field: "StrGrado" },
            {
                Label: "Grupo principal",
                Field: "StrGrupoPrincipal",
                OnGetValueToMatch: (datoAlumno: IDataGrid) => Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(datoAlumno.IdChild).values())
                    .filter(d => d.EsPrincipal)
                    .map(d => d.Nombre),
            },
            { Label: "Fecha", Field: "EvtInicio", Type: "date", Level: 2, MaxDate: new Date().toISOString() },
            // { Label: "Fecha de salida", Field: "EvtFin", IsDate: true, Level: 2 },
            {
                LabelLangKey: "d_field_strasistencia",
                Field: "TipoEVento" as any,
                Level: 2,
                Type: "select",
                Options: [
                    { Name: _L("general.afirma"), Id: CTipoEvento.Asistencia },
                    { Name: _L("general.niega"), Id: (-CTipoEvento.Asistencia) },
                ]
            },
        ];
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IDataGrid> {
        return {
            IdTabla: "ReportesAsistencias",
            Title: "",
            DefaultSort: "NombreCompleto",
            IdData: "IdChild",
            MaxOptionsInRow: 2,
            MinWidth: 1300,
            Columns: [
                { Field: "NombreCompleto", Label: "Nombre", Width: "12%", MinWidth: "140px", OrderTypeParse: String },
                { Field: "Matricula", Label: "Matrícula", Width: "12%", MinWidth: "80px", OrderTypeParse: String },
                // { Field: "FechaNacimiento", Label: "Nacimiento", Width: "10%", MinWidth: "75px" },
                // { Field: "StrSexo", Label: "Sexo", Width: "18%", MinWidth: "30px" },
                // { Field: "StrEstado", Label: "Estado", Width: "12%", MinWidth: "40px" },
                { Field: "NombreKinder", Label: "Escuela", Width: "12%", MinWidth: "100px", OrderTypeParse: String },
                { Field: "StrEscolaridad", Label: "Escolaridad", Width: "10%", MinWidth: "100px", OrderTypeParse: String },
                { Field: "StrGrado", Label: "Grado", Width: "10%", MinWidth: "80px", OrderTypeParse: String },
                { Field: "StrGrupoPrincipal", Label: "Grupo principal", Width: "12%", MinWidth: "80px", OrderTypeParse: String },
                { Field: "EvtInicio", Label: "Fecha", Width: "11%", MinWidth: "80px", OrderLevelRows: 2, OrderTypeParse: Date },
                { Field: "HrEntrada", Label: "Hr. entrada", Width: "10%", MinWidth: "80px", OrderLevelRows: 2, OrderTypeParse: Date },
                { Field: "HrSalida", Label: "Hr. salida", Width: "10%", MinWidth: "80px", OrderLevelRows: 2, OrderTypeParse: Date },
                { Field: "StrAsistencia", Label: "Asistencia", Width: "10%", MinWidth: "120px", OrderLevelRows: 2, OrderTypeParse: String },
            ]
        };
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IDataGrid> {
        return {
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IDataGrid, IEvento, any>>{
                OnStepCellTable: (container, datum, field: keyof IDataGrid) => {
                    switch (field) {
                        case "NombreCompleto":
                            UIUtilGeneral._CreaElementosLinkeablesV2({
                                Container: container,
                                Data: [datum],
                                GetId: ({ IdChild }) => IdChild,
                                GetTag: ({ NombreCompleto }) => NombreCompleto,
                                Path: "alumnos/alumnos/panel",
                            })
                            break;
                        case "StrGrupoPrincipal":
                            UIUtilViewGrupos._ApplyLblsGruposToContainer(container, datum.IdChild);
                            break;
                    }
                },
                // ActiveDataOnlyWhenDisplayData: true,

                EvaluatorSubLevel: {
                    IdMember: "Id",
                    RemoveLevelCheckboxes: true,
                    OnGetData: (dato) => dato.Eventos,
                    OnStepRowTable: (d, cont) => cont.node().onclick = () => (console.debug(d)),
                    OnStepCellTable: (container, dato, field: keyof IDataGrid) => {
                        switch (field) {
                            case "EvtInicio":
                                // case "EvtFin":
                                if (dato[field]) {
                                    container.text(UIUtilTime._DateFormatStandarFixTimeZone(dato[field], dato.ZonaHoraria));
                                }
                                break;
                            case "StrGrupoPrincipal":
                                if (dato.TipoEVento == Entidad.CReporteTipoEvento.Asistencia) {
                                    const grupo = _DiccGrupo.get(dato.InfoEvt)
                                    container.text(grupo ? UIUtilViewGrupos._GetLblGrupoName(grupo) : _L("general.nodisponible"))
                                } else {
                                    container.text("")
                                }
                                break;
                            case "HrEntrada":
                            case "HrSalida":
                                container.text(this.GetStrHoraMientrasAsistencia(dato, field));
                                break;
                            case "StrAsistencia":
                                container.text(this.GetAsignaStrAsistencia(dato));
                                break;
                        }
                    }
                }
            }
        };
    }

    protected GRID_GetExportarConfig(dataGrid: IDataGrid[]): IConfigGridExcelExport<IDataGrid> {
        const idsEscuelas = [...new Set(dataGrid.map(d => d.IdKinder))];

        return {
            IdsEscuelas: idsEscuelas,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IDataGrid>>(d => ({
                    Field: d.Field as keyof IDataGrid,
                    HeaderTag: d.Label,
                    WidthCell: (d.Field as keyof IDataGrid) == "NombreCompleto" ? 35 : 25
                })),
            OnGetDataBySheets: async () => {
                let groupedData: Map<number, IDataGrid[]> = new Map(idsEscuelas.map(idEscuela => ([idEscuela, []])));

                dataGrid.forEach(alumno => {
                    let datosEnEscuela = groupedData.get(alumno.IdKinder);

                    datosEnEscuela.push(alumno);

                    let itemInTable = this.ctrlTabla._SelectItemData<IEvento>(alumno.IdChild);
                    let eventosChilds: IEvento[] = Array.from(itemInTable.GetChildrenData().values())
                        .map(d => d.Data);

                    eventosChilds.forEach(evento => {
                        datosEnEscuela.push(<IDataGrid>{
                            "StrGrupoPrincipal": (evento.TipoEVento == Entidad.CReporteTipoEvento.Asistencia)
                                ? (_DiccGrupo.has(evento.InfoEvt) ? UIUtilViewGrupos._GetLblGrupoName(_DiccGrupo.get(evento.InfoEvt)) : _L("general.nodisponible"))
                                : "",
                            "EvtInicio": (evento.EvtInicio ? UIUtilTime._DateFormatStandarFixTimeZone(evento.EvtInicio, evento.ZonaHoraria) : ""),
                            "HrEntrada": this.GetStrHoraMientrasAsistencia(evento, "HrEntrada"),
                            "HrSalida": this.GetStrHoraMientrasAsistencia(evento, "HrSalida"),
                            "StrAsistencia": this.GetAsignaStrAsistencia(evento)
                        })
                    })
                })

                return Array.from(groupedData)
                    .map(entrie => ({
                        IdSheet: entrie[0],
                        SheetName: entrie[1][0].NombreKinder,
                        Data: entrie[1]
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder
        };
    }

    private UI_AjustaDrawGrid() {
        // Area combos-filtros
        let filterCont = this.ctrlTabla._Control.select(".filter_container");
        let searchwrapper = filterCont.select(".search-wrapper");

        let areaCombos = filterCont.append("div")
            .attr("class", UIUtilGeneral.FBoxOrientation.Horizontal)
            .style("column-gap", "calc(var(--padding2) * 4)")
            .style("padding", "0 var(--padding3) var(--padding2)")
            .style("box-sizing", "border-box")

        searchwrapper.raise();

        this.comboYear = new SelectV2<IItemCombo, "Id", "monoselect">({
            Type: "monoselect",
            Parent: areaCombos,
            ValueMember: "Id",
            DisplayMember: "Name",
            Data: [],
            OnSelect: (item) => {
                if (item) {
                    let mesesDisponibles = UIUtilViewData._GetMonthsByYearCurrentMonthLimit(item.Id);
                    this.comboMonth._UpdateList(mesesDisponibles);
                    let selectedM = this.comboMonth._dataSelected[0];
                    if (!selectedM) {
                        this.comboMonth._valueSelect(mesesDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b)).Id);
                    }
                } else {
                    this.comboMonth._UpdateList([]);
                }
                this.GridUpdateData();
            }
        })

        this.comboMonth = new SelectV2<IItemCombo, "Id", "monoselect">({
            Type: "monoselect",
            Parent: areaCombos,
            ValueMember: "Id",
            DisplayMember: "Name",
            OnChange: (idMonth, item) => {
                this.GridUpdateData();
            }
        })

        areaCombos.append(() => {
            return ElementWrapper._WrapperToSelectControl(this.comboYear, _L("time.anio") + ":")
                .style("height", "35px")
                .node();
        })

        areaCombos.append(() => {
            return ElementWrapper._WrapperToSelectControl(this.comboMonth, _L("time.mes") + ":").node();
        })
    }

    // **********************************************
    // Overwrite methods
    // **********************************************

    protected GridInitTable() {
        super.GridInitTable();
        this.UI_AjustaDrawGrid();
    }

    protected GridRefreshKinderList() {
        super.GridRefreshKinderList();

        let initAniosDisponibles = this.GetAniosDisponibles();

        if (!this.comboYear._dataSelected?.length && initAniosDisponibles.length) {
            let initAnioSeleccionado = initAniosDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b)).Id;

            this.comboYear._UpdateList(this.GetAniosDisponibles());
            this.comboYear._valueSelect(initAnioSeleccionado);
        }
    }

    protected async GridOnKinderSelectionChange() {
        // super.GridOnKinderSelectionChange(items);
        await this.GridUpdateData(true, true);
    }

    protected async GridGetData(): Promise<IDataGrid[]> {
        let escuelasSeleccionadas = this.GridGetEscuelasSeleccionadas();
        let yearFilter = this.comboYear._dataSelected[0]?.Id;
        let monthFilter = this.comboMonth._dataSelected[0]?.Id;

        // >> Consulta data

        if (escuelasSeleccionadas.length && yearFilter != null && monthFilter != null) {
            const eventosEscuelas = new Map<number, Map<number, IEvento[]>>();
            const dateToConsult = new Date(yearFilter, monthFilter, 1);

            this.ctrlProgressBar.attr("oculto", false);

            // >> Consulta eventos de las escuelas seleccionadas y agrupa por Escuela y Alumno
            for (let escuela of escuelasSeleccionadas) {
                let finalDate = new DateV2(dateToConsult)._SetTimeZone(escuela.ZonaHoraria, true);
                let res = await this.Sv_ObtenerEventosPorEscuela(escuela.IdKinder, finalDate);

                if (res.Resultado > 0) {
                    let eventosAlumnos = d3Group(res.Datos, d => d.IdAlumno);

                    eventosEscuelas.set(escuela.IdKinder, eventosAlumnos);
                } else {
                    this.notificacion._Mostrar(_L("general.notif_fail_infoupdate"), "ADVERTENCIA");
                }
            }

            // Agrupa los eventos dentro del objeto de alumno final
            return (DataModuloMain._GetReqDataArrayByName("Alumno") as IDataGrid[]) // (await super.GridGetData())
                .map(d => {
                    let escuela = DataModuloMain._GetItemDataByName("Escuela", d.IdKinder);
                    let alumno = UIUtilGeneral._ObjectClone(d);

                    alumno.Eventos = [];

                    eventosEscuelas.forEach((eventosEscuela, idEscuela) => {
                        let eventosAlumno = eventosEscuela.get(alumno.IdChild)
                            ?.map(d => {
                                d.ZonaHoraria = escuela.ZonaHoraria;
                                d.HrEntrada = d.EvtInicio;
                                d.HrSalida = d.EvtFin;
                                return d;
                            })

                        if (eventosAlumno) {
                            alumno.Eventos.push(...eventosAlumno);

                            if (idEscuela != alumno.IdKinder) {
                                console.warn("Los eventos pertenecen al alumno, pero están asignados a otra escuela", alumno.IdChild, alumno.Nombre, eventosAlumno);
                            }
                        }
                    })

                    alumno.Eventos.sort((a, b) => (Number(new Date(a.EvtInicio)) - Number(new Date(b.EvtInicio))));

                    return alumno;
                })
                .filter(d => Boolean(d.Eventos.length))
        }
        return [];
    }

    // **********************************************
    // Data things
    // **********************************************

    private GetAsignaStrAsistencia(dato: IEvento): string {
        if (!dato.StrAsistencia) {
            if (dato.TipoEVento == CTipoEvento.Asistencia) {
                dato.StrAsistencia = _L("general.afirma");
            }
            else if (dato.TipoEVento == -CTipoEvento.Asistencia) {
                if (dato.InfoEvt != 0 && dato.Informacion.length) dato.StrAsistencia = `${_L("general.noaplica_short")}: ${dato.Informacion}`
                else dato.StrAsistencia = _L("general.niega");
            }
            else {
                console.warn("-d", "El tipo de evento no es compatible con el grid", dato);
            }
        }

        return dato.StrAsistencia;
    }

    private GetStrHoraMientrasAsistencia(dato: IEvento, tiempo: "HrEntrada" | "HrSalida"): string {
        if (dato.TipoEVento == CTipoEvento.Asistencia) {
            if (dato[tiempo]) {
                console.warn(dato);
                return UIUtilTime._DateFormatStandarFixTimeZone(dato[tiempo], dato.ZonaHoraria, "h12:mm");
            } else {
                console.warn("-d", "Alumno", dato.IdAlumno, ", Evento:", dato.Id, tiempo + " no disponible");
                return "";
            }
        }
        else {
            return "";
        }
    }

    private GetAniosDisponibles() {
        let years: IItemCombo[] = [];
        let allSchools = Array.from(DataModuloEscuela._DiccEscuela.values());

        if (allSchools.length) {
            let schoolMinRecordDate = allSchools.reduce((a, b) => (new Date(a.FechaRegistro) < new Date(b.FechaRegistro) ? a : b));
            let initY = new Date(schoolMinRecordDate.FechaRegistro).getFullYear();

            for (let i = initY; i <= new Date().getFullYear(); i++) {
                years.push({
                    Id: i,
                    Name: i.toString()
                })
            }
        }

        return years.sort((a, b) => (b.Id - a.Id));
    }

    private async Sv_ObtenerEventosPorEscuela(idEscuela: number, date: DateV2): Promise<DataDRequest.IRequestResponseA<IEvento[]>> {
        let eventosPorEscuela = this.dicctEventos.get(idEscuela);
        let eventosFecha = eventosPorEscuela?.get(date._ToISOString());

        if (eventosFecha?.length) {
            return {
                Resultado: 1,
                Datos: eventosFecha,
            }
        } else {
            let res = await _EventoObtenerEventosEscuela(idEscuela, CTipoEvento.Asistencia, date._ToISOString());

            if (res.Resultado > 0 && UIUtilTime._GetValidatorDateYM(date) < UIUtilTime._GetValidatorDateYM(new Date())) {
                if (!eventosPorEscuela) {
                    eventosPorEscuela = new Map();
                }

                eventosPorEscuela.set(date.toISOString(), res.Datos || []);

                this.dicctEventos.set(idEscuela, eventosPorEscuela);
            }

            return res;
        }
    }
}
