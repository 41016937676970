import { group } from "d3-array";
import saveAs from "file-saver";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataModuloMain } from "../ModuloMain";
import { DataUtil } from "../util/Util";
import DataModuloTutorAsignacion from "./TutorAsignacion";

import ITutor = Entidad.ITutor;
type TIKinder = Entidad.IEscuela;

const IDREQUEST = Entidad.CTipoRequest.Tutor;
const URL_TUTOR = Global._GLOBAL_CONF.URL_RSTV2 + "tutor/";
export const _DiccTutores: Map<number, ITutor> = new Map();

export async function _GetList(escuelas: Array<TIKinder>): Promise<DataDRequest.IRequestResponseListA<ITutor>[]> {
    const url = URL_TUTOR + "ObtenerLista";
    const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST);
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "Modificacion": currentDateStr,
    }

    return DataDRequest._Request4List(url, "GET", params, IDREQUEST)
        .then(res => [res])
        .catch(() => []);
}

export async function _Insert(datoT: ITutor, idsEscuela: number[]): Promise<DataDRequest.IRequestResponseA> {
    const url = URL_TUTOR + "NuevoTutor";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Nombre": datoT.Nombre,
        "ApPaterno": datoT.ApPaterno,
        "ApMaterno": datoT.ApMaterno,
        "Telefono": datoT.Telefono,
        "Correo": datoT.Correo.trim(),
        "Contrasenia": datoT.Contrasenia,
        "IdsEscuela": idsEscuela,
    }
    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Tutor);
}

export async function _Update(datoTutor: ITutor, esGlobal: boolean, idsEscuelaNew: number[], idsEscuelaRemove: number[]): Promise<DataDRequest.IRequestResponseA> {
    // const url = Utils._GLOBAL_CONF.URL_RSTV1 + "Padre/ActualizarPadre";
    const url = URL_TUTOR + "ActualizarTutor";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdPadre": datoTutor.IdPadre,
        "Nombre": datoTutor.Nombre,
        "ApPaterno": datoTutor.ApPaterno,
        "ApMaterno": datoTutor.ApMaterno,
        "Telefono": datoTutor.Telefono,
        "Correo": datoTutor.Correo.trim(),
        "IdUsuarioPadre": datoTutor.IdUsuarioPadre,
        "EsGlobal": esGlobal,
        "EscuelasAgregar": idsEscuelaNew,
        "EscuelasEliminar": idsEscuelaRemove,
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export async function _Delete(idTutor: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    // let url = Utils.prop_URLPrincipal + "AdminJS/Padre/EliminarPadreV2";
    const url = URL_TUTOR + "EliminarTutor"
    const params = {
        "IdTutor": idTutor,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params, IDREQUEST)
}

export async function _GetPassword(idsTutores: number[]): Promise<DataDRequest.IResultadoPeticion<{ [key: string]: string }>> {
    let url = Global._GLOBAL_CONF.URL_RSTV2 + "padre/ObtenerAccesos" //"padre/ObtenerContrasenia" //+ "Grupo/ObtenerContrasenia";
    let params = { Ids: idsTutores }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "GET", params, (result: DataDRequest.IRequestResponseBase<{ [k: string]: string }>) => {
            resolve({
                Resultado: result?.Resultado || -100,
                Data: result?.Datos || {},
                TipoRequest: Entidad.CTipoRequest.Tutor
            })
        })
    })
}

export async function _SvAlumnoRecuperarContraseniaViaEmail(correo: string): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "padre/RecuperarContraseniaViaEmail";
    const params = {
        "Correo": correo
    }
    return DataDRequest._Request5(url, "POST", params);
}

export async function _SvAlumnoRecuperarContraseniaViaSMS(correo: string): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "padre/RecuperarContraseniaViaSMS"
    const params = {
        "Correo": correo,
    }
    return DataDRequest._Request5(url, "POST", params)
}

export async function _DownloadExcelTemplate() {
    const url = Global._GLOBAL_CONF.URL_SrvRoot + "archivo/importar/plantilla/ObtenerExcel?IdEscuela=0&IdCategoria=22";
    const filename = "Tutores.xlsx";

    return DataDRequest._XMLHttpRequestFile(url, filename)
        .then(file => {
            saveAs(file)
            return true
        })
        .catch(() => false)
}

// ********************************************************************************
// LOCAL DATA
// ********************************************************************************

interface IAsignacionesAlumnosResult {
    Alumno: Entidad.IAlumno;
    Asignacion: Entidad.ITutorAsignacion;
}

type TAsignacionMonitoreo = ("monitoreo" | "no-monitoreo" | "todo");

/** @returns ```
 * Map<IdAlumno, {
 * -     Alumno: Entidades.IChild;
 * -     Asignacion: Entidades.ITutorAsignacion;
 * }>
 * ``` */
export function _LOCALDATA_GetAsignacionesAlumnos(idTutor: number, type: TAsignacionMonitoreo, includeStudensNoFounded = false): Map<number, IAsignacionesAlumnosResult> {
    const asignaciones: Map<number, Entidad.ITutorAsignacion> = DataModuloTutorAsignacion._DiccAsignacionesPorTutor.get(idTutor) || new Map();
    const alumnosAsignados = new Map<number, IAsignacionesAlumnosResult>();

    asignaciones.forEach(asignacion => {
        if ((type == "todo") || (asignacion.EsMonitoreo && type == "monitoreo") || (!asignacion.EsMonitoreo && type == "no-monitoreo")) {
            const alumno = DataModuloMain._GetReqDataMapByName("Alumno").get(asignacion.IdChild);
            // if (!alumno) {
            //     console.warn("-d", `>> LOCALDATA -> Alumno no encontrado: ${asignacion.IdChild}, Tutor: ${idTutor}`);
            // }

            if (alumno || includeStudensNoFounded) {
                alumnosAsignados.set(asignacion.IdChild, {
                    Alumno: alumno,
                    Asignacion: asignacion
                });
            }
        }
    })

    return alumnosAsignados;
}

/** @returns ```
 * Map<IdEscuela, (Asignacion-Alumno)[]>
 * ```
 * */
export function _LOCALDATA_GetAlumnosAsignadosGroupBySchools(idTutor: number, type: TAsignacionMonitoreo, includeStudensNoFounded = false): Map<number, IAsignacionesAlumnosResult[]> {
    const asignacionesAlumnosMap = _LOCALDATA_GetAsignacionesAlumnos(idTutor, type, includeStudensNoFounded);
    const asignacionesAlumnosArray = Array.from(asignacionesAlumnosMap.values());

    return group(asignacionesAlumnosArray, (d) => (d.Alumno?.IdKinder || d.Asignacion.IdKinder));
}

export default {
    _DiccTutores,
    _Insert,
    _Update,
    _Delete,
    _GetPassword,
    _DownloadExcelTemplate,
    _LOCALDATA_GetAsignacionesAlumnos,
    _LOCALDATA_GetAlumnosAsignadosGroupBySchools
}
