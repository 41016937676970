import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloMateriaV2 from "../../data/modulo/MateriaV2";
import { ArrayV2 } from "../../util/ArrayV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { FormGenerator } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";

import IAlumno = Entidad.IAlumno;
import IEvaluacion = Entidad.IEvaluacion;
import CTipoEval = Entidad.CTipoEvaluacion;

type IAlumnoPick = Pick<IAlumno, "IdChild" | "IdKinder" | "NombreCompleto" | "KinderFiltro" | "Matricula" | "NombreKinder">;

interface IEvaluacionGrid extends IEvaluacion {
    StrCicloEscolar: string;
}

interface IDataGrid extends IAlumnoPick {
    IdAlumnoEscuela: string;
    Evaluaciones: IEvaluacionGrid[];
}

export class UIVentanaMateriasCalificadas extends VentanaGrid<IDataGrid> {
    /** strDateRangeInicio solo debe de estar asociado a cadenas de tipo DateInput ej. '2023-10-03'*/
    private strDateRangeInicio: string;
    /** strDateRangeFin solo debe de estar asociado a cadenas de tipo DateInput ej. '2023-10-03'*/
    private strDateRangeFin: string;

    private labelDateRange: d3.Selection<HTMLLabelElement, any, HTMLElement, any>

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, {
            LabelsKeyBase: "materiascalificadas"
        });
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }
    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IDataGrid[]): Table.ITableMenuDataSelectedOptionConfig<IDataGrid>[] {
        return null;
    }
    protected GRID_GetFilters(): Table.IParametroFiltro<IDataGrid>[] {
        return [
            {
                Field: "Matricula", Type: "text"
            },
            {
                Field: "NombreCompleto", Type: "text"
            },
            {
                Field: "Materia" as any, Type: "select", Level: 2,
                Options: () => {
                    const ArrMaterias: string[] = [];
                    DataModuloMain._GetReqDataArrayByName("MateriaV2")
                        .forEach(d => ArrMaterias.push(d.Nombre));
                    this.ctrlTabla._data.forEach(d => d.Evaluaciones.forEach(d => ArrMaterias.push(d.Materia)))
                    return ArrMaterias
                        .map(d => ({ Id: d, Name: d }))
                        .filter(d => (!!d.Name))
                        .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase()));
                }
            },
            {
                Field: "Criterio" as any, Type: "select", Level: 2,
                Options: () => {
                    const ArrCriterios: string[] = [];
                    DataModuloMain._GetReqDataArrayByName("MateriaV2")
                        .map(d => d.Criterios)
                        .forEach(d => ArrCriterios.push(...d))
                    this.ctrlTabla._data.forEach(d => d.Evaluaciones.forEach(d => ArrCriterios.push(d.Criterio)));
                    return ArrCriterios
                        .map(d => ({ Id: d, Name: d }))
                        .filter(d => (!!d.Name))
                        .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase()));
                }
            },
            {
                Field: "FechaEval" as any, Type: "date", Level: 2, LabelLangKey: "d_filter_fechaeval"
            },
            {
                Field: "StrCicloEscolar" as any, Type: "select", Level: 2,
                Options: () => {
                    const ArrCiclosEscolares: string[] = [];
                    DataModuloMain._GetReqDataArrayByName("CicloEscolar")
                        .forEach(d => ArrCiclosEscolares.push(d.Nombre));
                    this.ctrlTabla._data.forEach(d => d.Evaluaciones.forEach(d => ArrCiclosEscolares.push(d.StrCicloEscolar)))
                    return ArrCiclosEscolares
                        .map(d => ({ Id: d, Name: d }))
                        .filter(d => (!!d.Name))
                        .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase()))
                }
            }
        ];
    }
    protected GRID_GetTableConfigBase(): IGridRenderInfo<IDataGrid> {
        return {
            IdTabla: "MateriasCalificadas",
            Title: "",
            DefaultSort: { Type: Table.CStatusOrder.Asc, Field: "NombreCompleto" },
            IdData: "IdAlumnoEscuela",
            MinWidth: 1500,
            Columns: [
                { Field: "Matricula", Label: "", MinWidth: "130px", Width: "10%" },
                { Field: "NombreCompleto", Label: "", MinWidth: "130px", Width: "17%" },
                { Field: "NombreKinder", Label: "Escuela", MinWidth: "130px", Width: "15%" },
                { Field: "Materia" as any, Label: "", MinWidth: "130px", Width: "10%", OrderLevelRows: 2 },
                { Field: "Criterio" as any, Label: "", MinWidth: "130px", Width: "10%", OrderLevelRows: 2 },
                { Field: "Evaluacion" as any, Label: "", MinWidth: "130px", Width: "12%", OrderLevelRows: 2 },
                { Field: "FechaEval" as any, Label: "", MinWidth: "130px", Width: "8%", OrderLevelRows: 2 },
                { Field: "StrCicloEscolar" as any, Label: "", MinWidth: "130px", Width: "15%", OrderLevelRows: 2 }
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IDataGrid> {
        return {
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IDataGrid, IEvaluacionGrid, any>>{
                ForceHideLevelRowsWithoutChilds: true,
                EvaluatorSubLevel: {
                    IdMember: "IdEvaluacion",
                    OnGetData: (d) => {
                        return d.Evaluaciones;
                    },
                    OnStepCellTable: (container, datum, field) => {
                        switch (field) {
                            case "Evaluacion":
                                this.CreateCalificacionColumn(datum.Tipo, datum.Evaluacion, container);
                                break;
                            case "FechaEval":
                                container.text(UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(datum.FechaEval, datum.IdEscuela));
                                break;
                        }
                    }
                },
                OnStepCellTable: (container, datum, field) => {
                    if (field == "NombreCompleto") {
                        UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", datum.IdChild)
                    }
                },
                /* OnStepRowTable: (dato, tr, rB) => {
                    const ItemSelected = this.ctrlTabla.met_SelectItemData(dato.IdAlumnoEscuela);
                    const Enabled = !!ItemSelected.GetChildrenDataFiltered().size
                    rB.style("height", !Enabled ? "1px" : null);
                    tr.classed("hide", !Enabled);
                } */
            },
            /* OnFilter: () => {
                this.ctrlTabla.prop_data.forEach(d => {
                    const Item = this.ctrlTabla.met_SelectItemData(d.IdAlumnoEscuela);
                    const Enabled = !!Item.GetChildrenDataFiltered().size;
                    console.log(Enabled);
                    if (Enabled !== Item.IsEnable) {
                        console.log("RefreshView");
                        Item.EnableItemRow(Enabled).RefreshTableView();
                    }
                })
            } */
        };
    }

    protected GRID_GetExportarConfig(dataGrid: IDataGrid[]): IConfigGridExcelExport<any> {
        interface IDataToExport extends Pick<IDataGrid, "IdKinder" | "NombreCompleto" | "Matricula" | "NombreKinder"> {
            Materia: string;
            Criterio: string;
            Evaluacion: string;
            FechaEval: string;
            StrCicloEscolar: string;
        }

        const hasDataSelected = (this.ctrlTabla._dataChecked.length > 0);

        return <IConfigGridExcelExport<IDataToExport>>{
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdKinder))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IDataToExport>>(d => ({
                    Field: d.Field as keyof IDataToExport,
                    HeaderTag: d.Label,
                    WidthCell: (d.Field == "Matricula" || d.Field == "FechaEval" as any) ? 15 : 30
                })),
            OnGetDataBySheets: async () => {
                let datosToExport: IDataToExport[] = [];
                dataGrid.forEach(alumnoEscuela => {
                    datosToExport.push({
                        IdKinder: alumnoEscuela.IdKinder,
                        NombreCompleto: alumnoEscuela.NombreCompleto,
                        Matricula: alumnoEscuela.Matricula,
                        NombreKinder: alumnoEscuela.NombreKinder,
                        Materia: null,
                        Criterio: null,
                        Evaluacion: null,
                        FechaEval: null,
                        StrCicloEscolar: null
                    })
                    this.ctrlTabla._SelectItemData(alumnoEscuela.IdAlumnoEscuela).GetChildrenDataFiltered<IEvaluacionGrid>().forEach(({ Data: evalData, IsCheked: isChecked }) => {
                        if (hasDataSelected && !isChecked) return;
                        datosToExport.push({
                            IdKinder: alumnoEscuela.IdKinder,
                            NombreCompleto: null,
                            Matricula: null,
                            NombreKinder: null,
                            Materia: evalData.Materia,
                            Criterio: evalData.Criterio,
                            Evaluacion: evalData.Evaluacion,
                            FechaEval: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(evalData.FechaEval, evalData.IdEscuela),
                            StrCicloEscolar: evalData.StrCicloEscolar,
                        })
                    })
                })

                return Array.from(d3Group(datosToExport, d => d.IdKinder))
                    .map<ExcelThings.ISheetConfig<IDataToExport>>(([idEscuela, alumnos]) => ({
                        IdSheet: idEscuela,
                        SheetName: alumnos[0].NombreKinder,
                        Data: alumnos
                    }))
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder
        };
    }

    private CreateCalificacionColumn(tipoEval: CTipoEval, calificacion: string, container: TSelectionHTML<"div", any, any>) {
        switch (tipoEval) {
            case CTipoEval.Colores:
                container.text("");
                container.style("display", "flex").style("gap", "10px");
                container.append("div")
                    .style("height", "23px")
                    .style("width", "23px")
                    .style("background-color", calificacion)
                    .style("border-radius", "50%")
                    .style("border", "1px solid var(--color_borderbox1)");
                break;
            default:
                container.text(calificacion);
                break;
        }
    }

    private UI_AjustaDrawGrid() {
        let filterCont = this.ctrlTabla._Control.select(".filter_container");
        let searchwrapper = filterCont.select(".search-wrapper");

        let areaCombos = filterCont.append("div")
            .style("column-gap", "calc(var(--padding2) * 4)")
            .style("padding", "0 var(--padding3) var(--padding2)")
            .style("box-sizing", "border-box")

        searchwrapper.raise();

        this.strDateRangeInicio = UIUtilTime._FmtToInputDate(new Date());
        this.strDateRangeFin = UIUtilTime._FmtToInputDate(new Date());

        this.labelDateRange = d3.create("label").style("padding-left", "var(--padding2)");
        this.UpdateLabelDateRange();
        areaCombos.append(() => ElementWrapper._WrapperToElement(this.labelDateRange, this.VB_GetUIStringModule("tag_query_range"), { LabelMaxWidth: "max-content" })
            .style("height", "35px")
            .style("max-width", "400px")
            .node());
        let wrapperElement = areaCombos.select(".element_wrapper").select(".area_element");
        wrapperElement.on("click", () => {
            this.OpenMiniModalDateRange();
        })
    }

    private GetStrDateRanges() {
        if (!this?.strDateRangeInicio || !this?.strDateRangeFin) return { mssg: this.VB_GetUIStringModule("tag_noconfig"), err: true }
        return {
            mssg: `${(this.strDateRangeInicio.split("-").reverse().join("/"))} - ${this.strDateRangeFin.split("-").reverse().join("/")}`,
            err: false
        }
    }

    private UpdateLabelDateRange() {
        this.labelDateRange.text(this.GetStrDateRanges().mssg);
        this.labelDateRange.classed("err_range", this.GetStrDateRanges().err)
    }

    private OpenMiniModalDateRange() {
        interface Rangos {
            Inicio: string,
            Fin: string
        }

        ModalThings._GetModalToForm({
            Title: this.VB_GetUIStringModule("tag_query_range"),
            Width: 300,
            GetForm: (content, mt) => {
                return new FormGenerator<Rangos>()._Crear({
                    LabelMaxWidth: 100,
                    schema: [
                        { model: "Inicio", type: "input", labelText: "Inicio", inputAttr: { type: "date", required: true, value: this.strDateRangeInicio } },
                        { model: "Fin", type: "input", labelText: "Fin", inputAttr: { type: "date", required: true, value: this.strDateRangeFin } },
                    ],
                    Validation: (value, field, dataForm, controlsForm) => {
                        if (dataForm.Inicio > dataForm.Fin) return false;
                        return true;
                    }
                })
            },
            OnAccept: (form, mt) => {
                // ****** Pasar abajo hasta termino de consulta?
                this.strDateRangeInicio = form._Data.Inicio;
                this.strDateRangeFin = form._Data.Fin;
                // ******

                mt.Modal._Ocultar();
                this.UpdateLabelDateRange();
                this.GridUpdateData();
                return null;
            }
        })
    }

    // ***************************************
    // Overwritten Methods
    // ***************************************

    protected async GridOnKinderSelectionChange(): Promise<void> {
        this.ctrlTabla._CheckAllItems(false);
        this.GridUpdateData();
    }

    protected GridInitTable() {
        super.GridInitTable();
        this.UI_AjustaDrawGrid();
    }

    protected async GridGetData(): Promise<IDataGrid[]> {
        const arrCalificaciones: Entidad.IEvaluacion[] = [];
        const arrAlumnosCalificaciones: IDataGrid[] = [];

        const idsEscuelasSeleccionadas = this.GridGetEscuelasSeleccionadas().map(d => d.IdKinder);
        if (idsEscuelasSeleccionadas.length && this.strDateRangeFin && this.strDateRangeInicio) {
            if (!this.ctrlProgressBar.node()._Visible) this.ctrlProgressBar.node()._Visible = true;
            let error = false;
            await new ArrayV2<number>()
                ._Push(...idsEscuelasSeleccionadas)
                ._ForEachAwait(async idEscuela => {
                    const nombreEscuela = DataModuloMain._GetDataValueFieldByName("Escuela", idEscuela, "Nombre");
                    const res = await DataModuloMateriaV2._ObtenerMateriasCalificadas(idEscuela, this.strDateRangeInicio, this.strDateRangeFin);
                    if (!error && res.Resultado <= 0) error = true;
                    if (res.Datos) arrCalificaciones.push(...res.Datos);
                    d3Group(arrCalificaciones, d => d.IdAlumno).forEach((alumnoCalificaciones, idAlumno) => {
                        const alumno = DataModuloMain._GetItemDataByName("Alumno", idAlumno);
                        arrAlumnosCalificaciones.push({
                            IdAlumnoEscuela: idAlumno + "_" + idEscuela,
                            IdChild: idAlumno,
                            IdKinder: idEscuela,
                            KinderFiltro: [idEscuela],
                            Matricula: alumno.Matricula,
                            NombreCompleto: alumno.NombreCompleto,
                            NombreKinder: nombreEscuela,
                            Evaluaciones: alumnoCalificaciones.map(d => (
                                {
                                    ...d,
                                    StrCicloEscolar: DataModuloMain._GetDataValueFieldByName("CicloEscolar", d.IdCiclo, "Nombre")
                                }
                            )).sort((a, b) => b.FechaEval.localeCompare(a.FechaEval))
                        })
                    })
                })
            if (error) this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
        }
        return arrAlumnosCalificaciones;
    }
}
