import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtilDevice } from "../util/Device";
import { DataUtil } from "../util/Util";

import IUsuarioSesion = Entidad.IUsuarioSesion;
const IDREQUEST = Entidad.CTipoRequest.Sesion;

export const _DiccSesionVigencia = new Map<number, ISesionVigencia>();

export async function _SvSesionIniciarSesion(correo: string, contrasenia: string): Promise<DataDRequest.IRequestResponseA<IUsuarioSesion>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "sesion/IniciarSesion";
    const params = {
        "Correo": correo,
        "Contrasenia": contrasenia,
        "Imei": DataUtilDevice._GetAppInfo().IMEI,
        "TipoDispositivo": DataUtilDevice._GetAppInfo().MobileType,
        "TokenFirebase": ""
    }

    return DataDRequest._Request5<IUsuarioSesion[]>(url, "POST", params, {
        requestID: IDREQUEST,
        ignoreNegativeResultAlert: true,
    }).then((result) => {
        const [userData] = (result?.Datos || []);

        if (userData) {
            userData.IMEI = params.Imei;
            userData.DeviceType = params.TipoDispositivo;
            //@ts-ignore
            userData.Correo = correo;
        }
        return {
            ...result,
            Datos: userData,
        }
    }).catch(() => ({ Resultado: DataDRequest.__RESCODE_UNKNOWNERROR }));
}

export async function _CerrarSesion() {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "sesion/CerrarSesion";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Imei": DataUtil._Usuario.IMEI
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export interface ISesionVigencia {
    Id: number;
    /**
     * * res.Data == 1 -> (Sesión vigente)
     * * res.Data == -1 -> Vigencia expirada
     * * res.Data == -2 -> No se pudo actualizar la sesión (Sesión cerrada)
     */
    Vigencia: number;
    EnUso: boolean;
}
/** Consulta la vigencia de la sesión del usuario
 * @returns
 * * res.Data == 1 -> (Sesión vigente)
 * * res.Data == -1 -> Vigencia expirada
 * * res.Data == -2 -> No se pudo actualizar la sesión (Sesión cerrada)
 */
export function _VerificarSesion(): Promise<DataDRequest.IRequestResponseListA<ISesionVigencia>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "sesion/VerificarSesion";
    const params = {
        "IdSesion": DataUtil._Usuario.IdSesion || -1,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    const itemVigencia: ISesionVigencia = {
        Id: 1,
        Vigencia: 1,
        EnUso: true
    }

    return new Promise((resolve, reject) => {
        if (params.IdSesion == -1) {
            resolve([{
                Resultado: 1,
                Datos: [itemVigencia],
                Maxima: null,
            }])
            return;
        }
        DataDRequest._Request5(url, "POST", params)
            .then(result => {
                if ([-1, -2].includes(result.Resultado)) {
                    itemVigencia.Vigencia = result.Resultado;
                }
                resolve([{
                    Resultado: 1,
                    Datos: [itemVigencia],
                    Maxima: null,
                }])
            })
    })
}

/**
 * @returns
 * * res.Data >= 0 -> Numero de sesiones (Inicios de sesión) acumuladas
 * * res.Data < 0 -> No existe el usuario
 */
export async function _SvSesionObtenerInfo(idUsuario: number): Promise<DataDRequest.IResultadoPeticion<number>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "sesion/ObtenerInfo";
    const params = {
        "IdUsuario": idUsuario
    }

    return new Promise((resolve, reject) => {
        DataDRequest._Request(url, "GET", params, (result: DataDRequest.IRequestResponseBase) => {
            resolve({
                Resultado: (result?.Resultado || -100),
                Data: result?.Resultado,
                TipoRequest: IDREQUEST
            })
        })
    })
}

export async function _SvSesionCambiarContrasenia(oldPasswd: string, newPasswd: string): Promise<DataDRequest.IResultadoPeticion<any>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "sesion/CambiarContrasenia";
    const params = {
        IdUsuarioModifica: DataUtil._Usuario.IdUsuario,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        ContraseniaOld: oldPasswd,
        ContraseniaNew: newPasswd
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

interface IRecuperaResponse {
    Estado: number;
}
export function _SvSesionRecuperarContrasenia(email: string): Promise<DataDRequest.IResultadoPeticion<IRecuperaResponse>> {
    const url = Global._GLOBAL_CONF.URL_RSTV1 + "Sesion/RecuperarContrasenia";
    const params = {
        Correo: email
    }

    return new Promise(resolve => {
        DataDRequest._Request(url, "POST", params, (result: IRecuperaResponse) => {
            resolve({
                Resultado: (result?.Estado || -100),
                TipoRequest: IDREQUEST
            })
        })
    })
}

// export async function fn_ConsultarLogin<TParam>(correo: string, contrasenia: string): Promise<data.DRequest.IResultadoPeticion<TParam>> {
//     var params = {
//         "Correo": correo,
//         "Contrasenia": contrasenia
//     }

//     var url = data.Utils.prop_URL_RSTV1 + "Sesion/IniciarSesion";
//     return new Promise(function (resolve) {
//         DRequest.fn_Request(url, "POST", params, (response: data.DRequest.IResultadoPeticion<TParam>) => {
//             /*var user: Entidades.IUsuario = result != null ? result.Datos : {};
//             var estado = result != null ? result.Estado : 0;*/
//             response = <data.DRequest.IResultadoPeticion<TParam>>{
//                 Resultado: response ? response["Estado"] : -1,
//                 Data: response ? response["Datos"] : null,
//                 TipoRequest: IDREQUEST
//             }

//             resolve(response);
//             /*if (callback)
//                 callback(estado, user);*/
//         });
//     });
// }

export default {
    _DiccSesionVigencia,
    _VerificarSesion,
}
