import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { Main } from "../../Main";
import { Entidad } from "../../data/Entidad";
import { _SvAdmin_GetExcelTemplateToExport, IExportarExcelPlantillaInfo } from "../../data/modulo/Admin";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { NotificacionV2 } from "./NotificacionV2";

export namespace ExcelThings {

    export function _LoadWorkbook(file: File): Promise<ExcelJS.Workbook> {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();

            fileReader.onloadend = async e => {
                let wb = new ExcelJS.Workbook();
                await wb.xlsx.load(fileReader.result as ExcelJS.Buffer);
                resolve(wb);
            };
            fileReader.onerror = e => {
                console.warn("-d", "Error onload file: ", e);
                reject(e);
            };
            fileReader.onabort = (e) => {
                console.warn("-d", "Error onabort file", e);
                reject(e);
            }

            fileReader.readAsArrayBuffer(file);
        })
    }

    function ExportFile(libro: ExcelJS.Workbook, fileName: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            libro.xlsx.writeBuffer({ useStyles: true }) // DOTEST
                .then((dataBuffer) => {
                    const blobOptions: BlobPropertyBag = {
                        type: " application/vnd.openxmlformats-officedocument. spreadsheetml.sheet",
                    }
                    const blob = new Blob([dataBuffer], blobOptions);

                    saveAs(blob, (fileName + "_" + UIUtilTime._DateFormatStandar(new Date())) + ".xlsx");
                    resolve(true);
                })
                .catch(() => reject(false));
        })
    }

    export interface IColumnToExcelExportFileConfig<TData> {
        Field: keyof TData;
        HeaderTag: string;
        /** @default 20 */
        WidthCell?: number;
        /** @default "text" */
        TypeData?: "text" | "date" | "number" | "email";
    }

    export interface ISheetConfig<TData> {
        /** Recomendado para Id de Escuela */
        IdSheet: number;
        /** Los nombres por hoja deben ser únicos */
        SheetName: string;
        Data: TData[];
        ExtraPie?: {
            Label: string;
            Value: string | number;
            /** @default "left" */
            Alignment?: Partial<ExcelJS.Alignment>["horizontal"];
        }[];
        // Level: number; // NOTE 🤔🤔🤔
    }

    export interface IConfigExcelExport<TData> {
        // OnGetTemplateData: () => Promise<data.modulos.Admin.IExportarExcelResponse>;
        ColumnsConfig: IColumnToExcelExportFileConfig<TData>[];
        /** @default true */
        ConsultarLogos?: boolean;
        FileName: string;
        IdCategoria: number;
        IdsEscuelas: number[];
        Modulo: Entidad.CModulo;
        // Notificador: controlD3.Notificacion.IObjetoControl;
        OnGetDataBySheets: () => Promise<ISheetConfig<TData>[]>;
        OnGetEscuelasTagInSheet: (datos: TData[]) => string;
        OnStepFieldInDataRow?: (field: keyof TData, dato: TData) => (string | number) | Promise<(string | number)>; // Date // NOTE SE PUEDE??
        RequestType: Entidad.CTipoRequest;
        /** @default por defecto es el mismo que FileName */
        TagEncabezadoHoja?: string;
        ElementToAppendProgressBar?: d3.Selection<HTMLElement, any, any, any>;
    }

    // ***** DELETE ? ******
    interface IInfoImagenExcel {
        type: string; // "image";
        imageId: string;
        range: ExcelJS.ImageRange;
    }

    interface IConfigLogo {
        // /** Nombre de la hoja (Único) */
        // Nombre: string;
        Logo: File;
        IdExcelImagen: number;
        ExcelLogoWidth: number;
        ExcelLogoHeight: number;
    }
    // *********************

    export async function _ExportData<T>(config: IConfigExcelExport<T>) {
        // >> Verifica permisos
        if (config.Modulo) {
            let permissionInfo = UIUtilPermission._HasActionsPermissionFromManySchools(Entidad.CAccionPermiso.ExportarAExcel, config.Modulo, config.IdsEscuelas);
            if (!permissionInfo.HasPermission) {
                // mostrar mensaje
                NotificacionV2._Mostrar(permissionInfo.Message, "ADVERTENCIA");
                return
            }
        }

        // >> Prepara config
        config.TagEncabezadoHoja = config.TagEncabezadoHoja || config.FileName;
        config.ElementToAppendProgressBar = (config.ElementToAppendProgressBar || Main._Template._ContenedorPrincipal) as d3.Selection<HTMLElement, any, any, any>;
        config.ConsultarLogos = config.ConsultarLogos == null ? true : config.ConsultarLogos;
        config.ColumnsConfig.forEach(d => {
            d.TypeData = d.TypeData || "text";
            d.WidthCell = d.WidthCell || 20;
        })

        // >> Init
        let progress = config.ElementToAppendProgressBar.append<HTMLProgressElement>("wc-progress")
            .attr("oculto", false);

        Main._Template._ContenedorPrincipal
            .classed("cursor-progress", true);

        let RemoveProgress = (mess?) => {
            progress.attr("oculto", true);

            Main._Template._ContenedorPrincipal
                .classed("cursor-progress", false);

            if (mess) {
                console.warn("-d", "Excel Export -> ", mess);
            }
        }

        let idsSchoolsToGetLogos = [];
        if (config.ConsultarLogos) {
            idsSchoolsToGetLogos = config.IdsEscuelas;
        }
        let templateInfo = await _SvAdmin_GetExcelTemplateToExport(idsSchoolsToGetLogos, config.IdCategoria, config.RequestType);

        if (templateInfo.Resultado > 0) {
            _LoadWorkbook(templateInfo.Datos.FileTemplate)
                .then(async (book) => {
                    let dataToExport = await config.OnGetDataBySheets();
                    if (dataToExport != null) {
                        dataToExport.forEach(d => {
                            // >> Recorta el nombre de Hoja, si tiene más de 30 carácteres
                            // La lib truena
                            d.SheetName = d.SheetName.slice(0, 30);
                        })

                        // >> Start proccess
                        await ExportDataProccess(book, dataToExport, config, templateInfo.Datos.Info, templateInfo.Datos.EscuelaLogos);
                        RemoveProgress();
                    } else {
                        RemoveProgress();
                        return;
                    }
                })
                .catch(error => {
                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                    RemoveProgress(error);
                })
        } else {
            NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
            RemoveProgress("Error to get template data");
        }
    }

    function ExportDataProccess<TDato>(book: ExcelJS.Workbook, dataToExport: ISheetConfig<TDato>[], config: IConfigExcelExport<TDato>, templateInfo: IExportarExcelPlantillaInfo, logosInfo?: Map<number, File>) {
        const abecedario = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        let mapCeldasEncabezados: Map<string, { celda: string, valor: keyof TDato }> = new Map();
        let columnaTablaExcel: Array<ExcelJS.TableColumnProperties> = [];
        // let listasPorHoja: Map<number, Array<any>>

        let ReadLogo = function (logo: File, fileName: string, infoLogoOriginal: IInfoImagenExcel): Promise<IConfigLogo> {
            let configHoja: IConfigLogo = {
                Logo: logo,
                IdExcelImagen: -1,
                ExcelLogoHeight: 5,
                ExcelLogoWidth: 5
            }

            return new Promise((resolve, reject) => {
                let fileReader: FileReader = new FileReader();

                fileReader.onloadend = (e) => {
                    let imagen = new Image();

                    configHoja.IdExcelImagen = book.addImage({
                        base64: <string>fileReader.result,
                        extension: "png"
                    });

                    imagen.onload = (e) => {
                        let propor = (infoLogoOriginal ? infoLogoOriginal.range["ext"].height * 100 / imagen.height : 100) / 100;

                        configHoja.ExcelLogoWidth = imagen.width * propor;
                        configHoja.ExcelLogoHeight = imagen.height * propor;
                        // resolve(configHoja.IdExcelImagen);
                        resolve(configHoja);
                    }
                    imagen.onerror = () => {
                        console.warn("-d", "Error (2) al leer el logo de " + fileName);
                        // configHoja.IdExcelImagen = -2;
                        reject(null);
                    }
                    imagen.src = <string>fileReader.result
                }

                fileReader.onerror = (e) => {
                    console.warn("-d", "Error (1) al leer el logo de " + fileName);
                    // configHoja.IdExcelImagen = -1;
                    reject(null);
                }

                fileReader.readAsDataURL(logo);
            })
        }

        let InsertarDatosPorHoja = async function (item: ISheetConfig<TDato>, infoLogoOriginal: IInfoImagenExcel, hojaOriginal: ExcelJS.Worksheet, logoInfo?: IConfigLogo) {
            // let item.IdSheet = item.IdSheet;
            let sheet: ExcelJS.Worksheet = book.getWorksheet(item.SheetName);
            let dataExcelFinal: any[][] = [];

            for (let datum of item.Data) {
                let row = [];
                for (let colConfig of config.ColumnsConfig) {
                    let value: any = datum[colConfig.Field] || "";

                    if (config.OnStepFieldInDataRow) {
                        let val = config.OnStepFieldInDataRow(colConfig.Field, datum);
                        if (val instanceof Promise) {
                            value = await val;
                        } else {
                            value = val;
                        }
                    } else if (colConfig.TypeData == "date") {
                        if (typeof value == "string" && value.trim()) {
                            // FIX_TIMEZONE
                            // value = new Date(value); FIXME DESCOMENTAR AJUSTAR FORMATO DE SALIDA
                            value = UIUtilTime._DateFormatStandar(new Date(value)); // REMOVER
                        }
                        else if (!(value instanceof Date)) {
                            value = "";
                        }
                    }
                    else if (colConfig.TypeData == "number") {
                        if (typeof value == "string" && value.trim()) {
                            value = Number(value);
                        }
                        else if (!(typeof value == "number")) {
                            value = "";
                        }
                    }
                    row.push(value);
                }
                dataExcelFinal.push(row);
            }

            let origen = templateInfo.RangoTable[0].split("");
            // let configHoja = config.Hojas.get(item.IdSheet);
            let color = hojaOriginal.getCell(origen[0] + (parseInt(origen[1]) + 2)).style.fill;//B11
            const tableRowsAdded = item.Data.length >= 2 ? item.Data.length - 2 : 0;
            // const encabezadosInfo = Array.from(config.MapCampoEncabezado.values());

            //didujar filas con formato sin datos
            AjustaEncabezadosDeHoja(sheet, item.Data);

            if (logoInfo?.Logo && logoInfo.IdExcelImagen > -1) {
                if (infoLogoOriginal) {
                    sheet.addImage(logoInfo.IdExcelImagen, {// infoLogoOriginal.range)
                        tl: infoLogoOriginal.range.tl,
                        ext: { width: logoInfo.ExcelLogoWidth, height: logoInfo.ExcelLogoHeight }
                    });
                } else {
                    // console.info("Sin logo template")
                    sheet.addImage(logoInfo.IdExcelImagen, {// infoLogoOriginal.range)
                        tl: { col: 1, row: 0 },
                        ext: { width: logoInfo.ExcelLogoWidth, height: logoInfo.ExcelLogoHeight }
                    });
                }
            } else {
                if (infoLogoOriginal) {
                    sheet.addImage(Number(infoLogoOriginal.imageId), infoLogoOriginal.range);
                }
            }

            /*Se quita el color de la celda default* */
            sheet.getCell(origen[0] + (parseInt(origen[1]) + 2)).style.fill = sheet.getCell(origen[0] + (parseInt(origen[1]) + 1)).style.fill;
            //B11                                                                    B10

            sheet.removeTable("MyTable");
            let insertedRows = sheet.insertRows(parseInt(origen[1]) + 2, new Array(tableRowsAdded), "i");

            sheet.addTable({
                name: 'MyTable',
                ref: templateInfo.RangoTable[0],//7
                headerRow: true,
                totalsRow: false,
                columns: columnaTablaExcel,//columnas,
                rows: dataExcelFinal
            });

            let ArrayEncabezado = Array.from(mapCeldasEncabezados.values());
            let FinalDiagonal = ArrayEncabezado[ArrayEncabezado.length - 1].celda.split("");
            let RangoInicio = origen[0] + (parseInt(origen[1]) + 1);

            let numeroFinal = (FinalDiagonal.length === 3) ? parseInt(FinalDiagonal[1] + FinalDiagonal[2]) : parseInt(FinalDiagonal[1]);
            let RangoFinal = FinalDiagonal[0] + (numeroFinal + item.Data.length);

            sheet.addConditionalFormatting({
                ref: RangoInicio + ":" + RangoFinal,
                rules: [
                    {
                        type: "expression",
                        priority: 1,
                        formulae: ['MOD(ROW(),2)'],
                        style: {
                            fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: color["fgColor"]["argb"] } } as any
                        }
                    }
                ]
            });

            /**
             * Ajustar el pie de pagina
             */
            const [pieCol, pieRowP1, pieRowP2] = templateInfo.Pie.split("");
            const indiceLetraF = FinalDiagonal[0];
            const pieRowConfig = (pieRowP2 != null) ? parseInt(pieRowP1 + pieRowP2) : parseInt(pieRowP1);
            const pieRowFinal = pieRowConfig + tableRowsAdded;
            const cellnameTotal = indiceLetraF + pieRowFinal;

            const pieIndexCol2 = abecedario.indexOf(pieCol) + 1;
            const cellnamePieTotalTemp = abecedario[pieIndexCol2] + pieRowFinal
            // let indiceLetra = abecedario.indexOf(pieCol);
            const cellnamePieMergeCellInicio = pieCol + pieRowFinal;
            const cellnamePieMergeCellFinal = abecedario[pieIndexCol2] + pieRowFinal;

            const cellPieStyleLabelTemplate = sheet.getCell(cellnamePieMergeCellInicio).style;
            const cellPieStyleValueTemplate = sheet.getCell(cellnamePieTotalTemp).style;

            /*Imprimir el total de las filas* */
            sheet.getCell(cellnameTotal).value = item.Data.length;
            sheet.getCell(cellnameTotal).style = cellPieStyleValueTemplate;

            /*Merge de las filas* */
            const totalLabeltoFix: string = sheet.getCell(cellnamePieMergeCellInicio).value + "";
            sheet.getCell(cellnamePieMergeCellInicio).value = UIUtilStrings._CapitaliceString(totalLabeltoFix.toLowerCase());
            sheet.mergeCells(`${cellnamePieMergeCellInicio}:${cellnamePieMergeCellFinal}`);

            let extraRowPie = pieRowFinal
            item.ExtraPie?.forEach(({ Label, Value, Alignment }) => {
                extraRowPie++;
                const cellnamePieExtraMergeCellInicio = pieCol + extraRowPie;
                const cellnamePieExtraMergeCellFinal = abecedario[pieIndexCol2] + extraRowPie;
                const cellnamePieExtraValue = indiceLetraF + extraRowPie;

                sheet.getCell(cellnamePieExtraMergeCellInicio).value = Label;
                sheet.getCell(cellnamePieExtraMergeCellInicio).style = cellPieStyleLabelTemplate;
                sheet.mergeCells(`${cellnamePieExtraMergeCellInicio}:${cellnamePieExtraMergeCellFinal}`);

                sheet.getCell(cellnamePieExtraValue).value = Value;
                sheet.getCell(cellnamePieExtraValue).style = cellPieStyleValueTemplate;
                sheet.getCell(cellnamePieExtraValue).alignment = { horizontal: Alignment || "left" }
            })

            let noLastRowTable = parseInt(origen[1]) + 1;
            let indexCol = 0;
            if (insertedRows) {
                insertedRows.forEach(row => {
                    noLastRowTable = row.number + 1;
                    row.eachCell((cell, noCol) => {
                        if (config.ColumnsConfig[indexCol] && config.ColumnsConfig[indexCol].WidthCell) {
                            sheet.getColumn(noCol).width = config.ColumnsConfig[indexCol].WidthCell;
                        }
                        cell.alignment = {
                            wrapText: true
                        }
                        indexCol++;
                    });
                    indexCol = 0;
                });
            }
            sheet.getRow(noLastRowTable).eachCell((cell, noCol) => {
                if (config.ColumnsConfig[indexCol] && config.ColumnsConfig[indexCol].WidthCell) {
                    sheet.getColumn(noCol).width = config.ColumnsConfig[indexCol].WidthCell;
                }
                cell.alignment = { wrapText: true }
                indexCol++;
            });

            // sheet.name = configHoja.Nombre;
        }

        // let AjustarDatos = function () {
        //     config.ListaParaExportar.forEach((datum, i) => {
        //         if (datum[config.IdCampoHoja]) {
        //             let CampoIdPorHoja = Number(datum[config.IdCampoHoja]);
        //             let filas = listasPorHoja.get(CampoIdPorHoja);
        //             let auxDatum = [];
        //             if (!filas) {
        //                 filas = [];
        //             }
        //             config.MapCampoEncabezado.forEach(function (nameEnc, nameCampo) {
        //                 datum[nameCampo] = ValidacionItemExportar(datum[nameCampo], nameCampo)
        //                 auxDatum.push(datum[nameCampo]);
        //             });
        //             filas.push(auxDatum);
        //             // this_c.listaToView.push(auxDatum); //Arreglo de arreglos
        //             listasPorHoja.set(CampoIdPorHoja, filas);
        //         }
        //     });

        //     config.MapCampoEncabezado.forEach(function (nameEnc) {

        //     });
        // }

        let CopiaHojaOriginalPorKinder = function (sheetOrigin: ExcelJS.Worksheet) {
            // config.Hojas.forEach((d, k) => {
            dataToExport.forEach((d, k) => {
                let newWorkSheet: ExcelJS.Worksheet;
                let rowsOrigin = sheetOrigin.getRows(0, 20);
                newWorkSheet = book.addWorksheet(d.SheetName, {
                    views: [
                        { showGridLines: false }
                    ]
                });
                if (!newWorkSheet) return

                for (let a = 0; a < config.ColumnsConfig.length + 10; a++) {
                    // let lengthI = (config.ListaParaExportar.length < 20 ? 20 : config.ListaParaExportar.length) + 10;
                    let lengthI = (d.Data.length < 20 ? 20 : d.Data.length) + 10;
                    for (let i = 0; i <= lengthI; i++) {
                        let letra = abecedario[a];
                        if (letra) {
                            newWorkSheet.getCell(letra + i).fill = {
                                type: 'pattern',
                                pattern: "lightHorizontal",
                                fgColor: { argb: 'FFFFFF' }
                            };
                        }
                        newWorkSheet.getRow(i).fill = {
                            type: 'pattern',
                            pattern: "lightHorizontal",
                            fgColor: { argb: 'FFFFFF' }
                        };
                    }
                }
                newWorkSheet.properties = sheetOrigin.properties;

                for (let i = 1; i <= rowsOrigin.length; i++) {
                    if (rowsOrigin[i]) {
                        rowsOrigin[i].eachCell((cellOrigin, noCol) => {
                            let newRow = newWorkSheet.getRow(i);
                            newWorkSheet.getColumn(noCol).width = sheetOrigin.getColumn(noCol).width;
                            newRow.height = rowsOrigin[i].height;

                            let newCell = newRow.getCell(cellOrigin.col);
                            newCell.value = cellOrigin.value;
                            newCell.style = cellOrigin.style;

                            // copy original cells merge
                            if (cellOrigin.address != cellOrigin.master.address) {
                                let mergeRef = cellOrigin.master.address + ":" + cellOrigin.address;
                                try {
                                    newWorkSheet.mergeCells(mergeRef);
                                } catch (e) {
                                    newWorkSheet.unMergeCells(mergeRef);
                                    newWorkSheet.mergeCells(mergeRef);
                                }
                            }
                        });
                    }
                }
            });
        }

        let AjustaEncabezadosDeHoja = function (hoja: ExcelJS.Worksheet, dataSheet: TDato[]) {//: { Fila1: ExcelJS.Cell, Fila2: ExcelJS.Cell, Formato: boolean } {
            let origen = templateInfo.RangoTable[0].split("");

            //Inicizar Encabezado y array de Letras de los encabezados
            let nuevoOrigen = origen[0];
            let i = 0;
            // config.MapCampoEncabezado.forEach((encabezado, campoKey) => {
            config.ColumnsConfig.forEach((d) => {
                if (i == 0)
                    mapCeldasEncabezados.set(templateInfo.RangoTable[0], { celda: templateInfo.RangoTable[0], valor: d.Field });
                else {
                    let getIndex = abecedario.indexOf(nuevoOrigen);
                    let cell = abecedario[getIndex + 1] + origen[1];
                    nuevoOrigen = abecedario[getIndex + 1];
                    mapCeldasEncabezados.set(cell, { celda: cell, valor: d.Field });
                }
                i++;
            });

            //insertar Fecha de descarga
            hoja.getCell(templateInfo.FechaDescrga).value = UIUtilTime._DateFormatStandar(new Date()) // .toDateString();
            hoja.getCell(templateInfo.Escuela).value = config.OnGetEscuelasTagInSheet(dataSheet); //  nombreEscuela; // config.Escuela.titulo;
            hoja.getCell(templateInfo.Titulo).value = config.TagEncabezadoHoja;

            /**
             * Estilos a las columnas
             */
            let acum = parseInt(origen[1]);
            let styleColuma = hoja.getCell(templateInfo.RangoTable[0]).style;

            mapCeldasEncabezados.forEach(function (datoCelda, i) {
                hoja.getCell(datoCelda.celda).style = styleColuma;
                hoja.getColumn(acum).width = 15;
                acum = acum + 1;
            });
        }

        // let ValidacionItemExportar = function (value: any, campo: keyof TDato): string {
        //     let valor = value != undefined && value != null ? value : "";
        //     if (config.CampoFecha) {
        //         config.CampoFecha.forEach(campoFecha => {
        //             if (campo == campoFecha && valor != "") {
        //                 const splitDate = valor.split("-");
        //                 value = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
        //             }
        //         })
        //     }
        //     return value;
        // }

        let InitExportar = async function (): Promise<boolean> {
            // >> Extrae las configuraciones de la plantilla
            let infoLogoBase: IInfoImagenExcel = book.worksheets[0].getImages()[0];
            let hojaOriginal = book.worksheets[0];
            hojaOriginal.name = "template";

            // AjustarDatos();
            config.ColumnsConfig.forEach((colConfig) => {
                columnaTablaExcel.push({ name: colConfig.HeaderTag, filterButton: false });
            })

            CopiaHojaOriginalPorKinder(hojaOriginal);
            // let idsHojas = Array.from(listasPorHoja.keys());

            // ListasPorEscuela.forEach((lista, idEscuela) => {
            // for (let i = 0; i < idsHojas.length; i++) {
            for (let item of dataToExport) {
                // let idHoja = idsHojas[i];
                // let lista = listasPorHoja.get(idHoja);
                // console.log(idHoja, "Data Lenght: " + lista.length, lista);

                // let configHoja = config.Hojas.get(idHoja);
                let logoInfo: IConfigLogo;
                if (logosInfo?.has(item.IdSheet)) {
                    logoInfo = await ReadLogo(logosInfo.get(item.IdSheet), item.SheetName, infoLogoBase);
                }
                //         .finally(() => {
                //             InsertarDatosPorHoja(idHoja, libro.getWorksheet(idHoja + ""), lista, infoLogoOriginal, hojaOriginal);
                //         })
                // } else {
                await InsertarDatosPorHoja(item, infoLogoBase, hojaOriginal, logoInfo);
                // }
            }

            // >> Remueve hoja base
            book.removeWorksheet("template");
            // d3.select("body").classed("cursor-progress", false);
            return await ExportFile(book, config.FileName);
        }

        return InitExportar();
    }
}
