import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetGruposHorariosDeAlumno, _SvAlumnoAsignarMaterias, _SvAlumnoEliminarCalificaciones, _SvAlumnoObtenerCalificaciones, _SvAlumnoObtenerCriteriosAsignados, _SvAlumnoObtenerMateriasAsignadas, AsignacionMateriaToSend } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloMateriaV2 from "../../data/modulo/MateriaV2";
import { DateV2 } from "../../util/DateV2";
import { CalendarioGridYearDateRange } from "../controlD3/CalendarioGridYearDateRange";
import { IConfigCardCollapseExcelExport } from "../controlD3/CardCollapseAdvancedTable";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ModalThings } from "../controlD3/ModalThings";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { Selection } from "d3";
import _L from "../../util/Labels";

import ICicloEscolar = Entidad.ICicloEscolar;
import IAlumno = Entidad.IAlumno;
import CAccionPermiso = Entidad.CAccionPermiso;
import IMateriaDisponible = Entidad.IMateriaDisponible;
import CFreqEval = Entidad.CMateriaFrecuenciaEvaluacion;
import CEstadoEval = Entidad.CEstadoEval;

interface IMateriaDispGrid extends IMateriaDisponible {
    IdGrid: string;
    StrGrupo: string;
}

interface IAsignacionGrid extends Omit<Entidad.IAsignacionAlumnoMateria, "Repeticiones" | "DtInicio" | "DtFin"> {
    /* Se omite de la extensión porque no contienen las mismas propiedades */
    Repeticiones: IAsignacionRepeticion[];

    /* Extras para render */
    NombreMateria: string; //No viene en el nuevo servicio
    StrGrupo: string;
    NoRepeticiones: number;
    Inicio: string;
    Fin: string;

    IdCriterio: number; //No viene en el nuevo servicio

    /* Auxiliar para selcción múltiple de alumnos */
    IDs?: Array<number>;
    IsSafe: boolean;
}

interface IAsignacionRepeticion extends Omit<Entidad.IRepeticionAsignAlumnoMateria, "DtInicio" | "DtFin" | "DtCalificarInicio" | "DtCalificarFin" | "DtCalificacion"> {
    Inicio: string;
    Fin: string;
    CalificarInicio: string;
    CalificarFin: string;
    FechaCalificacion: string;

    /* No vienen en el servicio ObtenerMaterias Asignadas => Vienen pero en cadenas */
    /* Auxiliares para fechas */
    DtFechaInicio: DateV2;
    DtFechaCalificarFin: DateV2;

    /* Aux */
    ParentAsignacion: IAsignacionGrid; // Corresponde al criterio al que pertenece la repetición
    IsSafe?: boolean;

    /* Extras para render */
    Criterio: string;

    /* Auxiliar para selcción múltiple de alumnos */
    IDs?: Array<number>;
}

export class UIPanelCardAlumnosMaterias extends CardV2CollapseAdvancedTable<IAsignacionGrid, [IAlumno[]]> {
    private currentEscuela: Entidad.IEscuela;
    private currentAlumnos: Entidad.IAlumno[];
    private diasNoLaborales: number[];
    private asignacionesCompartidas: Map<string, IAsignacionGrid[]>;
    private idTemporalAux: number;
    private ctrlSelectCicloEscolar: SelectV2<ICicloEscolar, "Id", "monoselect">;
    private areaMenuSelects: Selection<HTMLDivElement, any, any, any>;

    private statusTags: Map<number, string> = new Map([[CEstadoEval.Registered, "tag_registered"], [CEstadoEval.Pending, "tag_pending"], [CEstadoEval.Expired, "tag_pending"], [CEstadoEval.Evaluated, "tag_evaluated"]])

    constructor(modulo: Entidad.CModulo.PanelAlumnoMaterias) {
        super("", modulo);
        this.asignacionesCompartidas = new Map();
        this.idTemporalAux = Date.now();
    }
    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let options: Array<Table.ITableMenuTopDefaultOptionConfig> = [];
        if (this.HasActionPermission(CAccionPermiso.Agregar)) {
            options.push({
                Label: "action_agregar",
                Callback: async () => {
                    if (this.currentAlumnos.length && this.currentEscuela) {
                        this.OpenModal_AddAsignacion();
                    }
                }
            })
        }
        return options;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IAsignacionGrid[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        if (this.currentAlumnos.length !== 1) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("export_fail_multialumnos"), "ADVERTENCIA");
            return null;
        }

        interface IDataToExport {
            Grupo?: string;
            Materia?: string;
            Criterio?: string;
            Frecuencia?: string;
            Repeticiones?: string;
            Inicio?: string;
            Fin?: string;
            Aplicacion?: string;
            Vencimiento?: string;
            Estado?: string;
            Calificacion?: string;
            FechaEvaluacion?: string;
        }

        const hasDataSelected = (this.ctrlTabla._dataChecked.length > 0);
        const alumno = this.GetCurrentStudents()[0];

        return <IConfigCardCollapseExcelExport<IDataToExport>>{
            FileName: alumno.NombreCompleto,
            IdsEscuelas: [alumno.IdKinder],
            TypeRequest: Entidad.CTipoRequest.Alumno,
            ColumnsConfig: [
                { Field: "Grupo", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_strgrupo") },
                { Field: "Materia", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_nombremateria") },
                { Field: "Criterio", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_criterio") },
                { Field: "Frecuencia", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_frecuenciaeval") },
                { Field: "Repeticiones", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_norepeticiones") },
                { Field: "Inicio", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_inicio") },
                { Field: "Fin", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_fin") },
                { Field: "Aplicacion", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_calificarinicio") },
                { Field: "Vencimiento", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_calificarfin") },
                { Field: "Estado", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_estadoevaluacion") },
                { Field: "Calificacion", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_valorevaluacion") },
                { Field: "FechaEvaluacion", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_fechacalificacion") }
            ],
            OnGetDataBySheets: async () => {
                let datosToExport: IDataToExport[] = [];
                let errsGetRepeticiones = false;
                for (let asignacion of dataTable) {
                    let repeticiones: Entidad.IRepeticionAsignAlumnoMateria[] = [];

                    // Definir como se exporta 
                    if (hasDataSelected) {
                        const childsAsignacion = this.ctrlTabla._SelectItemData(asignacion.IdAsignacion).GetChildrenData<IAsignacionRepeticion>();
                        // Caso 1: Hay checkeados y la asignación actual tiene hijos seleccionados => ¿Se obtendrá de la data del padre los hijos seleccionados?
                        if (childsAsignacion.size > 0) {
                            childsAsignacion.forEach(dRepeticion => {
                                if (dRepeticion.IsCheked) {
                                    const repeticionData = dRepeticion.Data;
                                    repeticiones.push(<Entidad.IRepeticionAsignAlumnoMateria>{
                                        DtInicio: repeticionData.Inicio,
                                        DtFin: repeticionData.Fin,
                                        DtCalificarInicio: repeticionData.CalificarInicio,
                                        DtCalificarFin: repeticionData.CalificarFin,
                                        EvaluacionStatus: repeticionData.EvaluacionStatus,
                                        ValorEval: repeticionData.ValorEval,
                                        DtCalificacion: repeticionData.FechaCalificacion
                                    })
                                }
                            })
                        }
                        // Caso 2: Hay checkeados y La asignación actual no tiene hijos checkeados => ¿Se consultarán y renderizarán todas sus repeticiones?
                        else {
                            let resCalRepeticiones = await _SvAlumnoObtenerCalificaciones(asignacion.IdAsignacion);
                            // let resCalRepeticiones = await Data.Modulo.MateriaV2.fn_GetCalificacionesAsignacion(asignacion.IdAsignacion);
                            if (resCalRepeticiones.Resultado > 0) {
                                repeticiones.push(...resCalRepeticiones.Datos);
                            } else {
                                errsGetRepeticiones = true;
                            }
                        }
                    }
                    // Caso 3: Exportar todo => Se consultarán todas las repeticiones
                    else {
                        let resCalRepeticiones = await _SvAlumnoObtenerCalificaciones(asignacion.IdAsignacion);
                        //let resCalRepeticiones = await Data.Modulo.MateriaV2.fn_GetCalificacionesAsignacion(asignacion.IdAsignacion);
                        if (resCalRepeticiones.Resultado > 0) {
                            repeticiones.push(...resCalRepeticiones.Datos);
                        } else {
                            errsGetRepeticiones = true;
                        }
                    }
                    let tagNoRepeticiones = asignacion.NoRepeticiones.toString();
                    if (asignacion.NoRepeticiones !== repeticiones.length) {
                        tagNoRepeticiones = repeticiones.length + "/" + asignacion.NoRepeticiones.toString();
                    }

                    datosToExport.push({
                        Grupo: asignacion.StrGrupo,
                        Materia: asignacion.NombreMateria,
                        Criterio: asignacion.Criterio,
                        Frecuencia: CFreqEval[asignacion.FrecuenciaEval],
                        Repeticiones: tagNoRepeticiones,
                        Inicio: (asignacion.Inicio ? UIUtilTime._DateFormatStandar(asignacion.Inicio) : "---"),
                        Fin: (asignacion.Fin ? UIUtilTime._DateFormatStandar(asignacion.Fin) : "---")
                    })


                    repeticiones.forEach(repeticion => {
                        datosToExport.push({
                            Criterio: asignacion.Criterio,
                            Inicio: repeticion.DtInicio ? UIUtilTime._DateFormatStandar(repeticion.DtInicio) : "---",
                            Fin: repeticion.DtFin ? UIUtilTime._DateFormatStandar(repeticion.DtFin) : "---",
                            Aplicacion: repeticion.DtCalificarInicio ? UIUtilTime._DateFormatStandar(repeticion.DtCalificarInicio) : "---",
                            Vencimiento: repeticion.DtCalificarFin ? UIUtilTime._DateFormatStandar(repeticion.DtCalificarFin) : "---",
                            Estado: this.CARDCOLL_GetUIStringModule(this.statusTags.get(repeticion.EvaluacionStatus)),
                            Calificacion: (repeticion.ValorEval ? repeticion.ValorEval : this.CARDCOLL_GetUIStringModule("tag_sincalificar")),
                            FechaEvaluacion: repeticion.DtCalificacion ? UIUtilTime._DateFormatStandar(repeticion.DtCalificacion) : this.CARDCOLL_GetUIStringModule("tag_sincalificar"),
                        })
                    })
                }
                if (errsGetRepeticiones) this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_failgetrepeticiones"), "ADVERTENCIA");
                return [{
                    IdSheet: alumno.IdKinder,
                    SheetName: alumno.NombreCompleto,
                    Data: datosToExport
                }];
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(alumno.IdKinder).Nombre
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IAsignacionGrid>, "Parent"> {
        return {
            IdTabla: "AlumnosPanelMaterias",
            Title: "",
            IdData: "IdAsignacion",
            MinWidth: 1800,
            RenderColumnHeadings: [
                { Field: "StrGrupo", Label: "", MinWidth: "120px", Width: "10%" },
                { Field: "NombreMateria", Label: "", MinWidth: "125px", Width: "10%" },
                { Field: "Criterio", Label: "", MinWidth: "160px", Width: "10%" },
                { Field: "FrecuenciaEval", Label: "", MinWidth: "100px", Width: "7%", IsSortable: false },
                { Field: "NoRepeticiones", Label: "", MinWidth: "100px", Width: "7%", IsSortable: false },
                { Field: "Inicio", Label: "", MinWidth: "120px", Width: "8%", IsSortable: false },
                { Field: "Fin", Label: "", MinWidth: "120px", Width: "7%", IsSortable: false },
                { Field: "CalificarInicio" as any, Label: "", MinWidth: "120px", Width: "7%", IsSortable: false },
                { Field: "CalificarFin" as any, Label: "", MinWidth: "120px", Width: "7%", IsSortable: false },
                { Field: "EstadoEvaluacion" as any, Label: "", MinWidth: "120px", Width: "7%", IsSortable: false },
                { Field: "ValorEvaluacion" as any, Label: "", MinWidth: "120px", Width: "7%", IsSortable: false },
                { Field: "FechaCalificacion" as any, Label: "", MinWidth: "120px", Width: "7%", IsSortable: false },
            ],
            OrderDefault: { Type: Table.CStatusOrder.Asc, Field: "StrGrupo" },
            OptionsOfDataCheckV3: (datos) => {
                if (datos?.length) {
                    let datosAcciones = this.UI_TableGetSelectedDataMenuLvOne("top-selected", datos)
                    return this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0][0].IdKinder, "top-selected", datosAcciones)
                }
                return null;
            },
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 1,
                Options: []
            },
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IAsignacionGrid, IAsignacionRepeticion, any>>{
                OnEvalIsEnableRow: (asignacion) => {
                    if (!asignacion.Repeticiones.length) return (asignacion.IsSafe && (asignacion.TotalCalificaciones < 1 || asignacion.TotalCalificaciones !== asignacion.TotalCalificados));
                    return (this.Get_ValidaAsignRepeticionesSonValidos(asignacion.Repeticiones))
                },
                OnStepCellTable: (container, datum, field) => {
                    switch (field) {
                        case "Inicio":
                            container.text(datum.Inicio ? UIUtilTime._DateFormatStandar(datum.Inicio) : "---");
                            break;
                        case "Fin":
                            container.text(datum.Fin ? UIUtilTime._DateFormatStandar(datum.Fin) : "---");
                            break;
                        case "FrecuenciaEval":
                            container.text(CFreqEval[datum.FrecuenciaEval]);
                            break;
                    }
                },
                GetOptionsInRowV2: (asignacion) => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.currentAlumnos[0].IdKinder, "row", this.UI_TableGetSelectedDataMenuLvOne("row", [asignacion])),
                EvaluatorSubLevel: {
                    OnGetData: async (dato) => {
                        if (dato.NoRepeticiones < 1) return null;
                        if (this.currentAlumnos.length > 1) {
                            return dato.Repeticiones;
                        }
                        let resCalRepeticiones = await _SvAlumnoObtenerCalificaciones(dato.IdAsignacion);
                        //let resCalRepeticiones = await Data.Modulo.MateriaV2.fn_GetCalificacionesAsignacion(dato.IdAsignacion);
                        if (resCalRepeticiones.Resultado > 0) {
                            dato.Repeticiones = resCalRepeticiones.Datos.map<IAsignacionRepeticion>(d => {
                                const { DtCalificarFin, DtCalificarInicio, DtFin, DtInicio, DtCalificacion, ...rest } = d
                                return {
                                    ...rest,
                                    EvaluacionStatus: d.EvaluacionStatus || 0,
                                    Inicio: d.DtInicio,
                                    Fin: d.DtFin,
                                    CalificarInicio: d.DtCalificarInicio,
                                    CalificarFin: d.DtCalificarFin,
                                    FechaCalificacion: d.DtCalificacion,
                                    DtFechaInicio: null,
                                    DtFechaCalificarFin: null,
                                    ParentAsignacion: dato,
                                    Criterio: dato.Criterio,
                                    IsSafe: true,
                                    IDs: [d.Id],
                                    ValorEval: d.ValorEval
                                }
                            })
                            return dato.Repeticiones;
                        }
                        return new Error(this.CARDCOLL_GetUIStringModule("fail_getcalrepeticiones"));
                    },
                    IdMember: "Id",
                    OnStepRowTable: (datum, tableRow, rowBody, indexOrigin, parentData, restOfParentsData) => {
                        tableRow.classed("vencido", datum.EvaluacionStatus == CEstadoEval.Expired);
                    },
                    OnStepCellTable: (container, datumRep, field) => {
                        switch (field) {
                            case "FrecuenciaEval":
                                container.text("");
                                break;
                            case "Inicio":
                                container.text(datumRep.Inicio ? UIUtilTime._DateFormatStandar(datumRep.Inicio) : "---");
                                break;
                            case "Fin":
                                container.text(datumRep.Fin ? UIUtilTime._DateFormatStandar(datumRep.Fin) : "---");
                                break;
                            case "CalificarInicio":
                                container.text(datumRep.CalificarInicio ? UIUtilTime._DateFormatStandar(datumRep.CalificarInicio) : "---");
                                break;
                            case "CalificarFin":
                                container.text(datumRep.CalificarFin ? UIUtilTime._DateFormatStandar(datumRep.CalificarFin) : "---")
                                container.classed("fecha_venc", true);
                                break;
                            case "EstadoEvaluacion":
                                container.text(this.CARDCOLL_GetUIStringModule(this.statusTags.get(datumRep.EvaluacionStatus)));
                                break;
                            case "FechaCalificacion":
                                container.text(datumRep.FechaCalificacion ? UIUtilTime._DateFormatStandar(datumRep.FechaCalificacion) : "Sin calificar");
                                break;
                            case "ValorEvaluacion":
                                (datumRep.EvaluacionStatus !== CEstadoEval.Evaluated) ? container.text("Sin calificar") : this.CreateCalificacionColumn(datumRep.ParentAsignacion.ValorTipo, datumRep.ValorEval, container);
                                break;
                        }
                    },
                    OnEvalIsEnableRow: (datumRep) => this.Get_Valida_DatoPuedeAccionarse(datumRep),
                    GetOptionsInRowV2: (datumRep) => ({
                        Options: this.UI_TableGetSelectedDataMenuLvTwo(datumRep),
                        MaxOptionsInRow: 3
                    })
                },
                ActiveDataOnlyWhenDisplayData: true,
            },
        }
    }
    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();

        this.areaMenuSelects = container.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
            .style("row-gap", "0px")
            .style("column-gap", "10px")
            .style("flex-wrap", "nowrap")
            .style("max-width", "400px")

        this.ctrlSelectCicloEscolar = new SelectV2<ICicloEscolar, "Id">({
            Parent: this.areaMenuSelects,
            Type: "monoselect",
            DisplayMember: "Nombre",
            ValueMember: "Id",
            Data: [],
            OnChange: () => {
                this.UI_UpdateCardData();
            }
        })

        this.areaMenuSelects.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectCicloEscolar, _L("panelboletas.filtro_cicloesc")).node())
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno[]): string {
        return cardData_0[0].IdChild.toString();
    }
    protected CARDCOLL_OnUpdateData(alumnosData: IAlumno[]): void | Promise<void> {
        this.currentEscuela = DataModuloEscuela._DiccEscuela.get(alumnosData[0].IdKinder);
        this.currentAlumnos = alumnosData.sort((dA, dB) => dA.IdChild - dB.IdChild);
        if (!this.currentEscuela) {
            this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
        }
        this.diasNoLaborales = [];
        if (this.currentEscuela.HoraEntradas) {
            this.currentEscuela.HoraEntradas.forEach((dia, i) => {
                if (dia == "") {
                    console.warn("Dia No Laboral agregado: ", dia, i)
                    this.diasNoLaborales.push(i + 1);
                }
            })
        }
        if (!this.CARDCOLL_StatusCardExpandido) {
            this.ctrlTabla._UpdateData([]);
            return null;
        }
        return this.UI_UpdateCardData();
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardSelection.style("height", "100%");
        this.UI_UpdateCardData();
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null)
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return () => this.UI_UpdateCardData();
    }
    protected CARDCOLL_GetIdSchool(cardData_0: IAlumno[]): number {
        return cardData_0[0].IdKinder;
    }

    // >> VALIDACIONES
    /** Retorna true, si al menos una de las repeticiones (IsSafe y no ha sido evaluado */
    private Get_ValidaAsignRepeticionesSonValidos(datos: IAsignacionRepeticion[]) {
        return Boolean(datos.find(d => (this.Get_Valida_DatoPuedeAccionarse(d))));
    }

    private CreateCalificacionColumn(tipoEval: number, calificacion: string, container: TSelectionHTML<"div", any, any>) {
        if (tipoEval == 2) {
            container.text("");
            container
                .style("height", "23px")
                .style("width", "23px")
                .style("margin-left", "25px")
                .style("background-color", calificacion)
                .style("border-radius", "50%")
                .style("border", "1px solid var(--color_borderbox1)");
        } else {
            container.text(calificacion);
        }
    }

    private checkDateValidity(fechaInicial: Date, diasNoLaborales: number[], fechasDescartar: Date[]): DateV2 {
        let fechaToReturn = new DateV2(fechaInicial)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true);
        let dayInWeek = UIUtilTime._GetDayInWeek(fechaToReturn);
        if (diasNoLaborales.includes(dayInWeek)) {
            fechaToReturn.setDate(fechaToReturn.getDate() + 1)
            return this.checkDateValidity(fechaToReturn, diasNoLaborales, fechasDescartar);
        }

        for (const fecha of fechasDescartar) {
            if (fechaToReturn.toDateString() === fecha.toDateString()) {
                fechaToReturn.setDate(fechaToReturn.getDate() + 1);
                return this.checkDateValidity(fechaToReturn, diasNoLaborales, fechasDescartar);
            }
        }
        return fechaToReturn;
    }

    private GetCurrentAsignacionesKey(currentAlumnos = this.currentAlumnos) {
        return currentAlumnos
            .map(d => (d.IdChild))
            .join();
    }

    private UI_UpdateCardData() {
        this.areaMenuSelects.classed("hide", this.GetStudentsCount() > 1)
        const ciclosEscolaresList = this.GetCiclosEscolaresList(false);
        ciclosEscolaresList.sort((a, b) => UIUtilTime._GetValidatorDateYMD(new Date(b.FechaInicio)) - UIUtilTime._GetValidatorDateYMD(new Date(a.FechaInicio)))
        this.ctrlSelectCicloEscolar._UpdateList(ciclosEscolaresList);
        if (!this.ctrlSelectCicloEscolar._dataValueMemberSelected.length) {
            this.ctrlSelectCicloEscolar._valueSelect(this.GetCurrentCicloEscolar(ciclosEscolaresList)?.Id)
        }

        return new Promise<void>(async (resolve, reject) => {
            let dataResult: IAsignacionGrid[] = [];
            this.ctrlProgress.attr("oculto", false);
            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.MateriaV2, this.currentEscuela.IdKinder);
            this.ctrlProgress.attr("oculto", true);
            if (this.currentAlumnos.length > 1) {
                console.warn("MultiAlumnos");
            } else {
                const [cicloEscolarSelected] = this.ctrlSelectCicloEscolar._dataValueMemberSelected;
                if (cicloEscolarSelected != null) {
                    this.ctrlProgress.attr("oculto", false)
                    //let materiasAsignadas = await Data.Modulo.MateriaV2.fn_GetMateriasAsignadas(this.currentEscuela.IdKinder, this.currentAlumnos[0].IdChild);
                    let materiasAsignadas = await _SvAlumnoObtenerMateriasAsignadas(this.currentEscuela.IdKinder, this.currentAlumnos[0].IdChild, cicloEscolarSelected);
                    this.ctrlProgress.attr("oculto", true);
                    if (materiasAsignadas.Resultado > 0) {
                        dataResult = materiasAsignadas?.Datos.map(asignacion => {
                            const { DtFin, DtInicio, Repeticiones, ...rest } = asignacion
                            return {
                                ...rest,
                                TotalCalificados: (asignacion.IdAsignacion !== 7) ? asignacion.TotalCalificados : 1,
                                StrGrupo: DataModuloMain._GetDataValueFieldByName("Grupo", asignacion.IdGrupo, "Nombre"),
                                IdCriterio: null,
                                NombreMateria: DataModuloMain._GetDataValueFieldByName("MateriaV2", asignacion.IdMateria, "Nombre") || _L("general.nodisponible"),
                                NoRepeticiones: asignacion.TotalCalificaciones,
                                Inicio: asignacion.DtInicio,
                                Fin: asignacion.DtFin,
                                Repeticiones: [],
                                IDs: [asignacion.IdAsignacion],
                                IsSafe: true
                            }
                        })
                        dataResult = dataResult.filter(d => d.EnUso);
                        this.asignacionesCompartidas.set(this.currentAlumnos.map(d => d.IdChild)[0].toString(), dataResult);
                    } else {
                        this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                    }
                }
            }
            this.ctrlTabla._UpdateData(this.asignacionesCompartidas.get(this.GetCurrentAsignacionesKey()) || []);
            resolve();
        })
    }

    private UI_TableGetSelectedDataMenuLvOne(location: "row" | "top-selected", asignacionesMateriasAlumnos: IAsignacionGrid[]) {
        let opciones: Table.ITableMenuDataSelectedOptionConfig<IAsignacionGrid>[] = [];
        //
        let fnEachAsignacionRepeticionNoProcesada = (datos: IAsignacionGrid[], iteratorAsignaciones: (repeticion: IAsignacionRepeticion & { isMaster?: boolean }) => void) => {
            // De acuerdo a la posición de la llamada => Desde el TOP con selección / En la fila ROW
            switch (location) {
                case "row":
                    // Si no tiene repeticiones
                    if (!datos[0].Repeticiones.length) {
                        // Se asume que es padre y se agrega de forma que su propiedad isMaster es True con pocas propiedades => Id: en el que se suplanta por el id del padre / isMaster: define que es un dato padre / ParentAsignación: se pone a sí mismo 
                        iteratorAsignaciones(<IAsignacionRepeticion & { isMaster?: boolean }>{ Id: datos[0].IdAsignacion, isMaster: true, ParentAsignacion: datos[0], IDs: datos[0].IDs })
                        return;
                    }
                    // Si tiene repeticiones por cada uno de sus rep
                    datos[0].Repeticiones.
                        forEach(repeticion => {
                            // Verifica si puede accionarse
                            if (this.Get_Valida_DatoPuedeAccionarse(repeticion)) {
                                // la agrega como va
                                iteratorAsignaciones(repeticion);
                            }
                        })
                    break;
                // Cuando la llamada viene del TOP
                case "top-selected":
                    // Se obtiene toda la data que fue chequeada de la tabla
                    let dataCheckedInfo = this.ctrlTabla._DataAndChildsDataChecked as Table.IDataCheckedAdvanced<IAsignacionGrid, IAsignacionRepeticion>[];
                    // Por cada una de la data que fue chequeada se itera
                    dataCheckedInfo
                        .forEach(datoAsigInfo => {
                            // Se evalua si tiene hijos chequeados o no
                            // Si no tiene hijos chequeados se entiende que es solo el Padre
                            if (!datoAsigInfo.ChildsChecked) {
                                // Se agrega de forma que su propiedad isMaster es True con pocas propiedades => Id: en el que se suplanta por el id del padre / isMaster: define que es un dato padre / ParentAsignación: se pone a sí mismo
                                iteratorAsignaciones(<IAsignacionRepeticion & { isMaster?: boolean }>{ Id: datoAsigInfo.Data.IdAsignacion, isMaster: true, ParentAsignacion: datoAsigInfo.Data, IDs: datoAsigInfo.Data.IDs })
                                return;
                            }
                            // Si tiene Hijos Checkeados por cada uno de los hijos checkd
                            datoAsigInfo.ChildsChecked
                                .forEach(d => {
                                    // Valida si puede accionarse
                                    if (this.Get_Valida_DatoPuedeAccionarse(d.Data)) {
                                        // Lo agrega como va
                                        iteratorAsignaciones(d.Data)
                                    }
                                })
                        });
                    break;
            }
        }

        if (this.HasActionPermission(Entidad.CAccionPermiso.Eliminar) /* && this.currentAlumnos.length == 1 */) {
            opciones.push({
                Label: "action_delpendientes",
                Callback: (asignaciones) => {
                    // Se obtienen todas las repeticiones(Puede venir asignaciones padres para suplantar de ser así vendrá con la propiedad isMaster en true)
                    let repeticiones: Array<IAsignacionRepeticion & { isMaster?: boolean }> = [];
                    fnEachAsignacionRepeticionNoProcesada(asignaciones, (repeticion) => {
                        repeticiones.push(repeticion);
                    })

                    this.OpenModal_EliminarAsignaciones(repeticiones);
                },
            })
        }

        return opciones;
    }

    private Get_Valida_DatoPuedeAccionarse(d: IAsignacionRepeticion) {
        return (d.IsSafe && d.EvaluacionStatus !== CEstadoEval.Evaluated)
    }

    private UI_TableGetSelectedDataMenuLvTwo(datoRepInRow: IAsignacionRepeticion) {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IAsignacionRepeticion>> = [];
        if (this.HasActionPermission(Entidad.CAccionPermiso.Eliminar) /* && this.currentAlumnos.length == 1 */) {
            opciones.push({
                Label: this.CARDCOLL_GetUIStringModule("action_eliminar"),
                Callback: (repeticion) => {
                    this.OpenModal_EliminarAsignaciones(repeticion);
                }
            })
        }
        return opciones;
    }

    private GetCiclosEscolaresList(onlyAvailable = true) {
        return DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(d => (d.IdEscuela == this.currentEscuela.IdKinder && ((onlyAvailable) ? UIUtilTime._GetValidatorDateYMD(new Date(d.FechaFin)) >= UIUtilTime._GetValidatorDateYMD(new Date()) : true)))
            .sort((a, b) => (Number(new Date(a.FechaInicio)) - Number(new Date(b.FechaInicio))));
    }

    private GetCurrentCicloEscolar(ciclosEscolaresList = this.GetCiclosEscolaresList()) {
        const now = new Date();
        let cicloEscFound = ciclosEscolaresList
            .find(d => new Date(d.FechaInicio) <= now && new Date(d.FechaFin) >= now);

        if (!cicloEscFound) {
            cicloEscFound = ciclosEscolaresList[0];
        }

        return cicloEscFound;
    }

    private async OpenModal_AddAsignacion() {
        // 1. Obtener grupos en común
        let gruposByAlumno = this.currentAlumnos.map(d => Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(d.IdChild).values()).map(d => d.IdGrupo));

        let idsGruposRep = gruposByAlumno.reduce((numerosRepetidos: number[], subarreglo: number[]) => {
            const uniqNums = Array.from(new Set(subarreglo));
            const numsRepSubArr = uniqNums.filter((numero) => gruposByAlumno.every((arr) => arr.includes(numero)));
            return numerosRepetidos.concat(numsRepSubArr);
        }, []);

        let arrGruposComunes = Array.from(new Set(idsGruposRep));
        if (!arrGruposComunes.length) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule((this.currentAlumnos.length > 1) ? "notif_singruposcomun" : "notif_singrupos"), "ADVERTENCIA");
            return;
        }

        /* ¿Debería de invocarse el MainPage.fn_ReloadServiceAndAwaitBool aquí? 
            Si se invoca aquí se hara el reload solo cada vez que se abra el formulario.
            Si la información de la materia cambia no se actualizará mientras se tenga abierto
            Genera menos carga de consultas
        */
        this.ctrlProgress.attr("oculto", false);
        await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.MateriaV2, this.currentEscuela.IdKinder);
        this.ctrlProgress.attr("oculto", true);

        const ciclosEscolaresList = this.GetCiclosEscolaresList();

        if (!ciclosEscolaresList.length) {
            this.ctrlNotification._Mostrar("Escuela sin ciclos escolares disponibles", "ADVERTENCIA");
            return;
        }

        //For Step 1
        let ctrlSelectCicloEscolar: SelectV2<ICicloEscolar, "Id">;
        let ctrlCalendarRangeSelection: CalendarioGridYearDateRange.CalendarioGridYearDateRange;
        //For Step 2
        let tableSelectAsign: Table.Tabla<IMateriaDispGrid>;
        //For Step 3
        let tablePreview: Table.Tabla<IAsignacionGrid>;

        ModalThings._GetModalToALongProccess({
            Modulo: this.modulo,
            Action: CAccionPermiso.Agregar,
            AccionToHttpMessage: "Agregar",
            IdsEscuelas: [this.currentEscuela.IdKinder],
            StartEndIds: ["asignacion_periodo", "asignaciones_preview"],
            StepsConfig: [
                {
                    Id: "asignacion_periodo",
                    NextID: "asignaciones",
                    Title: "tag_periodo",
                    Width: 800,
                    OnDrawContent: (content, modalThings) => {
                        let initCicloEscolar = this.GetCurrentCicloEscolar(ciclosEscolaresList);
                        content.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);

                        ctrlCalendarRangeSelection = new CalendarioGridYearDateRange.CalendarioGridYearDateRange(
                            content,
                            {
                                ContainerToRedimention: content,
                                FistMonthIsActualMonth: false
                            }
                        );
                        ctrlCalendarRangeSelection._minDate = new DateV2(initCicloEscolar.FechaInicio)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder);
                        ctrlCalendarRangeSelection._maxDate = new DateV2(initCicloEscolar.FechaFin)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder);
                        ctrlCalendarRangeSelection._DiasNoLabores = this.diasNoLaborales;
                        ctrlCalendarRangeSelection._UpdateViewMonthsYear(ctrlCalendarRangeSelection._minDate?.getFullYear());

                        setTimeout(() => {
                            ctrlCalendarRangeSelection._control.raise();
                        })

                        //Build select ciclo escolar
                        ctrlSelectCicloEscolar = new SelectV2<ICicloEscolar, "Id">({
                            Parent: content,
                            Data: ciclosEscolaresList,
                            ValueMember: "Id",
                            DisplayMember: "Nombre",
                            Type: "monoselect",
                            OnSelect: (dato) => {
                                const timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();

                                ctrlCalendarRangeSelection._minDate = new DateV2(dato.FechaInicio)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder);
                                ctrlCalendarRangeSelection._maxDate = new DateV2(dato.FechaFin)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder);
                                ctrlCalendarRangeSelection._DiasNoLabores = this.diasNoLaborales;
                                ctrlCalendarRangeSelection._UpdateViewMonthsYear(ctrlCalendarRangeSelection._minDate?.getFullYear());

                                if (
                                    (ctrlCalendarRangeSelection._minDate <= timeRangeSelected.StartDate && ctrlCalendarRangeSelection._minDate <= timeRangeSelected.EndDate)
                                    && (ctrlCalendarRangeSelection._maxDate >= timeRangeSelected.StartDate && ctrlCalendarRangeSelection._maxDate >= timeRangeSelected.EndDate)
                                ) {
                                    ctrlCalendarRangeSelection._SetPosicionSelectores(timeRangeSelected.StartDate, timeRangeSelected.EndDate);
                                } else {
                                    ctrlCalendarRangeSelection._SetPosicionSelectores(null, null);
                                }
                            }
                        });

                        content.append(() =>
                            ElementWrapper._WrapperToSelectControl(ctrlSelectCicloEscolar, "Ciclo Escolar")
                                .style("width", "50%")
                                .style("min-width", "380px")
                                .node()
                        );

                        ctrlSelectCicloEscolar._valueSelect(initCicloEscolar.Id);
                    },
                    OnValideStep: (content, modalThings) => {
                        const timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();
                        if (timeRangeSelected.StartDate && timeRangeSelected.EndDate) {
                            return true;
                        }
                        else {
                            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_selectdts"), "ADVERTENCIA");
                        }
                        return false;
                    }
                },
                {
                    Id: "asignaciones",
                    PreviousID: "asignacion_periodo",
                    NextID: "asignaciones_preview",
                    Title: "tag_asignacionesdisp",
                    Width: 700,
                    Height: "600px",
                    OnDrawContent: async (container, mt) => {
                        tableSelectAsign = new Table.Tabla<IMateriaDispGrid>({
                            IdTabla: "AlumnosPanelMaterias-AsignDisponibles",
                            Title: "Periodo: ",
                            IdData: "IdGrid",
                            Parent: container,
                            MinWidth: 600,
                            RenderColumnHeadings: [
                                { Field: "StrGrupo", Label: this.CARDCOLL_GetUIStringModule("d_field_strgrupo"), MinWidth: "40px", Width: "30%", IsSortable: false },
                                { Field: "NombreMateria", Label: this.CARDCOLL_GetUIStringModule("d_field_nombremateria"), MinWidth: "40px", Width: "30%", IsSortable: false },
                                { Field: "Descripcion" as any, Label: this.CARDCOLL_GetUIStringModule("d_field_criterio"), MinWidth: "40px", Width: "50%", IsSortable: false }
                            ],
                            EvaluatorAndSubLevelsBuild: {
                                OnEvalIsCollapsedRow: () => false,
                                EvaluatorSubLevel: {
                                    OnStepCellTable: () => { },
                                    OnGetData: (dato) => dato.Criterios,
                                    IdMember: "Descripcion",
                                },

                            }
                        });
                    },
                    OnFocusContent: async (content, mt) => {
                        let timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();
                        let tagPeriodo = UIUtilTime._DateFormatStandar(timeRangeSelected.StartDate) + " - " + UIUtilTime._DateFormatStandar(timeRangeSelected.EndDate);
                        tableSelectAsign._UpdateTitle(`Periodo: ${tagPeriodo}`);

                        /* ¿Debería de invocarse el MainPage._ReloadServiceAndAwaitBool aquí? 
                            Si se invoca aquí se hara el reload cada vez que se ingrese a este paso.
                            Esto permite que se tenga la información mas certera al momento de registrar
                            Pero genera mas carga de consultas
                        */

                        //mt.Progress.attr("oculto", false);
                        //await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.MateriaV2, this.currentEscuela.IdKinder);
                        //mt.Progress.attr("oculto", true);

                        // 2. Obtener todas las materias
                        let allMateriasDisponibles: IMateriaDispGrid[] = [];
                        DataModuloMateriaV2._DiccMateriasV2.forEach((materia, key) => {
                            // 3. Desestructurar materias por cada grupo en cada materia
                            materia.IdsGrupos.forEach(id => {
                                allMateriasDisponibles.push({
                                    NombreMateria: materia.Nombre,
                                    IdMateria: materia.IdMateria,
                                    FrecuenciaEval: materia.FrecuenciaEval,
                                    Criterios: materia.Criterios.map(c => ({ Descripcion: c, NombreMateria: materia.Nombre, Id: Date.now(), Orden: null })),
                                    BloqueCalificarInicio: materia.BloqueCalificarInicio,
                                    DiaCalificarInicio: materia.DiaCalificarInicio,
                                    DiasCalificar: materia.DiasCalificar,
                                    IdGrid: materia.IdMateria + "_" + id,
                                    IdGrupo: id,
                                    StrGrupo: DataModuloMain._GetDataValueFieldByName("Grupo", id, "Nombre") || UIUtilLang._GetUIString("general", "nodisponible"),
                                    IdConfiguracionMateria: materia.IdConfigMateria,
                                    UsaCriterios: materia.UsaCriterios
                                })
                            })
                        })
                        // 4. Ahora que se tiene una materia por cada grupo => filtrar las materias con respecto a los grupos en común
                        let materiasFilteredByGroupsPlaying = allMateriasDisponibles.filter(materia => arrGruposComunes.includes(materia.IdGrupo));
                        if (!materiasFilteredByGroupsPlaying.length) {
                            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_sinmateriasdisponibles"), "ADVERTENCIA");
                            setTimeout(() => {
                                mt.Previus();
                            }, 500);
                            return;
                        }

                        let materiasFilteredIds = new Set(materiasFilteredByGroupsPlaying.map(d => d.IdMateria));
                        mt.Progress.attr("oculto", false);
                        mt.Modal._DeshabilitarBtns();
                        // 5. Obtener criterios asignados
                        let resCriteriosAsig = await _SvAlumnoObtenerCriteriosAsignados(this.currentEscuela.IdKinder, ctrlSelectCicloEscolar._dataSelected[0].Id, this.currentAlumnos.map(d => d.IdChild), arrGruposComunes, Array.from(materiasFilteredIds))
                        //let resCriteriosAsig = await Data.Modulo.MateriaV2.fn_GetCriteriosAsignados(this.currentEscuela.IdKinder, ctrlSelectCicloEscolar.prop_dataSelected[0].Id, this.currentAlumnos.map(d => d.IdChild), arrGruposComunes, Array.from(materiasFilteredIds))
                        if (resCriteriosAsig.Resultado < 1) {
                            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_fail_getasignaciones"), "ADVERTENCIA");
                            setTimeout(() => {
                                mt.Previus();
                                mt.Progress.attr("oculto", true);
                                mt.Modal._HabilitarBtns();
                            }, 500)
                            return;
                        }
                        mt.Progress.attr("oculto", true);
                        mt.Modal._HabilitarBtns();

                        // 6. Descartar criterios asignados de las materias
                        let materiasFilterCopy = [...materiasFilteredByGroupsPlaying];
                        materiasFilteredByGroupsPlaying.forEach((materia, index) => {

                            // CASOS

                            // 1.
                            // Se crea una materia sin criterios
                            // En materias viene sin criterios
                            // Aun no se asigna
                            // No encuentra una asignación coincidente
                            // La materia no se descarta

                            // 2.
                            // La materia sin criterios se asigna
                            // En materias viene
                            // Viene en criterios asignados
                            // Se encuentra la asignación coincidente
                            // Se aplica el filtro (NO APLICA)
                            // La materia no tiene criterios se elimina de las materias
                            // Es descartada para mostrarse en disponible

                            // 3.
                            // Se edita la materia ahora con criterios
                            // En materias viene con sus criterios EJ. ...materia, Criterios: ['1', '2', '3']
                            // Viene en criterios asignados (criteriosMateria) de la materia => [''] 
                            // Se encuentra la asignacion coincidente
                            // ==>                                                                 [''].includes('1') | [''].includes('2') | ...  
                            // Se aplica el filtro materia.Criterios.filter(d => !criteriosMateria.Criterio.includes(d.Descripcion));
                            // No incluye los criterios
                            // Los criterios no se descartan
                            // La materia tiene criterios y no se descarta

                            // 4.
                            // Se tiene una materia con criterios
                            // En materias viene
                            // Aun no se asigna ninguno de sus criterios
                            // No se encuentra asignación coincidente
                            // No se descarta la materia ni sus criterios
                            // La materia se muestra con sus criterios
                            // Se asignan sus criterios

                            // 5.
                            // La materia se edita y ahora no usa criterios
                            // En materias viene con criterios y con su propiedad UsaCriterio es false
                            // En criterios asignados (criteriosMateria) vienen sus criterios => ['1', '2', '3']
                            // Se encuentra la asignación coincidente
                            // ==>                                                                 ['1', '2', '3'].includes('') | ['1', '2', '3'].includes('1') | ['1', '2', '3'].includes('2') | ...  
                            // ==>                                                                 Sucede así porque aunque esta como NoUsaCriterios los criterios aún existe en el arrCriterios de la materia
                            // Se aplica el filtro materia.Criterios.filter(d => !criteriosMateria.Criterio.includes(d.Descripcion));
                            // Si la materia antes de ser configurada como no usa criterios tuviera un criterio que no hubiese sido aplicado se esperaría que apareciera (NO DEBERÍA)
                            // Para solucionar el caso anterior
                            // Si la materia NoUsaCriterios y en sus criterios asignados viene [..., '', ...] un item con comillas vacias
                            // Sus criterios deberán de ser vaciados
                            // 

                            // Se busca una asignación de materia que coincida con las materias que se encuentran en juego
                            let criteriosMateria = resCriteriosAsig.Datos.find(d => (d.IdMateria + "_" + d.IdGrupo) == materia.IdGrid);
                            if (criteriosMateria) {
                                if (!materia.UsaCriterios) {
                                    materia.Criterios = [];
                                    if (criteriosMateria.Criterio.includes('')) {
                                        let materiaIndex = materiasFilterCopy.findIndex(d => d.IdGrid == materia.IdGrid);
                                        materiasFilterCopy.splice(materiaIndex, 1);
                                    }
                                    return;
                                }
                                // Se filtra de los criterios de la materia coincidente los criterios que coincidan asignados 
                                materia.Criterios = materia.Criterios.filter(d => !criteriosMateria.Criterio.includes(d.Descripcion));

                                // Y si la materia no tiene criterios ya, se elimina toda la asignación
                                if (!materia.Criterios.length) {
                                    let materiaIndex = materiasFilterCopy.findIndex(d => d.IdGrid == materia.IdGrid);
                                    materiasFilterCopy.splice(materiaIndex, 1);
                                }
                            }
                        })

                        materiasFilteredByGroupsPlaying = materiasFilterCopy;
                        if (!materiasFilteredByGroupsPlaying.length) {
                            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_sinasignacionesdisponibles"), "ADVERTENCIA", 3500);
                            setTimeout(() => {
                                mt.Modal._Ocultar();
                            }, 500);
                            return;
                        }
                        tableSelectAsign._UpdateData(materiasFilteredByGroupsPlaying);
                    },
                    OnValideStep: (container, modalThings) => {
                        if (!tableSelectAsign._DataAndChildsDataChecked.length) {
                            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_nonselectasign"), "ADVERTENCIA");
                            return false;
                        }
                        return true;
                    }
                }, {
                    Id: "asignaciones_preview",
                    PreviousID: "asignaciones",
                    Title: "tag_prevw_asignaciones",
                    Width: 1300,
                    Height: "800px",
                    OnDrawContent: (container, modalThings) => {
                        tablePreview = new Table.Tabla<IAsignacionGrid>({
                            IdTabla: "AlumnosPanelMaterias-AsignPreview",
                            Title: "Periodo: ",
                            IdData: "IdAsignacion",
                            Parent: container,
                            MinWidth: 1350,
                            HideCheckboxes: true,
                            EnableRelevanceSelections: false,
                            RenderColumnHeadings: [
                                { Field: "StrGrupo", Label: this.CARDCOLL_GetUIStringModule("d_field_strgrupo"), MinWidth: "150px", Width: "12%", IsSortable: false },
                                { Field: "NombreMateria", Label: this.CARDCOLL_GetUIStringModule("d_field_nombremateria"), MinWidth: "150px", Width: "12%", IsSortable: false },
                                { Field: "Criterio", Label: this.CARDCOLL_GetUIStringModule("d_field_criterio"), MinWidth: "150px", Width: "12%", IsSortable: false },
                                { Field: "FrecuenciaEval", Label: this.CARDCOLL_GetUIStringModule("d_field_frecuenciaeval"), MinWidth: "120px", Width: "8%", IsSortable: false },
                                { Field: "NoRepeticiones", Label: this.CARDCOLL_GetUIStringModule("d_field_norepeticiones"), MinWidth: "100px", Width: "8%", IsSortable: false },
                                { Field: "Inicio", Label: this.CARDCOLL_GetUIStringModule("d_field_inicio"), MinWidth: "120px", Width: "8%", IsSortable: false },
                                { Field: "Fin", Label: this.CARDCOLL_GetUIStringModule("d_field_fin"), MinWidth: "120px", Width: "8%", IsSortable: false },
                                { Field: "CalificarInicio" as any, Label: this.CARDCOLL_GetUIStringModule("d_field_calificarinicio"), MinWidth: "120px", Width: "8%", IsSortable: false },
                                { Field: "CalificarFin" as any, Label: this.CARDCOLL_GetUIStringModule("d_field_calificarfin"), MinWidth: "120px", Width: "8%", IsSortable: false }
                            ],
                            EvaluatorAndSubLevelsBuild: {
                                OnEvalIsCollapsedRow: () => false,
                                OnStepCellTable: (container, datum, field) => {
                                    switch (field) {
                                        case "Inicio":
                                            container.text(datum.Inicio ? UIUtilTime._DateFormatStandar(datum.Inicio) : "---");
                                            break;
                                        case "Fin":
                                            container.text(datum.Fin ? UIUtilTime._DateFormatStandar(datum.Fin) : "---");
                                            break;
                                        case "FrecuenciaEval":
                                            container.text(CFreqEval[datum.FrecuenciaEval]);
                                            break;
                                    }
                                },
                                EvaluatorSubLevel: {
                                    OnGetData: (dato) => dato.Repeticiones,
                                    OnStepCellTable: (container, datumRep: IAsignacionRepeticion, field) => {
                                        switch (field) {
                                            case "Inicio":
                                                container.text(datumRep.Inicio ? UIUtilTime._DateFormatStandar(datumRep.Inicio) : "---");
                                                break;
                                            case "Fin":
                                                container.text(datumRep.Fin ? UIUtilTime._DateFormatStandar(datumRep.Fin) : "---");
                                                break;
                                            case "CalificarInicio":
                                                container.text(datumRep.CalificarInicio ? UIUtilTime._DateFormatStandar(datumRep.CalificarInicio) : "---");
                                                break;
                                            case "CalificarFin":
                                                container.text(datumRep.CalificarFin ? UIUtilTime._DateFormatStandar(datumRep.CalificarFin) : "---")
                                                break;
                                            case "FrecuenciaEval":
                                                container.text("");
                                                break;
                                        }
                                    },
                                    IdMember: "Id",
                                },
                            }
                        })
                    },
                    OnFocusContent: (container, modalThings) => {
                        let timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();
                        let tagPeriodo = UIUtilTime._DateFormatStandar(timeRangeSelected.StartDate) + " - " + UIUtilTime._DateFormatStandar(timeRangeSelected.EndDate);
                        tablePreview._UpdateTitle(`Periodo: ${tagPeriodo}`);

                        let asignaciones: Array<IAsignacionGrid> = [];
                        //let asignacionesAux: Array<IAsignacionGrid> = [];
                        tableSelectAsign._DataAndChildsDataChecked.forEach(d => {
                            if (d.ChildsChecked && d.ChildsChecked.length) {
                                d.ChildsChecked.forEach(dCh => {
                                    this.idTemporalAux++;
                                    asignaciones.push(
                                        {
                                            IdMateria: d.Data.IdMateria,
                                            IdGrupo: d.Data.IdGrupo,
                                            IdCriterio: dCh.Data.Id,
                                            BloqueCalInicio: d.Data.BloqueCalificarInicio,
                                            DiaCalInicio: d.Data.DiaCalificarInicio,
                                            DiasCalificar: d.Data.DiasCalificar,
                                            FrecuenciaEval: d.Data.FrecuenciaEval,
                                            Repeticiones: [],
                                            NoRepeticiones: 0,
                                            Inicio: "",
                                            Fin: "",
                                            Criterio: dCh.Data.Descripcion,
                                            IdAsignacion: this.idTemporalAux,
                                            NombreMateria: d.Data.NombreMateria,
                                            StrGrupo: d.Data.StrGrupo,
                                            EnUso: true,
                                            IdAlumno: null,
                                            IdCicloEscolar: ctrlSelectCicloEscolar._dataSelected[0].Id,
                                            IdConfigMateria: d.Data.IdConfiguracionMateria, //Se tiene que modificar por el de la materia
                                            Modificacion: null,
                                            TotalCalificados: 0,
                                            TotalCalificaciones: 0,
                                            ValorTipo: 0,
                                            IsSafe: true
                                        }
                                    );
                                })
                            } else {
                                this.idTemporalAux++;
                                asignaciones.push(
                                    {
                                        IdMateria: d.Data.IdMateria,
                                        IdGrupo: d.Data.IdGrupo,
                                        IdCriterio: null,
                                        BloqueCalInicio: d.Data.BloqueCalificarInicio,
                                        DiaCalInicio: d.Data.DiaCalificarInicio,
                                        DiasCalificar: d.Data.DiasCalificar,
                                        FrecuenciaEval: d.Data.FrecuenciaEval,
                                        Repeticiones: [],
                                        NoRepeticiones: 0,
                                        Inicio: "",
                                        Fin: "",
                                        Criterio: null,
                                        IdAsignacion: this.idTemporalAux,
                                        NombreMateria: d.Data.NombreMateria,
                                        StrGrupo: d.Data.StrGrupo,
                                        EnUso: true,
                                        IdAlumno: null,
                                        IdCicloEscolar: ctrlSelectCicloEscolar._dataSelected[0].Id,
                                        IdConfigMateria: d.Data.IdConfiguracionMateria, //Se tiene que modificar por el de la materia
                                        Modificacion: null,
                                        TotalCalificados: 0,
                                        TotalCalificaciones: 0,
                                        ValorTipo: 0,
                                        IsSafe: true
                                    }
                                );
                            }
                        })

                        //Crea el arreglo vacio de las asignaciones que se van a procesar para ser llenadas con sus repeticiones
                        let asignacionesACargar: IAsignacionGrid[] = [];

                        //Por cada una de las asignaciones seleccionadas
                        asignaciones.forEach(asignacionTbl => {
                            if (asignacionTbl.FrecuenciaEval == CFreqEval.Libre) {
                                asignacionTbl.NoRepeticiones = 0;
                                asignacionTbl.Repeticiones = [];
                                asignacionTbl.TotalCalificaciones = 0;

                                let fechaInicio = new DateV2(ctrlCalendarRangeSelection._GetResultSelected().StartDate)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true);
                                let fechaInicioFilter = this.checkDateValidity(fechaInicio, this.diasNoLaborales, []);
                                asignacionTbl.Inicio = fechaInicioFilter._ToISOString();

                                let fechaFin = new DateV2(ctrlCalendarRangeSelection._GetResultSelected().EndDate)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true);
                                let fechaFinFilter = this.checkDateValidity(fechaFin, this.diasNoLaborales, []);
                                fechaFinFilter.setDate(fechaFin.getDate() + 1);
                                fechaFinFilter.setMilliseconds(fechaFinFilter.getMilliseconds() - 1);
                                asignacionTbl.Fin = fechaFinFilter._ToISOString();

                                asignacionesACargar.push(asignacionTbl);
                                return;
                            }

                            // Corresponde a la fecha de inicio que se seleccionó en el paso anterior
                            // => Se trata de esta manera porque fecha proceso ira cambiando su valor
                            let fechaProceso = new DateV2(ctrlCalendarRangeSelection._GetResultSelected().StartDate)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true);

                            // A la variable asignacion se inicializa su propiedad de NoRepeticiones en 0
                            asignacionTbl.NoRepeticiones = 0;
                            // A la variable asignacion se inicializa su propiedad de Repeticiones con un arreglo vacío
                            asignacionTbl.Repeticiones = [];
                            // A la variable asignacion se inicializa su propiedad de TotalCal en 0
                            asignacionTbl.TotalCalificaciones = 0;
                            // Estas variables entran en juego a continuación y su valor se irá llenando/Cambiando

                            // Ciclo que iterará siempre que la fecha de inicio sea mayor a la fecha fin
                            // => La fecha fin se obtiene del rango seleccionado en el paso anterior (ctrlCalendarRangeSelection)
                            while (fechaProceso <= ctrlCalendarRangeSelection._GetResultSelected().EndDate) {
                                // Esta función recorre la fecha de acuerdo a la frecuencia de evaluación que tiene la asignación
                                const getFechaRecorrida = (fecha: Date, adelante: boolean = true) => {
                                    let fechaRecorrida = new DateV2(fecha)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true);
                                    switch (asignacionTbl.FrecuenciaEval) {
                                        case CFreqEval.Diario:
                                            if (adelante) fechaRecorrida.setDate(fechaRecorrida.getDate() + 1);
                                            else fechaRecorrida.setDate(fechaRecorrida.getDate() - 1)
                                            break;
                                        case CFreqEval.Semanal:
                                            if (adelante) {
                                                let dayInWeek = UIUtilTime._GetDayInWeek(fechaRecorrida);
                                                fechaRecorrida.setDate(fechaRecorrida.getDate() + (8 - dayInWeek))
                                            }
                                            //if (adelante) fechaRecorrida.setDate(fechaRecorrida.getDate() + 7);
                                            //else fechaRecorrida.setDate(fechaRecorrida.getDate() - 7);
                                            break;
                                        case CFreqEval.Mensual:
                                        case CFreqEval.Bimestral:
                                        case CFreqEval.Trimestral:
                                        case CFreqEval.Cuatrimestral:
                                        case CFreqEval.Semestral:
                                        case CFreqEval.Anual:
                                            if (adelante) {
                                                fechaRecorrida.setDate(1);
                                                fechaRecorrida.setMonth(fechaRecorrida.getMonth() + Entidad.MateriaPeriodicidadNMeses[asignacionTbl.FrecuenciaEval]);
                                            }
                                            //if (adelante) fechaRecorrida.setMonth(fechaRecorrida.getMonth() + Entidad.MateriaPeriodicidadNMeses[asignacionTbl.FrecuenciaEval]);
                                            //else fechaRecorrida.setMonth(fechaRecorrida.getMonth() - Entidad.MateriaPeriodicidadNMeses[asignacionTbl.FrecuenciaEval]);
                                            break;
                                    }
                                    return fechaRecorrida;
                                }

                                if (asignacionTbl.FrecuenciaEval == CFreqEval.Diario) {
                                    let dayInWeek = UIUtilTime._GetDayInWeek(fechaProceso);
                                    if (this.diasNoLaborales.includes(dayInWeek)) {
                                        fechaProceso = getFechaRecorrida(fechaProceso);
                                        continue;
                                    }
                                }

                                // Variable global que se inicializa en el constructor con la función Date.now() debe de incrementarse antes de usarse
                                this.idTemporalAux++;

                                // Por cada iteración en el {WHILE} se crea una repetición
                                let repeticion: IAsignacionRepeticion = {
                                    ParentAsignacion: asignacionTbl,
                                    DtFechaInicio: null,
                                    DtFechaCalificarFin: null,
                                    Inicio: "",
                                    CalificarFin: "",
                                    CalificarInicio: "",
                                    Fin: "",
                                    ValorEval: "",
                                    EvaluacionStatus: CEstadoEval.Registered,
                                    Id: this.idTemporalAux,
                                    IsSafe: null,
                                    Criterio: asignacionTbl.Criterio,
                                    FechaCalificacion: "",
                                    EnUso: true,
                                    FrecuenciaEval: asignacionTbl.FrecuenciaEval,
                                    IdCalifico: null,
                                    IdMateria: asignacionTbl.IdMateria,
                                    Modificacion: null,
                                    Observacion: ""
                                };

                                // La fecha de inicio de la repetición actual se obtiene en base a una evaluación a la fechaProceso, la evaluación consiste en obtener el día habil posterior más cercano
                                repeticion.DtFechaInicio = this.checkDateValidity(fechaProceso, this.diasNoLaborales, []);
                                repeticion.Inicio = repeticion.DtFechaInicio._ToISOString();

                                // Se evalua lo siguiente
                                // El valor de fechaProceso es menor o igual a la fechaFin seleccionada en el rango
                                // La asignación no tiene repeticiones aún
                                if (fechaProceso <= ctrlCalendarRangeSelection._GetResultSelected().EndDate || asignacionTbl.NoRepeticiones === 0) { // Repetición válida
                                    // Se incrementa el numero de repeticiones de la asignación
                                    asignacionTbl.NoRepeticiones++;
                                    asignacionTbl.TotalCalificaciones++;

                                    // Se declara una variable para la fecha de aplicación // Basada en la fecha de inicio asignandole la zona horaria indicando que preserve la Date
                                    let dtAplicacion = new DateV2(repeticion.DtFechaInicio)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true);
                                    // Se asigna el mes(BLOQUE) de aplicación si es que su evaluación tien que ver con meses
                                    if (asignacionTbl.FrecuenciaEval !== CFreqEval.Diario && asignacionTbl.FrecuenciaEval !== CFreqEval.Libre && asignacionTbl.FrecuenciaEval !== CFreqEval.Semanal) {
                                        dtAplicacion.setMonth(dtAplicacion.getMonth() + asignacionTbl.BloqueCalInicio - 1);
                                    }
                                    // Se asigna el dia de para iniciar con la evaluación
                                    dtAplicacion.setDate(dtAplicacion.getDate() + asignacionTbl.DiaCalInicio - 1);
                                    // De la fecha obtenida se valida a la fecha habil posterior más cercana
                                    dtAplicacion = this.checkDateValidity(dtAplicacion, this.diasNoLaborales, [])
                                    // Este método transforma la fecha en cadena manteniendo sus propiedades de la zona hr
                                    repeticion.CalificarInicio = dtAplicacion._ToISOString();

                                    // Se obtiene la fecha de fin de la evaluación basada en la fecha de aplicación asignandole una zona horaria indicando que preserve la Date 
                                    repeticion.DtFechaCalificarFin = new DateV2(dtAplicacion)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true);
                                    // Se asigna la fecha fin sumando a la fecha de aplicación los días que se tienen para calificar
                                    repeticion.DtFechaCalificarFin.setDate(repeticion.DtFechaCalificarFin.getDate() + asignacionTbl.DiasCalificar - 1);
                                    // En las 2 lineas siguientes se juega con la fecha para hacer que la fecha final sea hasta el último segundo del día
                                    // 1. se recorre un día la fecha
                                    // 2. se quita un millisegundo a la fecha de modo: [2023-07-04 00:00:00] => [2023-07-03 23:59:59] 
                                    repeticion.DtFechaCalificarFin.setDate(repeticion.DtFechaCalificarFin.getDate() + 1);
                                    repeticion.DtFechaCalificarFin.setUTCMilliseconds(repeticion.DtFechaCalificarFin.getMilliseconds() - 1);

                                    // Finalmente se obtiene la fecha habil posterior más cercana
                                    repeticion.DtFechaCalificarFin = this.checkDateValidity(repeticion.DtFechaCalificarFin, this.diasNoLaborales, []);
                                    // Este método transforma la fecha en cadena manteniendo sus propiedades de la zona hr
                                    repeticion.CalificarFin = repeticion.DtFechaCalificarFin._ToISOString();

                                    let fechaFinBloque = new DateV2(fechaProceso);
                                    fechaFinBloque = getFechaRecorrida(fechaProceso);
                                    fechaFinBloque.setDate(fechaFinBloque.getDate() - 1);
                                    repeticion.Fin = fechaFinBloque._ToISOString();

                                    // Se agrega esa repetición al arreglo de repeticiones de la asignación actual
                                    asignacionTbl.Repeticiones.push(repeticion);
                                }
                                // Recorrido a siguiente bloque (Diario, Semanal, Mensual...,)
                                fechaProceso = getFechaRecorrida(fechaProceso);
                            }
                            asignacionTbl.Inicio = asignacionTbl.Repeticiones[0] ? asignacionTbl.Repeticiones[0].DtFechaInicio._ToISOString() : null;
                            asignacionTbl.Fin = asignacionTbl.Repeticiones[asignacionTbl.Repeticiones.length - 1] ? asignacionTbl.Repeticiones[asignacionTbl.Repeticiones.length - 1].Fin : null;
                            asignacionesACargar.push(asignacionTbl);
                        });

                        tablePreview._UpdateData(asignacionesACargar);

                    },
                    OnAccept: async (modalThings) => {
                        const nAlumnos = this.currentAlumnos.length;
                        let dataTableAsignaciones = tablePreview._data;
                        let asignacionesToSend: AsignacionMateriaToSend[] = [];
                        asignacionesToSend = dataTableAsignaciones.map<AsignacionMateriaToSend>(d => ({ IdMateria: d.IdMateria, IdConfiguracionMateria: d.IdConfigMateria, IdGrupo: d.IdGrupo, Criterio: d.Criterio, FrecuenciaEval: d.FrecuenciaEval, BloqueCalInicio: d.BloqueCalInicio, DiaCalInicio: d.DiaCalInicio, DiasCalDuracion: d.DiasCalificar, Repeticiones: d.Repeticiones.map(dA => ({ Inicio: dA.Inicio, Fin: dA.Fin, CalificarInicio: dA.CalificarInicio, CalificarFin: dA.CalificarFin })) }))
                        const idsChilds = this.GetIdsCurrentStudents();
                        asignacionesToSend.map(d => {
                            if (d.FrecuenciaEval == CFreqEval.Libre) {
                                d.Repeticiones.push({
                                    Inicio: new DateV2(ctrlCalendarRangeSelection._GetResultSelected().StartDate)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true)._ToISOString(),
                                    Fin: new DateV2(ctrlCalendarRangeSelection._GetResultSelected().EndDate)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true)._ToISOString(),
                                    CalificarFin: new DateV2(ctrlCalendarRangeSelection._GetResultSelected().StartDate)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true)._ToISOString(),
                                    CalificarInicio: new DateV2(ctrlCalendarRangeSelection._GetResultSelected().EndDate)._SetTimeZoneByIdSchool(this.currentEscuela.IdKinder, true)._ToISOString()
                                })
                            }
                        });
                        // Se invoca al servicio para insertar asignaciones de MateriasCriterios
                        // Recibe los Ids de los alumnos que están en juego, las asignaciones a insertar, el Id del ciclo escolar 

                        // El servicio en lugar de la propiedad Datos devuelve de forma separada las siguientes propiedades en forma de arreglo, su tamaño es equivalente al número de repeticiones totales de todos los criterios
                        // IdsAlumnos: El valor de cada item de este arreglo corresponderá al IDAlumno asociado con una repetición de asignación. Ej: [254, 254, 254, 254, 84, 84, 84, 84]
                        // IdsAsignacionesP: Correspondes a los Ids de las asignacionesPadre (El criterio)
                        // IdsCalificaciones: Corresponden a los IDs de las repeticiones asignadas

                        // Igualmente devuelve en una de sus propiedades llamada ResolvedData la cual es un arreglo cuyas posiciones principales corresponden a los ID's de los alumnos en juego
                        // Cada uno de los elementos de los arreglos agrupa las repeticiones asociadas a cada alumno. Ej:
                        /* 
                            84: (4) [{…}, {…}, {…}, {…}]
                            254: (4) [{…}, {…}, {…}, {…}]
                        */
                        // Cada repetición agrupada por alumno contiene las siguientes propiedades
                        // IdAlumno: Alumno que está asociado a la repetición
                        // IdAsignacionP: Id de la asignaciónPadre (MateriaCriterio) asiciada con la repetición
                        // IdCalificacion: Id de la repetición asignada (calificación);
                        let res = await _SvAlumnoAsignarMaterias(idsChilds, asignacionesToSend, ctrlSelectCicloEscolar._dataSelected[0].Id);
                        //let res = await Data.Modulo.MateriaV2.fn_AsignarMateria(idsChilds, asignacionesToSend, ctrlSelectCicloEscolar.prop_dataSelected[0].Id);
                        if (res.Resultado > 0) {
                            if (nAlumnos > 1) {
                                const repeticionesTotales: IAsignacionRepeticion[] = [];
                                // Cuando sean libres se debe de crear una repeticiónTemporal => Debe de eliminarse al final
                                dataTableAsignaciones
                                    .forEach((asignacion) => {
                                        if (asignacion.FrecuenciaEval == CFreqEval.Libre) {
                                            repeticionesTotales.push(<IAsignacionRepeticion>{ ParentAsignacion: asignacion, IsSafe: null, IDs: [] })
                                        } else {
                                            repeticionesTotales.push(...asignacion.Repeticiones)
                                        }
                                    })
                                let safeData = ((nAlumnos * repeticionesTotales.length) == res.IdsCalificaciones.length);

                                if (safeData) {
                                    // EJ. [84, 254]
                                    for (const idAlumno of idsChilds) {
                                        // Se busca la data resuelta correspondiente al alumno
                                        const asignacionesAlumnoRes = res.ResolvedData[idAlumno];
                                        if (asignacionesAlumnoRes.length == repeticionesTotales.length) {
                                            repeticionesTotales
                                                .forEach((repeticionA, indexRep) => {
                                                    const repeticionRes = asignacionesAlumnoRes[indexRep];
                                                    if ((repeticionA.IsSafe == null || repeticionA.IsSafe)) {
                                                        if (repeticionA.IsSafe == null) {
                                                            repeticionA.IsSafe = true;
                                                        }

                                                        if (!repeticionA.IDs) {
                                                            repeticionA.IDs = []
                                                        }

                                                        if (!repeticionA.ParentAsignacion.IDs) {
                                                            repeticionA.ParentAsignacion.IDs = [];
                                                        }

                                                        repeticionA.IDs.push(repeticionRes.IdCalificacion)
                                                        if (!repeticionA.ParentAsignacion?.IDs.includes(repeticionRes.IdAsignacionP)) {
                                                            repeticionA.ParentAsignacion.IDs.push(repeticionRes.IdAsignacionP);
                                                        }
                                                    } else {
                                                        repeticionA.IsSafe = false;
                                                        console.warn("Los indices no corresponden!!");
                                                    }
                                                });
                                        }

                                        else {
                                            console.warn("Alumno sin asignaciones correctos!!!", asignacionesAlumnoRes, repeticionesTotales);
                                            safeData = false;
                                            break;
                                        }
                                    }
                                }

                                if (!safeData) {
                                    console.warn("Datos corruptos!!!");
                                    repeticionesTotales
                                        .forEach(repeticion => {
                                            repeticion.IsSafe = false;
                                        });

                                    setTimeout(() => {
                                        this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_errorasignacion"), "ADVERTENCIA", 5000);
                                    }, 4000);
                                }
                                let currentAsignaciones = this.GetMateriasAsignacionesList(idsChilds);
                                currentAsignaciones.push(...dataTableAsignaciones);
                                this.SetMateriasAsignacionesList(idsChilds, currentAsignaciones);
                            }
                            await this.UI_UpdateCardData();
                        }
                        return res;
                    }
                }
            ]
        })
    }

    private GetCurrentStudents(): IAlumno[] {
        return (this.cardData[0] || []);
    }

    private GetIdsCurrentStudents() {
        return this.GetCurrentStudents()
            .map(d => d.IdChild)
    }

    private GetMateriasAsignacionesList(keyAsignaciones?: (string | number | number[])) {
        if (!keyAsignaciones) {
            keyAsignaciones = this.GetIdsCurrentStudents();
        }

        return (this.asignacionesCompartidas.get(keyAsignaciones.toString()) || []);
    }

    private SetMateriasAsignacionesList(key: (number | number[]), cargosAsignaciones: IAsignacionGrid[]) {
        this.asignacionesCompartidas.set(key.toString(), cargosAsignaciones);
    }

    private GetStudentsCount(): number {
        return this.GetCurrentStudents().length;
    }

    private OpenModal_EliminarAsignaciones(asignaciones: Array<IAsignacionRepeticion & { isMaster?: boolean }>) {
        this.CARDCOLLAD_OpenModal_ProccessArrayData<IAsignacionRepeticion & { isMaster?: boolean }>({
            DataToProccess: asignaciones,
            AccionToHttpMessage: "eliminarasignacion",
            OnGetIdEscuela: () => this.currentEscuela.IdKinder,
            OnStepAProccess: null,
            OnStepAllProccess: async (asignaciones) => {
                let res = await this.Sv_EliminarAsignaciones_Repeticiones(asignaciones);
                if (res.Resultado > 0 || res.Resultado == -1 || res.Resultado == -1.333) {
                    this.ctrlTabla._CheckAllItems(false);
                    await this.UI_UpdateCardData();
                }
                return res;
            },
            OnEndAndCloseProccess: async (correctos) => { },
            OnError_GetItemDataTag: (dato) => {
                let tag = ``;
                if (dato.Criterio) {
                    tag = `<b>${dato.Criterio}</b>`;
                    tag += `<br>`
                    tag += `<i style='font-size: var(--fontsize_me4)'>${dato.ParentAsignacion.NombreMateria} - ${dato.ParentAsignacion.StrGrupo}</i>`
                } else {
                    tag = `<b style='font-size: var(--fontsize_me2)'>${dato.ParentAsignacion.NombreMateria} - ${dato.ParentAsignacion.StrGrupo}</b>`;
                }
                return tag;
            },
            TypeRequest: null,
        })
    }

    private async Sv_EliminarAsignaciones_Repeticiones(repeticiones: Array<IAsignacionRepeticion & { isMaster?: boolean }>) {
        let completeRep: Array<IAsignacionRepeticion & { isMaster?: boolean }> = [];
        interface IEliminaAsignaciones {
            [idPadre: string]: number[];
        }
        repeticiones.forEach((d) => {
            d.IDs.forEach((id, index) => {
                completeRep.push({ ...d, Id: id, ParentAsignacion: { ...d.ParentAsignacion, IdAsignacion: d.ParentAsignacion.IDs[index] } })
            })
        })

        let repeticionesGroupedByParent = d3Group(completeRep, d => d.ParentAsignacion.IdAsignacion);

        let objAsignacionesDelete: IEliminaAsignaciones = {};

        repeticionesGroupedByParent.forEach((asignaciones, agrupador) => {
            objAsignacionesDelete[agrupador] = [];

            if (asignaciones[0].isMaster) {
                objAsignacionesDelete[agrupador] = null;
                return;
            }

            objAsignacionesDelete[agrupador].push(...asignaciones.map(d => d.Id));
        })

        let res = await _SvAlumnoEliminarCalificaciones(objAsignacionesDelete);
        //let res = await Data.Modulo.MateriaV2.fn_EliminarCalificaciones(objAsignacionesDelete);
        const idsAlumnos = this.GetIdsCurrentStudents();
        if (res.Resultado > 0) {
            if (this.GetStudentsCount() > 1) {
                let currentAsignaciones = this.GetMateriasAsignacionesList(idsAlumnos);
                const findAsignIndex = (repeticion: IAsignacionRepeticion & { isMaster?: boolean }) => {
                    return currentAsignaciones.findIndex(d => d.IDs.toString() == repeticion.ParentAsignacion.IDs.toString());
                }
                const findRepetIndex = (asignacionPlaying: IAsignacionGrid, repeticion: IAsignacionRepeticion & { isMaster?: boolean }) => {
                    return asignacionPlaying.Repeticiones.findIndex(d => d.IDs.toString() == repeticion.IDs.toString());
                }
                repeticiones.forEach(repeticion => {
                    let indexAsignacionPlaying = findAsignIndex(repeticion);
                    let asignacionPlaying = currentAsignaciones[indexAsignacionPlaying];
                    asignacionPlaying.IDs.forEach(idPadre => {
                        if (asignacionPlaying.FrecuenciaEval == CFreqEval.Libre) {
                            let arrRes = res?.Datos[idPadre] || [];
                            if (!arrRes.length) {
                                asignacionPlaying.IsSafe = false;
                            } else {
                                if (arrRes.includes(0)) {
                                    let indexAsignacionLibre = findAsignIndex(repeticion);
                                    if (indexAsignacionLibre > -1) {
                                        currentAsignaciones.splice(indexAsignacionLibre, 1);
                                    }
                                }
                            }
                        } else {
                            let arrRes = res?.Datos[idPadre] || [];
                            let indexRepeticionPlaying = findRepetIndex(asignacionPlaying, repeticion);
                            if (indexRepeticionPlaying > -1) {
                                if (!arrRes.length) {
                                    asignacionPlaying.Repeticiones[indexRepeticionPlaying].IsSafe = false;
                                } else {
                                    asignacionPlaying.Repeticiones[indexRepeticionPlaying].IsSafe = true;
                                    const setArrIdsrRep = new Set(repeticion.IDs);
                                    if (arrRes.some(id => setArrIdsrRep.has(id))) {
                                        asignacionPlaying.Repeticiones.splice(indexRepeticionPlaying, 1);
                                        if (asignacionPlaying.Repeticiones.length == 0) {
                                            currentAsignaciones.splice(indexAsignacionPlaying, 1);
                                        }
                                    }
                                }
                            }
                        }
                    })

                });

                this.SetMateriasAsignacionesList(idsAlumnos, currentAsignaciones);
            } else {
                let ningunaEliminacion = Object.values(res.Datos).every(arr => arr.length == 0);
                if (ningunaEliminacion) {
                    console.warn("Ninguna Asignacion");
                    res.Resultado = -1.333;
                }
            }
        }
        return res;
    }
}
