import * as d3 from "d3";
import { UIUtilElementBehaviors } from "../util/ElementBehaviors";
import { SelectV2 } from "./SelectV2";

export namespace ElementWrapper {

    function WrapperToElement(element: HTMLElement, text: string, pasteOnParent: boolean, extraConfig?: IExtraConfig) {
        let wrapper = d3.create("div")
            .attr("class", "element_wrapper combo_wrapper");
        let lblArea: HTMLDivElement & { _label?: HTMLLabelElement }

        if (text) {
            wrapper.append("div")
                .attr("class", "area_label")
                .call(cont => {
                    lblArea = cont.node()
                    if (extraConfig?.LabelMaxWidth) {
                        cont.style("max-width", extraConfig?.LabelMaxWidth)
                    }
                    if (extraConfig?.HideSeparator) {
                        cont.style("border-right", "none")
                    }
                })
                .append("label")
                .text(text)
                .call(lbl => {
                    lblArea._label = lbl.node()
                    return UIUtilElementBehaviors._EllipsisTextTooltip(lbl.node(), "button")
                });
        }


        if (pasteOnParent && element.parentElement) {
            // element.parentElement.append(wrapper.node());
            element.parentElement.insertBefore(wrapper.node(), element);
        }

        wrapper.append("div")
            .attr("class", "area_element")
            .append(() => element);

        if (extraConfig?.Borders) {
            wrapper.style("border", "none");
            extraConfig.Borders.forEach(direction => {
                wrapper.style("border-" + direction, "#c8c8c8 solid 1px");
            })
        }

        if (element instanceof HTMLInputElement && element.type === "checkbox") {
            element.style.marginRight = "var(--padding2)"
            element.parentElement.style.borderRadius = "0"
            if (element.id && lblArea) {
                lblArea._label.setAttribute("for", element.id)
            }
        }

        return wrapper;
    }

    type TBorder = "top" | "bottom" | "left" | "right"
    interface IExtraConfig {
        /** @default ["top", "bottom", "left", "right"] */
        Borders?: [TBorder] | [TBorder, TBorder] | [TBorder, TBorder, TBorder] | [TBorder, TBorder, TBorder, TBorder]
        /** @default "30%" */
        LabelMaxWidth?: string
        HideSeparator?: boolean
    }

    export function _WrapperToSelectControl(control: (SelectV2<any, any, "monoselect" | "multiselect">), text?: string, extraConfig?: IExtraConfig): TSelectionHTML<"div"> {
        let wrapper = WrapperToElement(control._controlSelection.node(), text, false, extraConfig);

        return wrapper;
    }

    export function _WrapperToElement(element: (HTMLElement | d3.Selection<HTMLElement, any, any, any>), text?: string, extraConfig?: IExtraConfig) {
        let elem = element instanceof HTMLElement ? element : element.node();
        let wrapper = WrapperToElement(elem, text, true, extraConfig);

        return wrapper;
    }
}
