import * as d3 from "d3";
import licenciaPayInfo from '../../cfg/licencia-payinfo.json';
import { CLicenciaConfigOrganizacionTipoRegistro, ILicenciaConfigOrganizacionDetalleAdeudo } from "../../data/entidad/Licencia";
import { Global } from "../../data/Global";
import { _DiccFullEscuelas } from "../../data/modulo/Escuela";
import { DataUtilLocalStorage } from "../../data/util/LocalStorage";
import { IPageItem, UIWindowPagesNavUtils } from "../../routes/MainPageMenu";
import { Router } from "../../routes/Router";
import { TRoutePath, _ROUTES } from "../../routes/Routes";
import _L from "../../util/Labels";
import { UtilObject } from "../../util/Object";
import { UIUtilFormat } from "../util/Format";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { THashParams, UIWindowManager } from "../ventana/WindowManager";
import { LocationNav } from "./LocationsNav";
import { ModalThings } from "./ModalThings";
import { TemplateMenuUser } from "./TemplateMenuUser";
import { ViewInfoControl } from "./TemplateViewInfo";
import { _ShowTemporalTooltip } from "./TemporalTooltip";

type TDOMElements = TSelectionHTML<"div"> & {
    __menuContainer?: TSelectionHTML<"div"> & {
        __header?: TSelectionHTML<"div">
        __menuContent?: TSelectionHTML<"div">
        __footer?: TSelectionHTML<"div">
    }
    __winContainer?: TSelectionHTML<"div"> & {
        __topbar?: TSelectionHTML<"div">
        __principalContent?: TSelectionHTML<"div">
        __footer?: TSelectionHTML<"div">
    }
}
export namespace Template {
    type IMenuItemCfg = Partial<IPageItem> & {};

    type IMenuItem = IMenuItemCfg & {
        // Label: string;
        Parent: IMenuItem;
        Childs: IMenuItem[];
        Selected: boolean;
    }

    const MENU_HIDEDELAY = 400;
    const MENU_SHOWDELAY = 200;

    export class TemplateControl {
        /**
         *  -  .template
         *  -  | > .menu_container
         *  -       > .header
         *  -       > .menu_content
         *             > .item
         *  -       > .footer
         *  -  | > .win_container
         *  -       > .topbar
         *  -       > .principal_content
         *  -       > .footer
         */
        private controlContainer: TDOMElements;
        private menuItems: IMenuItem[];

        private menuItemsResizeObserver: ResizeObserver;
        private relocateCallbacks: Function[];

        private ctrlLocationNav: LocationNav;
        private ctrlViewInfo: ViewInfoControl;

        constructor() {
            this.relocateCallbacks = [];
            this.menuItems = [];
            this.UI_Build();

            this["__HashPathChangeListener"] = UIWindowManager._AddOnHashPathChangeListener(e => {
                this.FindFocusHash(e.detail.Path);
                this.UI_UpdateMenu();
            });
        }

        // *****************************************************************************
        // PRIVATE METHODS
        // *****************************************************************************

        private UI_Build() {
            const SwitchMenuVisibility = (status: boolean) => {
                menuContainer.classed("menu_hover", status);
                if (!status && !this.controlContainer.classed("menu_expanded")) {
                    menuContainer.selectAll(".subitems")
                        .style("height", "0px")
                        .classed("expanded", false)
                    menuContainer.selectAll(".ico_childs")
                        .style("transform", "rotate(0deg)")
                }
            }
            this.controlContainer = d3.select(document.body)
                .append("div")
                .attr("class", "template");

            // >> MENU CONTAINER

            let menuContainer = this.controlContainer
                .append("div")
                .attr("class", "menu_container");

            let menuShowTimeOut: NodeJS.Timeout;
            let menuHideTimeOut: NodeJS.Timeout;

            menuContainer.node()
                .addEventListener("mouseenter", (e) => {
                    const [mobileMode, _] = this.IsMobileMode()
                    if (mobileMode) {
                        e.stopPropagation()
                        SwitchMenuVisibility(true)
                        return
                    }
                    if (menuHideTimeOut != null) {
                        clearTimeout(menuHideTimeOut);
                        menuHideTimeOut = null;
                    }
                    if (menuShowTimeOut == null) {
                        menuShowTimeOut = setTimeout(() => {
                            SwitchMenuVisibility(true)
                            menuShowTimeOut = null;
                        }, MENU_SHOWDELAY);
                    }
                });

            menuContainer.node()
                .addEventListener("mouseleave", (e) => {
                    const [mobileMode, _] = this.IsMobileMode()
                    if (mobileMode) {
                        e.stopPropagation()
                        SwitchMenuVisibility(false)
                        return
                    }
                    if (menuShowTimeOut != null) {
                        clearTimeout(menuShowTimeOut);
                        menuShowTimeOut = null;
                    }
                    if (menuHideTimeOut == null) {
                        menuHideTimeOut = setTimeout(() => {
                            SwitchMenuVisibility(false)
                            menuHideTimeOut = null;
                        }, MENU_HIDEDELAY);
                    }
                });

            UIUtilGlobalKeyEvents._SetKeyEventCallback(menuContainer.node(), {
                key: "A",
                altKey: true,
                callback: () => SwitchMenuVisibility(!menuContainer.classed("menu_hover"))
            })

            let menuHeader = menuContainer
                .append("div")
                .attr("class", "header");

            menuHeader
                .append("label")
                .text(import.meta.env.APP_TITLE)
                .call(lbl => {
                    lbl.node().onclick = () => SwitchMenuVisibility(true)
                    UIUtilGeneral._AddActiverDevelopmentUtils(lbl.node())
                })

            if (DataUtilLocalStorage._HasItem("template_menu", "view_mode")) {
                this.controlContainer.classed("menu_expanded", true);
            }
            menuHeader
                .append("img")
                .attr("class", "btn_pin")
                .attr("src", UIUtilIconResources.CGeneral.Pin)
                .attr("draggable", false)
                .node()
                .onclick = e => {
                    if (this.controlContainer.classed("menu_expanded")) {
                        this.controlContainer
                            .classed("menu_expanded", false);
                        DataUtilLocalStorage._RemoveItem("template_menu", "view_mode");

                        (e.target as HTMLImageElement).src = UIUtilIconResources.CGeneral.Pin;
                    } else {
                        this.controlContainer
                            .classed("menu_expanded", true);
                        DataUtilLocalStorage._SetItem("template_menu", "view_mode", "menu_expanded");

                        (e.target as HTMLImageElement).src = UIUtilIconResources.CGeneral.Unping;
                    }
                };
            menuHeader
                .append("img")
                .attr("class", "btn_hidemenu")
                .attr("src", UIUtilIconResources.CGeneral.AngleDown)
                .attr("draggable", false)
                .node()
                .onclick = e => {
                    SwitchMenuVisibility(false)
                }

            menuContainer.append("hr");

            let menuContent = menuContainer
                .append("div")
                .attr("class", "menu_content shy_scrollthin"); // Childs >.item

            this.menuItemsResizeObserver = UIUtilGeneral._GetResizeObserver((entries) => {
                this.relocateCallbacks
                    .forEach(callback => callback());
            });

            this.menuItemsResizeObserver?.observe(menuContent.node());

            let menuFooter = menuContainer
                .append("div")
                .attr("class", "footer");

            menuFooter
                .append("img")
                .attr("draggable", false)
                .attr("src", UIUtilIconResources.CGeneral.Elevenminds);

            menuFooter
                .append("label")
                .attr("class", "lbl_version")
                .text(Global._APP_VERSION_NAME_TAG);

            // >> WINDOW CONTAINER

            let viewContainer = this.controlContainer
                .append("div")
                .attr("class", "win_container");

            let topBar = viewContainer
                .append("div")
                .attr("class", "topbar");
            topBar
                .append("div")
                .attr("class", "locations");

            if (Global._LICENCIA.DetalleAdeudos) {
                topBar
                    .append("div")
                    .attr("class", "licencia_pago")
                    .text(_L("alumnos.tag_licvencida_paga"))
                    .on("click", () => ViewLicenciasPago());
            }

            let userInfoContainer = topBar
                .append("div")
                .attr("class", "userinfo");

            userInfoContainer
                .append("label")
                .on("click", () => {
                    dropdown._Visible
                        ? dropdown._Hide()
                        : (dropdown._Show(), dropdown._ListContainer.style("margin-top", "var(--padding1)"));
                    /* ctrlUserMenu.met_showOptionsList();
                    ctrlUserMenu.prop_listContainer
                        .style("margin-top", "var(--padding3)"); */
                });

            const winPrincipalContent = viewContainer
                .append("div")
                .attr("class", "principal_content");

            const winFooter = viewContainer
                .append("div")
                .attr("class", "footer")
            winFooter
                .append("label")
                .text(UIUtilLang._GetUIString("app", "copyright"));

            // Controles extras

            this.ctrlViewInfo = new ViewInfoControl(userInfoContainer);

            const dropdown = TemplateMenuUser._Create(userInfoContainer);

            this.ctrlLocationNav = new LocationNav(topBar.select<HTMLDivElement>(".locations"));

            this.controlContainer.__menuContainer = menuContainer
            this.controlContainer.__menuContainer.__header = menuHeader
            this.controlContainer.__menuContainer.__menuContent = menuContent
            this.controlContainer.__menuContainer.__footer = menuFooter
            this.controlContainer.__winContainer = viewContainer
            this.controlContainer.__winContainer.__topbar = topBar
            this.controlContainer.__winContainer.__principalContent = winPrincipalContent
            this.controlContainer.__winContainer.__footer = winFooter
        }

        private UI_UpdateMenu() {
            let container = this.controlContainer
                .select<HTMLDivElement>(":scope > .menu_container > .menu_content");

            this.UI_JoinMenuItems(container, this.menuItems, true);
        }

        private UI_JoinMenuItems(container: d3.Selection<HTMLDivElement, any, any, any>, menuItems: IMenuItem[], addIcons = false) {
            container
                .selectAll<HTMLDivElement, IMenuItem>(":scope > .item")
                .data(menuItems)
                .join(
                    enter => {
                        let item = enter.append("div")
                            .attr("class", "item");

                        const itemContent = item.append("div")
                            .attr("class", "item_content");

                        if (addIcons) {
                            itemContent.append("img")
                                .attr("class", "img_ico")
                                .attr("draggable", false);
                        }

                        itemContent.append("label");

                        itemContent.append("img")
                            .attr("class", "ico_childs")
                            .attr("draggable", false)
                            .attr("src", UIUtilIconResources.CGeneral.AngleDown);

                        item.append("div").classed("subitems shy_scrollthin", true).style("height", "0px");
                        return this.UI_UpdateMenuItem(item);
                    },
                    update => this.UI_UpdateMenuItem(update),
                    exit => exit.remove()
                )
        }

        private UI_UpdateMenuItem(item: d3.Selection<HTMLDivElement, IMenuItem, any, any>) {
            item.classed("selected", d => d.Selected);

            item.select(".item_content > .img_ico")
                .attr("src", d => d.Icon);

            item.select(".item_content > label")
                .text(d => d.Label);

            item
                .classed("whitoutview", (d) => !Boolean(Router._GetRouteView(d.Path)))
                .on("click", (d) => {
                    const route = _ROUTES[d.Path];
                    if (route?.View) {
                        this.DoHash(d.Path, route.DefaultParams);
                    }
                });

            item.select(".item_content > .ico_childs")
                .classed("hide", d => !Boolean(d.Childs?.length));

            item.each((d, i, arr) => {
                let itemParent = d3.select(arr[i]);
                let itemParentNode = itemParent.node();

                itemParentNode.onclick = null;
                let subMenuContainer = itemParent.select<HTMLDivElement>(":scope > .subitems");

                subMenuContainer.on("click", () => {
                    d3.event.stopPropagation();
                });

                this.UI_JoinMenuItems(subMenuContainer, !d.Childs.length ? [] : d.Childs);

                itemParentNode.onclick = (!d.Childs.length) ? null : (e) => {
                    e.stopPropagation();
                    let subMenuContainer = itemParent.select<HTMLDivElement>(":scope > .subitems");
                    subMenuContainer.classed("expanded", !subMenuContainer.classed("expanded"));
                    itemParent.select(":scope > .item_content").select(":scope > .ico_childs")
                        .style("transform", `rotate(${!subMenuContainer.classed("expanded") ? "0" : "180"}deg)`);

                    d3.select<HTMLDivElement, any>(".menu_content").selectAll<HTMLDivElement, any>(".item").each(function (d, i, itemArr) {
                        let currentItem = d3.select(itemArr[i]);
                        if (currentItem.select(":scope > .subitems").style("height") != "0px" && itemArr[i] != itemParentNode && itemArr[i] != itemParentNode.parentElement.parentElement && itemArr[i].parentElement.parentElement != itemParentNode) {
                            currentItem.select(":scope > .item_content").select(":scope > .ico_childs").style("transform", "rotate(0deg)");
                            currentItem.select(":scope > .subitems").style("height", currentItem.select<HTMLDivElement>(":scope > .subitems").node().clientHeight + "px").classed("expanded", false);
                            currentItem.select(":scope > .subitems").transition().duration(300).style("height", "0px");
                        }
                    })

                    if (!subMenuContainer.classed("expanded")) {
                        subMenuContainer.interrupt().style("height", subMenuContainer.node().clientHeight + "px");
                        subMenuContainer.transition().duration(300)
                            .style("height", "0px");
                    } else {
                        let numChildItems = d3.select(itemParentNode).selectAll(":scope >.subitems").selectAll<HTMLDivElement, IMenuItem>(":scope >.item").data().length;
                        let numSubItemsExpanded = subMenuContainer.selectAll(".subitems").filter((d, i, arr) => d3.select(arr[i]).style("height") != "0px").selectAll<HTMLDivElement, IMenuItem>(":scope >.item").data().length;
                        subMenuContainer.transition().duration(300)
                            .style("height", ((numChildItems + numSubItemsExpanded) * 40) + "px").end().then(() => {
                                subMenuContainer.style("height", "max-content");
                            }).catch(() => null);
                    }
                }
            })

            return item;
        }

        private UI_UpdateLocationNavs(itemNav: IPageItem) {
            let itemsNav: IPageItem[] = itemNav ? [itemNav] : [];

            if (itemNav) {
                let itemNavAux = itemNav.Parent;
                while (itemNavAux) {
                    itemsNav.push(itemNavAux);
                    itemNavAux = itemNavAux.Parent;
                }
            }

            // for (let itemN of itemsNav) {
            //     newNavs.push({
            //         BaseHash: itemN.Path,
            //         OnCall: () => {
            //         },
            //         // GetHash: () => {
            //         //     let path = "#" + itemN.Path;
            //         //     if (beforeHashInfo) {
            //         //         const location = beforeHashInfo[0];
            //         //         if (location == path) {
            //         //             // console.warn("Se recuperó el path anterior!!!");
            //         //             path = beforeHashInfo[1];
            //         //         }
            //         //     }
            //         //     return path;
            //         // },
            //         Label: itemN.Label,
            //         IsEnable: Boolean(itemN.View),
            //     })
            // }

            // console.log(newNavs, "new-navs");
            // this.ctrlLocationNav.met_Clear();
            this.ctrlLocationNav._UpdateLocations(
                itemsNav.reverse()
                    .map((d, i) => {
                        const route = _ROUTES[d.Path]
                        return ({
                            BaseHash: d.Path,
                            OnCall: route?.View ?
                                () => {
                                    // const windowHasHistory = ui.ventanas.WindowManager._windowHashCreatedHistory;
                                    // const beforeHashInfo = windowHasHistory[windowHasHistory.length - (i + 1)];
                                    const beforeHashInfoAux = UIWindowManager._GetHashInfoInHistory(i);
                                    console.warn(beforeHashInfoAux);

                                    if (beforeHashInfoAux && beforeHashInfoAux.Path == d.Path) {
                                        // ventanas.WindowManager.fn_UICreateOrUpdateCurrentWindow(beforeHashInfo[1]); // TEMPORAL
                                        // ventanas.WindowManager.fn_DoHash(beforeHashInfoAux.Path, beforeHashInfoAux.Params);
                                        for (let r = 0; r < i; r++) { // NOTE IMPLEMENTAR
                                            history.back();
                                        }
                                    } else {
                                        this.DoHash(d.Path, route.DefaultParams);
                                    }
                                }
                                : null,
                            Label: d.Label,
                            IsEnable: Boolean(route?.View),
                        })
                    })
            );
        }

        private FindFocusHash(currentHash: string = UIWindowManager._GetCurrentPath()) {
            if (!currentHash) return
            currentHash = currentHash.replace("/panel", "");
            const fnFindCurrentHashInItems = (menuItems: IMenuItem[]) => {
                menuItems
                    .forEach(menuItem => {
                        const itemHash = menuItem.Path?.replace("#", "").replace("/panel", "");
                        menuItem.Selected = (currentHash == itemHash);

                        if (menuItem.Childs?.length) {
                            fnFindCurrentHashInItems(menuItem.Childs);
                        }
                    });
            }
            fnFindCurrentHashInItems(this.menuItems);
        }

        private DoHash(path: TRoutePath, defaultParams?: THashParams) {
            UIWindowManager._DoHash(path, defaultParams);
            const [mobileMode, btn] = this.IsMobileMode()
            if (mobileMode) {
                btn.click() // Colapsa el menú cuando es vista mobil
            }
        }

        /** [IsMobileMode, ValidableElement] */
        private IsMobileMode(): [boolean, HTMLElement] {
            const btn_hidemenu = this.controlContainer.__menuContainer.__header.select<HTMLDivElement>(".btn_hidemenu").node()
            return [
                !!btn_hidemenu?.getBoundingClientRect().width,
                btn_hidemenu
            ]
        }

        //private AdjustItemDefault(item: IMenuItem) {
        //    item.SubmenuType = item.SubmenuType || "floating";
        //    if (item.Childs) {
        //        item.Childs.forEach(child => {
        //            this.AdjustItemDefault(child);
        //        })
        //    }
        //    return item;
        //}

        // *****************************************************************************
        // PUBLIC METHODS
        // *****************************************************************************

        public get _ContenedorPrincipal() {
            return this.controlContainer.select<HTMLDivElement>(":scope > .win_container > .principal_content");
        }

        public _SetAppTitle(title: string) {
            this.controlContainer.select(":scope > .menu_container > .header > label")
                .text(title);
            return this;
        }

        public _UpdateUser(userName: string) {
            let usernameHTML = "";
            if (userName) {
                let [part1, part2] = userName.replace(" ", "**").split("**");
                usernameHTML = part1[0];
                usernameHTML += "<span>" + part1.slice(1) + "</span>";
                if (part2) {
                    usernameHTML += "<span> </span>" + part2[0];
                    usernameHTML += "<span>" + part2.slice(1) + "</span>";
                }
            }

            this.controlContainer.select(":scope > .win_container > .topbar > .userinfo > label")
                .html(usernameHTML);
            return this;
        }

        // public _UpdateVersionTag(version: string) {
        //     this.controlContainer.select(":scope > .menu_container > .footer > .lbl_version")
        //         .text(version);
        //     return this;
        // }

        public _UpdateMainOptions(items: IMenuItemCfg[]) {
            this.menuItems = items as IMenuItem[];
            this.FindFocusHash();
            this.UI_UpdateMenu();
            return this;
        }

        public _RefreshLocationNavs(path: TRoutePath) {
            const itemPagesNav = UIWindowPagesNavUtils._GetMainPageMenuItem(path);
            this.UI_UpdateLocationNavs(itemPagesNav);
            return this;
        }

        public _RefreshWindowInfo() {
            this.ctrlViewInfo._Refresh();
            return this;
        }

        public _Destroy() {
            this.ctrlViewInfo._Destroy();
            this.controlContainer.remove();
            this.menuItemsResizeObserver?.disconnect();
            this.menuItemsResizeObserver = null;
            UIWindowManager._RemoveOnHashPathChangeListener(this["__HashPathChangeListener"]);
        }
    }
}

// ****************************************************************
// LICENCIA VIEW
// ****************************************************************

function ViewLicenciasPago() {
    type TItemLicenciaComponent = (HTMLDivElement & {
        _IdEscuela: number
        _LicenciaPago: ILicenciaConfigOrganizacionDetalleAdeudo
    })
    const fnCreateItemLicenciaComponent = () => {
        const mainElement = UtilObject._GettersSetters(document.createElement("div") as TItemLicenciaComponent, {
            set_IdEscuela: (idEscuela) => {
                escuelaElement.innerHTML = `<b>${_L("licencia.d_field_nombrekinder")} </b>: ` + _DiccFullEscuelas.get(idEscuela).Nombre
            },
            set_LicenciaPago: (d) => {
                let strCosto = `<b>${_L("licencia.tag_totalpago")}</b>: ` + UIUtilFormat._CurrencyFmt(d.Saldo)
                if (d.Detalles.length) {
                    d.Detalles.forEach(det => {
                        if (det.TipoRegistro == CLicenciaConfigOrganizacionTipoRegistro.IVA) {
                            strCosto += (Global._LICENCIA.IVA ? ` + IVA(${UIUtilFormat._CurrencyFmt(det.Valor)})` : "")
                        }
                    })
                    strCosto += ` = ` + UIUtilFormat._CurrencyFmt(d.SaldoFinal)
                }
                const esVencido = new Date() > new Date(d.FechaTolerancia)
                costoElement.innerHTML = strCosto
                periodoElement.innerHTML = `<b>${_L("licencia.d_field_periodo")}</b>: ${UIUtilTime._DateFormatStandar(d.FechaInicio)} - ${UIUtilTime._DateFormatStandar(d.FechaFin)}`
                fechaLimiteElement.innerHTML = `<b>${_L("licencia.tag_dtlimitepago")}</b>: `
                    + (esVencido ? `<span style="color:var(--color_app_red1);">` : "<span>")
                    + UIUtilTime._DateFormatStandar(d.FechaTolerancia, "d MMM yyyy")
                    + "</span"
            }
        })
        mainElement.classList.add("shadow1")
        mainElement.style.padding = "var(--padding1)"
        mainElement.style.borderLeft = "4px solid var(--color_app_red1)"
        mainElement.style.borderRadius = "var(--border_radius_base)"

        const escuelaElement = document.createElement("pre")
        const periodoElement = document.createElement("pre")
        const costoElement = document.createElement("pre")
        const fechaLimiteElement = document.createElement("pre")
        mainElement.append(escuelaElement)
        mainElement.append(periodoElement)
        mainElement.append(costoElement)
        mainElement.append(fechaLimiteElement)
        return mainElement
    }
    ModalThings._GetModalSimple({
        Title: _L("alumnos.tag_licvencida_paga") + " - " + _L("licencia.tag_detail"),
        Width: 450,
        DrawContent: (content) => {
            content.append("b").text(_L("licencia.tag_paymentrecipient"))
            content.append("pre")
                .style("user-select", "text")
                .text(
                    `${_L("licenciamiento_bloqueo.clabe")}: ${licenciaPayInfo.clabe}`
                    + `\n${_L("licenciamiento_bloqueo.beneficiario")}: ${licenciaPayInfo.beneficiario}`
                    + `\n${_L("licenciamiento_bloqueo.institucion")}: ${licenciaPayInfo.institucion}`
                )
                .call((preElement) => {
                    const img = document.createElement("img") as HTMLImageElement
                    img.alt = _L("general.copiar")
                    img.src = UIUtilIconResources.CGeneral.Copy
                    img.onclick = () => {
                        navigator.clipboard.writeText(licenciaPayInfo.clabe)
                        _ShowTemporalTooltip(preElement, _L("general.copiedtext"))
                    }
                    img.style.width = "20px"
                    img.style.height = "20px"
                    img.style.position = "absolute"
                    img.style.right = "var(--padding2)"
                    img.style.top = "0"
                    img.style.cursor = "pointer"
                    preElement.style("position", "relative")
                    preElement.append(() => img)
                })
            content.append("br")
            content.append("hr").style("color", "var(--color_borderbox1)")
            content.append("br")
            content.append("div")
                .attr("class", UIUtilGeneral.FBoxOrientation.Vertical)
                .style("gap", "var(--padding2)")
                // .style("margin-left", subItemsMarginL)
                .call(div => {
                    Global._LICENCIA._GetDetalleAdeudosArray()
                        .sort((a, b) => (new Date(a.FechaTolerancia).getTime() - new Date(b.FechaTolerancia).getTime()))
                        .forEach(d => {
                            const idEscuela = d._IdEscuela
                            const element = fnCreateItemLicenciaComponent()
                            element._IdEscuela = idEscuela
                            element._LicenciaPago = d
                            div.append(() => element)
                        })
                })
        }
    })
}