import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataModuloMain } from "../ModuloMain";
import { DataUtil } from "../util/Util";

/** @beforename Nivel */
import IGrado = Entidad.IGrado;
export const _DiccGrado: Map<number, IGrado> = new Map();
/** Map<idGrado, Map<idGrupo, Grupo>> */
export var _DiccAsignacionGradoGrupos: Map<number, Map<number, Entidad.IGrupo>> = new Map();

type TIKinder = Entidad.IEscuela;
const RequestID = Entidad.CTipoRequest.Grado;

export async function _GetList(escuelas: Array<TIKinder>): Promise<DataDRequest.IRequestResponseListA<IGrado>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "grado/ObtenerLista";
    const results = new Array<DataDRequest.IRequestResponseListA<IGrado>>();

    for (let escuela of escuelas) {
        const currentDateStr = await DataUtil._GetMaxDate(RequestID, escuela.IdKinder);
        const params = {
            "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
            "IdEscuela": escuela.IdKinder,
            "Modificacion": currentDateStr
        }

        let result = await DataDRequest._Request4List(url, "GET", params, RequestID, escuela.IdKinder);
        results.push(result);
    }
    return results;
}

export async function _AltaNivel(datoN: Entidad.IGrado): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "grado/NuevoGrado";
    Global._GLOBAL_CONF
    const params = {
        "Nombre": datoN.Nombre,
        "IdEscuela": datoN.IdKinder,
        "IdEscolaridad": datoN.IdEscolaridad,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: DataDRequest.IRequestResponseBase) => {
            resolve({
                Resultado: (result?.Resultado || -100),
                TipoRequest: Entidad.CTipoRequest.Grado
            });
        });
    });
}

// export async function _ActualizaNivel(datoN: Entidad.IGrado): Promise<DataDRequest.IResultadoPeticion<undefined>> {
//     const url = Global._GLOBAL_CONF.URL_RSTV1 + "Nivel/ActualizarNivel";
//     const params = {
//         "IdNivel": datoN.IdNivel,
//         "Nombre": datoN,
//         "IdUsuario": DataUtil._Usuario.IdUsuario
//     }

//     return new Promise(function (resolve, reject) {
//         DataDRequest._Request(url, "POST", params, (result: number) => {
//             resolve({
//                 Resultado: (result || -100),
//                 TipoRequest: Entidad.CTipoRequest.Grado
//             });
//         });
//     });
// }

// export async function _EliminaNivel(idNivel: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
//     const url = Global._GLOBAL_CONF.URL_RSTV1 + "Nivel/EliminarNivel";
//     const params = {
//         "IdNivel": idNivel,
//         "IdUsuario": DataUtil._Usuario.IdUsuario
//     }

//     return new Promise(function (resolve, reject) {
//         DataDRequest._Request(url, "POST", params, (result: number) => {
//             resolve({
//                 Resultado: (result || -100),
//                 TipoRequest: Entidad.CTipoRequest.Grado
//             });
//         });
//     });
// }

// *************************************************************
// LOCAL DATA
// *************************************************************

/**
 * @param idGrado > IdNivel
 * @returns 
 */
export function _LOCALDATA_GetGruposEnGrado(idGrado: number): Map<number, Entidad.IGrupo> {
    return (_DiccAsignacionGradoGrupos.get(idGrado) || new Map());
}

export function _LOCALDATA_GetAlumnosEnGrado(idGrado: number): Array<Entidad.IAlumno> {

    return DataModuloMain._GetReqDataArrayByName("Alumno")
        .filter(d => (d.IdGrado == idGrado));
}

export default {
    _DiccGrado,
    _DiccAsignacionGradoGrupos,
    _AltaNivel,
    _LOCALDATA_GetGruposEnGrado,
    _LOCALDATA_GetAlumnosEnGrado
}
