import { DataCache } from "./Cache";
import { Entidad } from "./Entidad";
import { DataModuloMain } from "./ModuloMain";
import { DataUtilAlertBot } from "./util/AlertBot";

export namespace DataDRequest {
    export type ResCode = number;
    export const __RESCODE_SUCCESS: ResCode = 1;
    export const __RESCODE_UNKNOWNERROR: ResCode = -100;
    export const __RESCODE_OFFLINECLIENT: ResCode = -101;
    export const __RESCODE_HTTP_METHODNOTALLOWED: ResCode = -405;
    type TParams = { [key: string]: any }

    type KMetodos = "POST" | "GET"

    export interface IRequestResponseBase<Data = undefined> {
        Resultado: number;
        Datos?: Data;
    }

    export interface IRequestResponseA<Data = undefined> extends IRequestResponseBase<Data> {
        TipoRequest?: Entidad.CTipoRequest;
        Mensaje?: string;
        /** `module/serviceaction`
         * @example "alumno/Nuevo"
         */
        EndPointName?: string;
    }

    export interface IRequestResponseListBase<Data = { [k: string]: any }> extends IRequestResponseBase<Data[]> {
        Maxima: string;
    }

    export interface IRequestResponseListA<TD> extends IRequestResponseListBase<TD> {
        TipoRequest?: Entidad.CTipoRequest;
        ID?: number;
        Mensaje?: string;
        Error?: Error;
    }

    /** @deprecated usar IRequestResponseA */
    export interface IResultadoPeticion<TData> {
        /** @deprecated */
        Resultado: number;
        /** @deprecated */
        Mensaje?: string;
        /** @deprecated */
        Data?: TData;
        /** @deprecated */
        TipoRequest?: Entidad.CTipoRequest;
    }

    type RequestCallback<TResult> = (result: TResult, response: Response, error: Error, resCode: ResCode) => void;
    interface IRequestBaseParams<TResponse> {
        requestURL: string
        method: KMetodos
        params: TParams
        callback: RequestCallback<TResponse>
        /** Enviar AlertBotMessage en caso de `Resultado` negativo */
        sendNegativeResultAlert?: boolean
    }
    function _RequestBase<TResponse = any>({ requestURL, method, params, callback, sendNegativeResultAlert }: IRequestBaseParams<TResponse>) {
        if (method == 'GET' && params != null) {
            requestURL += ParamsGET(params);
        }
        const request = new Request(requestURL, {
            method: method,
            body: method == "POST" ? JSON.stringify(params) : null,
            cache: "no-cache"
            // headers: {
            //     "Content-type": "application/json; charset=UTF-8"
            // }
        });
        const startOnLine = navigator.onLine;
        const getResCode = () => {
            if (!startOnLine && !navigator.onLine) {
                return __RESCODE_OFFLINECLIENT;
            }
            return __RESCODE_UNKNOWNERROR;
        }
        fetch(request)
            .then(async (response) => {
                if (!response.ok) {
                    callback(null, response, null, getResCode());
                    return;
                }
                const result = await response.json();
                if (!result) {
                    console.warn("Result response null", response);
                    return;
                }
                const reqFail = (typeof result == "number" && result <= 0) || (result?.Resultado != null && result.Resultado <= 0);
                if (sendNegativeResultAlert && reqFail) {
                    DataUtilAlertBot._SendWarn(requestURL, JSON.stringify(result, null, 2));
                }
                callback(result, null, null, __RESCODE_SUCCESS);
            })
            .catch((e: Error) => {
                callback(null, null, e, getResCode());
                if (e.message != "Failed to fetch")
                    console.error(e.stack);
            });
    }

    function EvalResCode(resultado: number, resCode: number, response: Pick<Response, "status">): number {
        const httpResStatus: number = response ? -response.status : 0
        const httpEvals: number[] = [__RESCODE_HTTP_METHODNOTALLOWED]
        const isErrHTTP: boolean = httpResStatus ? httpEvals.includes(httpResStatus) : false
        return (
            resultado != null
                ? resultado
                : (isErrHTTP ? httpResStatus : resCode < 0 ? resCode : __RESCODE_UNKNOWNERROR)
        )
    }

    /**
     * @param requestURL
     * @param method
     * @param params
     * @param callback
     */
    export function _Request<TResponse = Object>(requestURL: string, method: KMetodos, params: Object, callback: RequestCallback<TResponse>) {
        return _RequestBase({ requestURL, method, params, callback, sendNegativeResultAlert: true });
    }

    /** @deprecated */
    export interface IRequestResponse<TData> {
        /** @deprecated */
        error: Error
        /** @deprecated */
        data: TData
    }
    /** @deprecated */
    export async function _Request2<T>(requestURL: string, method: KMetodos, params: TParams): Promise<IRequestResponse<T>> {
        if (method == 'GET' && params != null)
            requestURL += ParamsGET(params);

        let request = new Request(requestURL, {
            method: method,
            body: method == "POST" ? JSON.stringify(params) : null,
            cache: "no-cache"
            // headers: {
            //     "Content-type": "application/json; charset=UTF-8"
            // }
        });

        let res: IRequestResponse<T> = { error: null, data: null };

        try {
            let response = await fetch(request);

            res.data = <T>await response.json();

            if (!response.ok) {
                res.error = { name: "consulta", message: "(" + response.status + ") " + response.statusText };
                console.warn("Resquest error. Response:", response, res);
                return res;
            }

        } catch (e: any) {
            res.error = { name: "error", message: "error" };
            if (e.message != "Failed to fetch")
                console.warn("Error fetch: ", e);
            return res;
        }
        let result = res.data as any;
        if ((typeof result == "number" && result <= 0) || (result?.Resultado != null && result.Resultado <= 0)) DataUtilAlertBot._SendWarn(requestURL, JSON.stringify(result, null, 2))
        return res;
    }

    /**
     * Por defecto las respuestas usan la entidad de /adminjsv2/
     *
     * -   {
     * -       Resultado: number;
     * -       Datos?: TData;
     * -       Maxima?: string;
     * -   }
     */
    export async function _Request4List<TReqId extends Entidad.CTipoRequest, TData extends DataModuloMain.TTypeRequestEntitiesMap[TReqId]>(
        requestURL: string,
        method: KMetodos,
        params: Object,
        requestID?: TReqId,
        idResult: number = requestID,
    ): Promise<IRequestResponseListA<TData>> {
        return new Promise((resolve, reject) => {
            _Request<IRequestResponseListBase<TData>>(requestURL, method, params, (result, response, err, resCode) => {
                resolve({
                    TipoRequest: requestID,
                    ID: idResult,
                    Resultado: EvalResCode(result?.Resultado, resCode, response),
                    Datos: result?.Datos,
                    Maxima: result?.Maxima,
                    Error: err
                });
            });
        });
    }

    const REQUEST5_MANAGER: { [k: (string | number)]: Promise<IRequestResponseA> } = {}
    interface IRequest5Extras {
        requestID?: Entidad.CTipoRequest
        /** No enviar AlertBotMessage en caso de fallo */
        ignoreNegativeResultAlert?: boolean
        /** Almacena la promesa hasta que se cumpla. Evita multiples peticiones repetidas.
         * * Si se solicita la petición retornará la Promesa almacenada disponible
         * * Si no Petición en curso se realiza una nueva
         * */
        idToKeepPromiseUntilFinished?: string | number
    }
    /**
     * Implementa {@link _RequestBase DataDRequest._RequestBase}
     *
     * Siempre retorna una respuesta con base:
     *
     * {@link DataDRequest.IRequestResponseA<TD = undefined> DataDRequest.IRequestResponseA<TD = undefined> & TExtends}
     *
     * @example
     * {
     *     Resultado: number, // Si la peticion falla, retorna un negativo predefinido
     *     Datos?: TDataDesponse,
     *     Maxima?: string,
     *     TipoRequest?: CTipoRequest
     *     EndPointName: string,
     * }
     */
    export async function _Request5<TDataDesponse = undefined, TExtends extends {} = {}>(
        requestURL: string,
        method: KMetodos,
        params: Object,
        requestID_Extras?: (Entidad.CTipoRequest | IRequest5Extras)
    ): Promise<IRequestResponseA<TDataDesponse> & TExtends> {
        type PromiseRequired = Promise<IRequestResponseA<TDataDesponse> & TExtends>
        const extraIsReqID = typeof requestID_Extras == "number";
        const extras: IRequest5Extras = extraIsReqID ? null : requestID_Extras;
        const requestID: Entidad.CTipoRequest = extraIsReqID ? requestID_Extras : (requestID_Extras?.requestID || undefined);
        const fullIdToKeepPromise: string = (() => {
            const idToKeepPromiseUntilFinished = extras?.idToKeepPromiseUntilFinished
            if (idToKeepPromiseUntilFinished == null)
                return null
            return `${requestURL}_${idToKeepPromiseUntilFinished}`
        })()

        if (fullIdToKeepPromise != null && REQUEST5_MANAGER[fullIdToKeepPromise]) {
            // console.debug("Request5 >> ", fullIdToKeepPromise, "Promesa reutilizada")
            return REQUEST5_MANAGER[fullIdToKeepPromise] as PromiseRequired
        }

        const promise = new Promise<IRequestResponseA<TDataDesponse> & TExtends>((resolve, reject) => {
            _RequestBase({
                requestURL,
                method,
                params,
                sendNegativeResultAlert: !extras?.ignoreNegativeResultAlert,
                callback: (result, response, err, resCode) => {
                    let res = result as IRequestResponseBase<TDataDesponse> & TExtends;
                    let endPointName = SplitEndPoint(requestURL);
                    if (res == null) {
                        res = <IRequestResponseBase<TDataDesponse> & TExtends>{}
                    }
                    res.Resultado = EvalResCode(res?.Resultado, resCode, response)
                    if (fullIdToKeepPromise != null && REQUEST5_MANAGER[fullIdToKeepPromise]) {
                        REQUEST5_MANAGER[fullIdToKeepPromise] = undefined
                    }
                    resolve({
                        ...res,
                        TipoRequest: requestID,
                        EndPointName: endPointName,
                    });
                }
            })
        })
        if (fullIdToKeepPromise != null) {
            REQUEST5_MANAGER[fullIdToKeepPromise] = promise as any
        }
        return promise
    }

    /**
     * Realiza una solicitud HTTP utilizando `XMLHttpRequest` y procesa la respuesta como JSON.
     *
     * @template TDataResponse - Tipo genérico para la estructura de datos esperada en la respuesta.
     * @param {string} url - URL del endpoint al que se enviará la solicitud.
     * @param {KMetodos} method - Método HTTP a utilizar (`GET`, `POST`, etc.).
     * @param {FormData} formData - Datos a enviar en la solicitud, encapsulados en un objeto `FormData`.
     * @param {Entidad.CTipoRequest} [requestID] - Identificador opcional para categorizar el tipo de solicitud.
     * @returns {Promise<IRequestResponseA<TDataResponse>>} Una promesa que se resuelve con la respuesta del servidor.
     *
     * @example
     * const formData = new FormData();
     * formData.append("key", "value");
     *
     * makeHttpRequest("https://api.example.com/data", "POST", formData)
     *   .then(response => {
     *     console.log("Respuesta exitosa:", response);
     *   })
     *   .catch(error => {
     *     console.error("Error en la solicitud:", error);
     *   });
     */
    export async function _XMLHttpRequestFormHandler<TDataResponse = undefined>(url: string, method: KMetodos, formData: FormData, requestID?: Entidad.CTipoRequest): Promise<IRequestResponseA<TDataResponse>> {
        return new Promise((resolve, _) => {
            const endPointName = SplitEndPoint(url);
            const XHR = new XMLHttpRequest();
            XHR.responseType = "json";
            XHR.open(method, url);
            XHR.send(formData);
            XHR.onload = function () {
                if (this.status == 200) {
                    let res = XHR.response as IRequestResponseA<TDataResponse>;
                    res.Resultado = EvalResCode(res?.Resultado, null, XHR);
                    resolve({
                        ...res,
                        ...{
                            TipoRequest: requestID,
                            EndPointName: endPointName
                        }
                    })
                } else {
                    resolve({
                        Resultado: __RESCODE_UNKNOWNERROR, // -500,
                        TipoRequest: requestID,
                        EndPointName: endPointName
                    })
                }
            };
            XHR.onerror = function (e) {
                console.error(e)
                resolve({
                    Resultado: __RESCODE_UNKNOWNERROR,
                    TipoRequest: requestID,
                    EndPointName: endPointName,
                })
            };
        })
    }

    /**
     * Realiza una solicitud HTTP para obtener `FormData`.
     *
     * @param {string} url - La URL a la que se realizará la solicitud.
     * @param {KMetodos} method - El método HTTP que se usará (por ejemplo, "GET" o "POST").
     * @param {TParams} params - Los parámetros de la solicitud. Se agregarán como cadena de consulta si el método es "GET".
     *
     * @example
     * fetchFormDataFromRequest(
     *   "https://example.com/api/upload",
     *   "GET",
     *   { fileId: 123 },
     *   (formData) => {
     *     if (formData) {
     *       console.log("FormData recibido:", formData);
     *     } else {
     *       console.error("Error al obtener el FormData.");
     *     }
     *   }
     * );
     */
    export async function _RequestFormData(url: string, method: KMetodos, params: TParams): Promise<FormData> {
        if (method == 'GET' && params != null)
            url += ParamsGET(params);

        const request = new Request(url, {
            headers: { "Accept": "multipart/form-data" }
        });
        return fetch(request)
            .then(response => response.formData())
            .catch((error) => { throw error })
    }

    /**
     * Realiza una solicitud HTTP [GET] para descargar un archivo desde una URL y lo devuelve como un objeto `File`.
     *
     * @param {string} url - La URL del archivo que se va a descargar.
     * @param {string} filename - El nombre que se asignará al archivo descargado.
     * @returns {Promise<File>} Una promesa que se resuelve con el archivo descargado o se rechaza en caso de error.
     *
     * @example
     * _XMLHttpRequestFile('https://example.com/file.txt', 'myFile.txt')
     *   .then(file => {
     *     console.log(file); // File {name: "myFile.txt", size: ..., type: "octet/stream"}
     *   })
     *   .catch(error => {
     *     console.error('Error descargando el archivo:', error);
     *   });
     */
    export function _XMLHttpRequestFile(url: string, filename: string): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            const XHR = new XMLHttpRequest()
            XHR.responseType = "blob"
            XHR.open("GET", url)
            XHR.send(null)
            XHR.onload = function () {
                try {
                    const file = new File([XHR.response], filename, { type: "octet/stream" });
                    resolve(file);
                } catch (e) {
                    reject(e)
                }
            }
            XHR.onerror = function (e) {
                console.warn(e);
                reject(e);
            }
        });
    }

    /**
     * @deprecated
     */
    export function _RequestBlobFromUrlResource(url: string, callback: (resource: Blob, error: Error, response: Response) => void) {
        fetch(url)
            .then(async (response) => {
                let blob = await response.blob();
                blob = blob.size > 0 ? blob : null;
                // console.debug(response, " >> Blob:", blob);
                callback(blob, null, response);
            })
            .catch((error: Error) => {
                callback(null, error, null);
                if (error.message != "Failed to fetch")
                    console.warn("Blob from resource error:", error);
            })
    }

    /** Hace uso de CacheStorage
     * @deprecated
     */
    export function _RequestBlobFromUrlResourceV2(url: string, callback: (resource: Blob, error) => void) {
        DataCache._GetData(url)
            .then(async (response) => {
                if (response) {
                    let blob = await response.blob();
                    blob = blob.size > 0 ? blob : null;
                    callback(blob, null);
                } else {
                    _RequestBlobFromUrlResource(url, callback);
                }
            })
            .catch((error: Error) => {
                callback(null, error);
                if (error.message != "Failed to fetch")
                    console.warn("Cache resource error:", error);
            })
    }

    // ****************************************************************
    // Private Methods
    // ****************************************************************

    function SplitEndPoint(requestURL: string) {
        let endpointSplitted = requestURL.split("/");
        return endpointSplitted[endpointSplitted.length - 2] + "/" + endpointSplitted.pop();
    }

    function ParamsGET(params: TParams): string {
        let concatStr = "?";
        let i = 0;
        for (let k in params) {
            if (i > 0)
                concatStr += "&";

            concatStr += k + "=" + params[k];
            // console.log("k: " + k + " => " + params[k]);
            // concatStr += k + "=" + params.get(k);
            i++;
        }
        return encodeURI(concatStr);
    }
}
