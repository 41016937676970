import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

// const IDREQUEST = Entidad.CTipoRequest.EgresoMovimiento;
const URL_EGRESOMOVIMIENTO = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoMovimiento/";

export async function _GetPagosPendientes(idsEscuelas: number[]) {
    type IPagoPendiente = Entidad.IFinanzaEgresoPagoPendiente;
    const url = URL_EGRESOMOVIMIENTO + "ObtenerEgresosPagosPendientes";
    return new ArrayV2<number>()
        ._Push(...idsEscuelas)
        ._ReduceAwait(async (result, idEscuela) => {
            const params = {
                "IdEscuela": idEscuela,
            }
            const escuelaRes = await DataDRequest._Request4List(url, "GET", params, null, null);
            if (result.Resultado > 0 && escuelaRes.Resultado <= 0) {
                result.Resultado = -1;
            }
            if (escuelaRes.Datos?.length) {
                result.Datos.push(...escuelaRes.Datos);
            }
            return result;
        }, <DataDRequest.IRequestResponseA<IPagoPendiente[]>>{
            Resultado: 1,
            Datos: [],
        });
}

interface INuevoEgreso {
    Folio: string;
    IdEscuela: number;
    IdProveedor: number;
    Descripcion: string;
    TipoEgreso: number;
    IdCategoria: number;
    FechaAplicacion: string;
    /** Fecha de emision del comprobante de pago */
    FechaEmision: string;
    FechaVencimiento: string;
    SubTotal: number;
    Descuento: number;
    RetencionISR: number;
    RetencionIVA: number;
    RetencionIEPS: number;
    TrasladoIVA: number;
    TrasladoIEPS: number;
    EsFactura: boolean;
}
/**
 * @example Result:
 * {
 *      Resultado // IdMovimientoEgresoPendientePago
 * }
 */
export function _NuevoEgreso(egreso: INuevoEgreso) {
    const url = URL_EGRESOMOVIMIENTO + "NuevoEgreso";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "Folio": egreso.Folio,
        "IdEscuela": egreso.IdEscuela,
        "IdProveedor": egreso.IdProveedor,
        "Descripcion": egreso.Descripcion,
        "TipoEgreso": egreso.TipoEgreso,
        "IdCategoria": egreso.IdCategoria,
        "FechaAplicacion": egreso.FechaAplicacion,
        "FechaEmision": egreso.FechaEmision,
        "FechaVencimiento": egreso.FechaVencimiento,
        "Cantidad": egreso.SubTotal || 0,
        "Descuento": egreso.Descuento || 0,
        "RetencionISR": egreso.RetencionISR || 0,
        "RetencionIVA": egreso.RetencionIVA || 0,
        "RetencionIEPS": egreso.RetencionIEPS || 0,
        "TrasladoIVA": egreso.TrasladoIVA || 0,
        "TrasladoIEPS": egreso.TrasladoIEPS || 0,
        "TipoDocumento": Number(egreso.EsFactura),
    }
    return DataDRequest._Request5(url, "POST", params);
}

type IActualizarEgreso = Pick<INuevoEgreso, "IdCategoria" | "IdProveedor" | "IdEscuela" | "Descripcion"
    | "TipoEgreso" | "FechaAplicacion" | "FechaVencimiento"
    | "Descuento" | "TrasladoIEPS" | "TrasladoIVA" | "RetencionIEPS" | "RetencionIVA" | "RetencionISR"> & {
        SubTotal: number;
    }
export function _ActualizarEgreso(idMovimiento: number, mov: IActualizarEgreso) {
    const url = URL_EGRESOMOVIMIENTO + "EditarEgreso";
    const params = {
        "IdMovimiento": idMovimiento,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdProveedor": mov.IdProveedor,
        "Descripcion": mov.Descripcion,
        "TipoEgreso": mov.TipoEgreso,
        "FechaAplicacion": mov.FechaAplicacion,
        "FechaVencimiento": mov.FechaVencimiento,
        "IdCategoria": mov.IdCategoria,
        "Cantidad": mov.SubTotal || 0,
        "Descuento": mov.Descuento || 0,
        "RetencionISR": mov.RetencionISR || 0,
        "RetencionIVA": mov.RetencionIVA || 0,
        "RetencionIEPS": mov.RetencionIEPS || 0,
        "TrasladoIVA": mov.TrasladoIVA || 0,
        "TrasladoIEPS": mov.TrasladoIEPS || 0,
    }
    return DataDRequest._Request5(url, "POST", params);
}

export async function _GetMovimientos(idsEscuelas: number[]) {
    type IMovimiento = Entidad.IFinanzaEgresoMovimiento;
    const url = URL_EGRESOMOVIMIENTO + "ObtenerMovimientos";
    const resArray = await new ArrayV2<number>()
        ._Push(...idsEscuelas)
        ._MapAwait<DataDRequest.IRequestResponseListA<IMovimiento>>(async idEscuela => {
            const params = {
                "IdEscuela": idEscuela,
            }
            return await DataDRequest._Request4List(url, "GET", params, null, null);
        })
    return resArray.reduce<DataDRequest.IRequestResponseA<IMovimiento[]>>(
        (result, escuelaRes) => {
            if (result.Resultado > 0 && escuelaRes.Resultado <= 0) {
                result.Resultado = -1;
            }
            if (escuelaRes.Datos?.length) {
                result.Datos.push(...escuelaRes.Datos);
            }
            return result;
        },
        <DataDRequest.IRequestResponseA<IMovimiento[]>>{
            Resultado: 1,
            Datos: [],
        }
    );
}

interface INuevoPagoParams {
    IdMovimiento: number;
    IdCuenta: number;
    Cantidad: number;
    FechaPago: string;
}
export function _NuevoPago(datosPago: INuevoPagoParams) {
    const url = URL_EGRESOMOVIMIENTO + "NuevoPago";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdMovimiento": datosPago.IdMovimiento,
        "IdCuenta": datosPago.IdCuenta,
        "Cantidad": datosPago.Cantidad,
        "FechaPago": datosPago.FechaPago,
    }
    return DataDRequest._Request5(url, "POST", params, null)
}

interface INuevoPagoAgrupadoParams {
    IdCuenta: number;
    FechaPago: string;
    IdMovimientos: number[];
    Montos: number[];
}
export function _NuevoPagoAgrupado(datosPago: INuevoPagoAgrupadoParams) {
    const url = URL_EGRESOMOVIMIENTO + "PagoMultipleAgrupado";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdMovimientos": datosPago.IdMovimientos,
        "IdCuenta": datosPago.IdCuenta,
        "Montos": datosPago.Montos,
        "FechaPago": datosPago.FechaPago,
    }
    return DataDRequest._Request5(url, "POST", params, null)
}

interface IEgresoDocumentacionInfo {
    IdArchivo: number;
    Nombre: string;
    Ext: string;
}
const REQUEST_GETINFO_MAP = new Map<number, Promise<DataDRequest.IRequestResponseA<IEgresoDocumentacionInfo[]>>>();
export function _ObtenerInfoArchivos(idMovimiento: number) {
    if (REQUEST_GETINFO_MAP.has(idMovimiento)) {
        return REQUEST_GETINFO_MAP.get(idMovimiento);
    }
    else {
        const url = URL_EGRESOMOVIMIENTO + "ObtenerInfoArchivos";
        const params = {
            "IdMovimiento": idMovimiento,
        }
        const req = DataDRequest._Request5<IEgresoDocumentacionInfo[]>(url, "GET", params, null);
        REQUEST_GETINFO_MAP.set(idMovimiento, req);
        req.finally(() => {
            REQUEST_GETINFO_MAP.delete(idMovimiento);
        })
        return req;
    }
}

export function _URLArchivo(idArchivo: number) {
    return URL_EGRESOMOVIMIENTO + `ObtenerArchivo?IdArchivo=${idArchivo}`;
}

export function _ObtenerArchivo(idMovimiento: number, filename: string) {
    const url = _URLArchivo(idMovimiento);
    return new Promise<DataDRequest.IRequestResponseA<File>>(resolve => {
        DataDRequest._RequestBlobFromUrlResource(url, (fileBlob, err) => {
            resolve({
                Resultado: !err ? 1 : -1,
                Datos: !err ? new File([fileBlob], filename) : null,
            })
        })
    })
}

export function _ActualizarDocumentacion(idMovimiento: number, newFiles: File[], eliminarArchivos: number[]) {
    const url = URL_EGRESOMOVIMIENTO + "ActualizarDocumentacion";
    const formData = new FormData();
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario + "");
    formData.append("IdMovimiento", idMovimiento + "");
    formData.append("IdArchivosEliminar", (eliminarArchivos || []).toString());
    newFiles.forEach((file) => {
        formData.append("Archivo", file);
    })

    return DataDRequest._XMLHttpRequestFormHandler(url, "POST", formData)
}

export function _EliminarEgreso(id: number) {
    const url = URL_EGRESOMOVIMIENTO + "EliminarEgreso";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Id": id,
    };
    return DataDRequest._Request5(url, "POST", params);
}

export default {
    _NuevoEgreso,
    _ActualizarEgreso,
    _GetMovimientos,
    _NuevoPago,
    _NuevoPagoAgrupado,
    _ObtenerInfoArchivos,
    _URLArchivo,
    _ObtenerArchivo,
    _ActualizarDocumentacion,
    _EliminarEgreso
}
