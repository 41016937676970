import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { Entidad } from "../../data/Entidad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { DataDRequest } from "../../data/DRequest";
import { DataModuloMain } from "../../data/ModuloMain";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { Table } from "../controlD3/Tabla";
import { _SvAgregarNuevaInformacionGeneral, _DiccEscuelaInfoGeneral, _SvEditarInformacionGeneral, _SvEliminarInformacionGeneral, _SvGetInfoArchivos, _SvGetUrlObtenerArchivo, _SvGetListEscuelaInfoGeneral } from "../../data/modulo/EscuelaInformacionGeneral";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { SelectV2 } from "../controlD3/SelectV2";
import { UIUtilGeneral } from "../util/Util";
import _L from "../../util/Labels";
import { DateV2 } from "../../util/DateV2";
import { UIUtilTime } from "../util/Time";
import { UIUtilIconResources } from "../util/IconResourses";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { FullViewFile } from "../controlD3/FullViewFile";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import { ExcelThings } from "../controlD3/ExcelExport";

import IEscuela = Entidad.IEscuela;
import IEscuelaInfoGral = Entidad.IEscuelaInfoGral;
import IInfoArchivo = Entidad.IInfoArchivo;
import { ModalThings } from "../controlD3/ModalThings";

interface IEscuelaInfoGralGrid extends IEscuelaInfoGral {
    File: string
    StrNiveles: string;
    StrGrados: string;
    StrGrupos: string;
    Grupos: Entidad.IGrupo[];
}

export interface IEscuelaInfoGralForm extends IEscuelaInfoGral {
    Archivo: string | File;
}

export class UIPanelCardEscuelasInfoGral extends CardV2CollapseAdvancedTable<IEscuelaInfoGralGrid, [IEscuela]> {
    private infoAndDocumentsMap: Map<number, IEscuelaInfoGralGrid[]>;

    constructor() {
        super("", Entidad.CModulo.PanelEscuelaInformacionGeneral);
        this.infoAndDocumentsMap = new Map<number, IEscuelaInfoGralGrid[]>();
    }

    protected Table_GetMenuDataSelected(): Array<Table.ITableMenuDataSelectedOptionConfig<IEscuelaInfoGralGrid>> {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IEscuelaInfoGralGrid>> = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "action_editar",
                Callback: (datos) => this.UI_OpenModal_FormularioEditar({ ...datos[0], Archivo: null }),
                MultiData: false
            })
        }

        if (this.HasActionPermission(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "action_eliminar",
                Callback: (datos) => this.UI_OpenModalEliminarDatos(datos),
            })
        }

        return opciones;
    }

    protected CARDCOLL_MostrarBody() {
        this.cardSelection.style("height", "100%");
        this.UI_UpdateCardData(true)
        return this;
    }

    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null);
    }

    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        return null;
    }
    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div">): void {
        this.cardFooterSelection.remove();
        this.btnEditarCard._d3Selection.remove();
        this.cardHeaderSelection.remove();
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: Entidad.IEscuela): string {
        return null;
    }
    protected CARDCOLL_OnUpdateData(cardData_0: Entidad.IEscuela): void | Promise<any> {
        return this.UI_UpdateCardData(true);
    }

    protected CARDCOLL_SyncOrGetIdToDownloadData(): (() => Promise<any>) | (DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[]) {
        return () => this.UI_UpdateCardData();
    }

    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IEscuelaInfoGralGrid[]): IConfigCardV2CollapseExcelExport<IEscuelaInfoGralGrid | any> | Promise<IConfigCardV2CollapseExcelExport<IEscuelaInfoGralGrid | any>> {
        type IDataToExport = Partial<IEscuelaInfoGralGrid>;
        const escuela = this.cardData[0];
        let tableColumns: ExcelThings.IColumnToExcelExportFileConfig<IDataToExport>[] = this.ctrlTabla._InfoColumns
            .filter(d => (d.Field != "File"))
            .map(d => ({
                Field: d.Field,
                HeaderTag: d.Label,
                WidthCell: 30
            }))

        return {
            FileName: escuela.Nombre + " - " + this.CARDCOLL_GetUIStringModule("tag_informacionydocumentos"),
            IdsEscuelas: [escuela.IdKinder],
            TypeRequest: Entidad.CTipoRequest.Escuela,
            ColumnsConfig: tableColumns,
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: escuela.IdKinder,
                    SheetName: escuela.Nombre,
                    Data: dataTable
                }]
            },
            OnGetEscuelasTagInSheet: () => DataModuloEscuela._DiccEscuela.get(escuela.IdKinder).Nombre,
        }
    }

    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IEscuelaInfoGralGrid>, "Parent"> {
        return {
            IdTabla: "EscuelasPanelInfoGral",
            Title: "",
            IdData: "Id",
            OrderDefault: { Type: Table.CStatusOrder.Asc, Field: "Titulo" },
            MinWidth: 600,
            RenderColumnHeadings: [
                { Field: "File", Label: "", Width: "5%", MinWidth: "50px", IsSortable: false, Align: "center", Icon: UIUtilIconResources.CGeneral.Circular2 },
                { Field: "Titulo", Label: "", Width: "15%", MinWidth: "75px", OrderTypeParse: String },
                { Field: "Descripcion", Label: "", Width: "25%", MinWidth: "100px", OrderTypeParse: String },
                { Field: "StrFechaInicio", Label: "", Width: "10%", MinWidth: "100px", OrderField: "Inicio", OrderTypeParse: Date },
                { Field: "StrNiveles", Label: "", Width: "15%", MinWidth: "100px" },
                { Field: "StrGrados", Label: "", Width: "15%", MinWidth: "100px" },
                { Field: "StrGrupos", Label: "", Width: "15%", MinWidth: "100px" }
            ],
            FilterParameters: [
                { LabelLangKey: "d_field_titulo", Field: "Titulo" },
                { LabelLangKey: "d_field_descripcion", Field: "Descripcion" },
                { LabelLangKey: "d_field_strfechainicio", Field: "Inicio", Type: "date", OnGetValueToMatch: (dato) => [new DateV2(dato.Inicio)._SetTimeZoneByIdSchool(dato.IdEscuela)] },
                { Label: _L("general.nivel"), Field: "StrNiveles", Type: "text", OnGetValueToMatch: (dato) => dato.StrNiveles.split(",\n") },
                { Label: _L("general.grado"), Field: "StrGrados", Type: "text", OnGetValueToMatch: (dato) => dato.StrGrados.split(",\n") },
                { Label: _L("general.grupo"), Field: "StrGrupos", Type: "text", OnGetValueToMatch: (dato) => dato.StrGrupos.split(",\n") }
            ],
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 3,
                Options: []
            },
            OptionsOfDataCheckV3: () => {
                if (this.cardData?.length) {
                    return this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "top-selected", this.Table_GetMenuDataSelected())
                }
                return null;
            },
            EvaluatorAndSubLevelsBuild: {
                GetOptionsInRowV2: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "row", this.Table_GetMenuDataSelected()),
                OnStepCellTable: (container, datum, field, step) => {
                    switch (field) {
                        case "File":
                            this.UI_StepContentIcoElement(container, datum)
                            break;
                        case "StrGrupos":
                            if (!datum.Grupos || !datum.Grupos.length) {
                                container.text(_L("panelescuelainformaciongeneral.tag_all"));
                                break;
                            }
                            UIUtilGeneral._CreaElementosLinkeablesV2({
                                Container: container,
                                Data: Array.from(datum.Grupos.values()),
                                Path: "escuelas/grupos/panel",
                                GetTag: (d) => UIUtilViewGrupos._GetLblGrupoName(d, false, true),
                                GetId: "IdGrupo",
                                Direction: "vertical"
                            })
                            break;
                    }
                },
            },
        }
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "action_agregar",
                Callback: async () => this.UI_OpenModal_FormularioAgregar()
            })
        }

        return opciones;
    }

    protected CARDCOLL_GetIdSchool(cardData_0_0: Entidad.IEscuela): number {
        return cardData_0_0.IdKinder;
    }

    private UI_UpdateCardData(showProgress = false) {
        if (!this.CARDCOLL_StatusCardExpandido || this.cardData == undefined) {
            this.ctrlTabla._UpdateData([]);
            return null;
        }

        return new Promise<void>(async (resolve, reject) => {
            if (showProgress) {
                this.ctrlProgress.attr("oculto", false);
            }

            const res = await _SvGetListEscuelaInfoGeneral(this.CARDCOLL_GetIdSchool(this.cardData[0]));
            if (res.Resultado > 0) {
                const data = res.Datos;
                const dataGrid = data.map<IEscuelaInfoGralGrid>((d) => ({
                    ...d,
                    File: null,
                    StrFechaInicio: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.Inicio, d.IdEscuela, "dd/mm/yyyy"),
                    StrNiveles: (!d.IdsNiveles) ? _L("panelescuelainformaciongeneral.tag_all") : d.IdsNiveles.map<string>(f => DataModuloMain._GetDataValueFieldByName("Escolaridad", f, "Nombre")).join(",\n"),
                    StrGrados: (!d.IdsGrados) ? _L("panelescuelainformaciongeneral.tag_all") : d.IdsGrados.map<string>(f => DataModuloMain._GetDataValueFieldByName("Grado", f, "Nombre")).join(",\n"),
                    StrGrupos: (!d.IdsGrupos) ? _L("panelescuelainformaciongeneral.tag_all") : d.IdsGrupos.map<string>(f => DataModuloMain._GetDataValueFieldByName("Grupo", f, "Nombre")).join(",\n"),
                    Grupos: (!d.IdsGrupos) ? [] : d.IdsGrupos.map<Entidad.IGrupo>(idGrupo => DataModuloMain._GetItemDataByName("Grupo", idGrupo)).filter(grupo => Boolean(grupo))
                }))
                this.infoAndDocumentsMap.set(this.CARDCOLL_GetIdSchool(this.cardData[0]), dataGrid);
            } else {
                this.ctrlNotification._Mostrar(_L("general.notif_fail_infosync"), "ADVERTENCIA")
            }

            const dataTable = this.infoAndDocumentsMap.get(this.CARDCOLL_GetIdSchool(this.cardData[0]))
            this.ctrlTabla._UpdateData(dataTable);

            if (showProgress) {
                this.ctrlProgress.attr("oculto", true)
            }

            resolve();
        })
    }

    /* private DATA_GetInformacionGeneral(): IEscuelaInfoGralGrid[] {
        if (this.cardData[0]) {
            return DataModuloMain._GetReqDataArrayByName("EscuelaInformacionGeneral")
                .filter(d => (d.IdEscuela == this.cardData[0].IdKinder))
                .map<IEscuelaInfoGralGrid>((d) => ({
                    ...d,
                    File: null,
                    StrNiveles: (!d.IdsNiveles) ? _L("panelescuelainformaciongeneral.tag_all") : d.IdsNiveles.map<string>(f => DataModuloMain._GetDataValueFieldByName("Escolaridad", f, "Nombre")).join(",\n"),
                    StrGrados: (!d.IdsGrados) ? _L("panelescuelainformaciongeneral.tag_all") : d.IdsGrados.map<string>(f => DataModuloMain._GetDataValueFieldByName("Grado", f, "Nombre")).join(",\n"),
                    StrGrupos: (!d.IdsGrupos) ? _L("panelescuelainformaciongeneral.tag_all") : d.IdsGrupos.map<string>(f => DataModuloMain._GetDataValueFieldByName("Grupo", f, "Nombre")).join(",\n"),
                    Grupos: (!d.IdsGrupos) ? [] : d.IdsGrupos.map<Entidad.IGrupo>(idGrupo => DataModuloMain._GetItemDataByName("Grupo", idGrupo)).filter(grupo => Boolean(grupo))
                }))
        }
        return [];
    } */

    private UI_OpenModal_FormularioAgregar() {
        const Escuela = this.cardData[0];

        ModalThings._GetModalToForm({
            Title: "action_agregar",
            Action: Entidad.CAccionPermiso.Agregar,
            Modulo: this.modulo,
            IdsEscuelas: [Escuela.IdKinder],
            GetForm: () => this.UI_GETInformacionGeneralForm(null, Entidad.CAccionPermiso.Agregar),
            OnAccept: async (form) => {
                const [idsLevelsValid, idsGradesValid, idsGroupsValid] = this.DATA_GetInfoGeneralReceiver(form)
                return this.SV_AgregarInfoGral(
                    Escuela,
                    {
                        ...form._Data,
                        IdsNiveles: idsLevelsValid,
                        IdsGrados: idsGradesValid,
                        IdsGrupos: idsGroupsValid,
                    }
                )
            }
        })
    }

    private UI_OpenModal_FormularioEditar(dato: IEscuelaInfoGralForm) {
        const Escuela = this.cardData[0];

        ModalThings._GetModalToForm({
            Title: "action_editar",
            Action: Entidad.CAccionPermiso.Editar,
            Modulo: this.modulo,
            IdsEscuelas: [Escuela.IdKinder],
            GetForm: () => this.UI_GETInformacionGeneralForm(dato, Entidad.CAccionPermiso.Editar),
            OnAccept: async (form, modalThings) => {
                const formData = form._Data;
                const [idsLevelsValid, idsGradesValid, idsGroupsValid] = this.DATA_GetInfoGeneralReceiver(form)
                let res = await this.SV_EditarInfoGeneral(
                    Escuela,
                    {
                        ...formData,
                        IdsNiveles: idsLevelsValid,
                        IdsGrados: idsGradesValid,
                        IdsGrupos: idsGroupsValid,
                        Archivo: form["__FileChange"] ? formData.Archivo : null
                    }
                )

                if (res.Resultado == -1) {
                    modalThings.Modal._Ocultar();
                }
                return res;
            }
        })
    }

    private DATA_GetInfoGeneralReceiver(form: FormGenerator<IEscuelaInfoGralForm>) {
        const formData = form._Data;

        let idsGroupsValid: number[] = formData.IdsGrupos;
        let idsGradesValid: number[] = formData.IdsGrados;
        let idsLevelsValid: number[] = formData.IdsNiveles;

        let comboGroups = form._ControlsData.get("IdsGrupos").instance as SelectV2<Entidad.IGrupo, "IdGrupo">;
        let groupsInSelect = comboGroups._dataitems.map<number>(group => group.IdGrupo);
        let isAllGroups = groupsInSelect.length == formData.IdsGrupos.length;

        if (isAllGroups) {
            idsGroupsValid = [];
        } else {
            idsGradesValid = Array.from(new Set(comboGroups._dataSelected.map<number>(group => group.IdNivel)));
        }

        let comboGrades = form._ControlsData.get("IdsGrados").instance as SelectV2<Entidad.IGrado, "IdNivel">;
        let gradesInSelect = comboGrades._dataitems.map<number>(grade => grade.IdNivel);
        let isAllGrades = (gradesInSelect.length == idsGradesValid.length && isAllGroups);

        if (isAllGrades) {
            idsGradesValid = [];
        } else {
            const GradesSelectedValids = comboGrades._dataSelected.filter(grade => idsGradesValid.some(idGradeValid => idGradeValid == grade.IdNivel))
            idsLevelsValid = Array.from(new Set(GradesSelectedValids.map(gradeValid => gradeValid.IdEscolaridad)));
        }

        let comboLevels = form._ControlsData.get("IdsNiveles").instance as SelectV2<Entidad.IEscolaridad, "Id">;
        let levelsInSelect = comboLevels._dataitems.map<number>(level => level.Id);
        let isAllLevels = (levelsInSelect.length == idsLevelsValid.length && isAllGrades);

        if (isAllLevels) {
            idsLevelsValid = []
        }

        return [idsLevelsValid, idsGradesValid, idsGroupsValid]
    }

    private UI_OpenModalEliminarDatos(infoDelete: IEscuelaInfoGralGrid[]) {
        let forceUpdate = false;
        this.CARDCOLLAD_OpenModal_ProccessArrayData<IEscuelaInfoGralGrid>({
            DataToProccess: infoDelete,
            TypeRequest: null,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnStepAProccess: async (dato) => {
                let res = await this.SV_EliminarInfoGral(dato);
                if (!forceUpdate && res.Resultado == -1) forceUpdate = true;
                return res
            },
            OnEndAndCloseProccess: async (correctos, incorrectos) => {
                if (correctos.length || forceUpdate) {
                    this.UI_UpdateCardData(true)
                }
            },
            OnError_GetItemDataTag: (itemData) => itemData.Titulo,
        })
    }

    private UI_GETInformacionGeneralForm(escuelaInformacionGeneral: IEscuelaInfoGralForm, action: (Entidad.CAccionPermiso.Agregar | Entidad.CAccionPermiso.Editar)): FormGenerator<IEscuelaInfoGralForm> {
        const Levels = this.DATA_GetLevelsInSchool();
        const isEdition = action == Entidad.CAccionPermiso.Editar;

        let infoGeneralToForm: IEscuelaInfoGralForm = Object.assign(<IEscuelaInfoGralForm>{
            IdsGrados: [],
            IdsNiveles: [],
            IdsGrupos: [],
        }, escuelaInformacionGeneral)

        infoGeneralToForm.Inicio = infoGeneralToForm.Inicio
            ? UIUtilTime._FmtToInputDate(infoGeneralToForm.Inicio, infoGeneralToForm.IdEscuela)
            : !isEdition
                ? UIUtilTime._FmtToInputDate(new Date())
                : infoGeneralToForm.Inicio;

        let form = new FormGenerator<IEscuelaInfoGralForm>();
        let scheme: IField<IEscuelaInfoGralForm>[];

        scheme = [
            {
                model: "Titulo",
                labelText: "d_field_titulo",
                type: Fields.input,
                inputAttr: { type: "text", maxlength: 120, required: true },
            },
            {
                model: "Descripcion",
                labelText: "d_field_descripcion",
                type: Fields.input,
                inputAttr: { type: "text", maxlength: 120 }
            },
            {
                model: "Archivo",
                labelText: "tag_file",
                type: Fields.fotoV2,
                fotoV2Attrs: {
                    ControlForm: "square_roundededges",
                    TypeValue: "File",
                    AspectRatio: "contain",
                    FilesAccept: [
                        "image/jpg", "image/png", "image/jpeg",
                        "application/pdf"
                    ],
                    OnChange: (_, isFromInput) => {
                        if (isFromInput) {
                            form["__FileChange"] = true;
                        }
                    },
                }
            },
            {
                model: "Inicio",
                labelText: "d_field_strfechainicio",
                type: Fields.input,
                inputAttr: { type: "date", required: true, min: UIUtilTime._FmtToInputDate(this.CALC_MinDate(escuelaInformacionGeneral)) }
            },
            {
                model: "IdsNiveles",
                labelText: "d_field_strniveles",
                type: Fields.selectMaterial,
                selectMaterialAttr: {
                    valueMember: "Id", displayMember: "Nombre",
                    Data: Levels,
                    multiselect: true,
                    ShowNSelectedInList: true,
                    required: true,
                    onSelect: (levels: Entidad.IEscolaridad[]) => {
                        let comboGrades = form._ControlsData.get("IdsGrados").instance as SelectV2<Entidad.IGrado, "IdNivel">;
                        comboGrades._UpdateList(this.DATA_GetGradesInLevels(levels));
                    },
                },
            },
            {
                model: "IdsGrados",
                labelText: "d_field_strgrados",
                type: Fields.selectMaterial,
                selectMaterialAttr: {
                    valueMember: "IdNivel", displayMember: "Nombre",
                    multiselect: true,
                    ShowNSelectedInList: true,
                    required: true,
                    onSelect: (grades: Entidad.IGrado[]) => {
                        let comboGroups = form._ControlsData.get("IdsGrupos").instance as SelectV2<Entidad.IGrupo, "IdGrupo">;
                        comboGroups._UpdateList(this.DATA_GetGroupsInGrades(grades));
                    },
                    OnStepItemListUI: (container, dato: Entidad.IGrado, step) => {
                        if (step == "enter") {
                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                            container.style("align-items", "flex-start");
                        }
                        let html = `<label>${(form._Data.IdsNiveles.length > 1) ? _L("general.grado") + ": " : ""}${dato.Nombre}</label>`;
                        if (form._Data.IdsNiveles.length > 1) html += `<label style='font-size: var(--fontsize_me4)'>${_L("general.nivel")}: ${DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad)?.Nombre || _L("general.nodisponible")}</label>`;
                        container.html(html);
                    },
                }
            },
            {
                model: "IdsGrupos",
                labelText: "d_field_strgrupos",
                type: Fields.selectMaterial,
                selectMaterialAttr: {
                    valueMember: "IdGrupo", displayMember: "Nombre",
                    multiselect: true,
                    ShowNSelectedInList: true,
                    required: true,
                    OnStepItemListUI: (container, dato: Entidad.IGrupo, step) => {
                        if (step = "enter") {
                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                        }
                        let html = `<label>${(form._Data.IdsGrados.length > 1) ? _L("general.grupo") + ": " : ""}${dato.Nombre}</label>`;
                        if (form._Data.IdsGrados.length > 1) {
                            html += `<label style='font-size: var(--fontsize_me4)'>${_L("general.grado")}: ${DataModuloGrado._DiccGrado.get(dato.IdNivel).Nombre}</label>`
                            if (form._Data.IdsNiveles.length > 1) {
                                html += `<label style='font-size: var(--fontsize_me4)'>${_L("general.nivel")}: ${DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad).Nombre}</label>`
                            }
                        }
                        container.html(html)
                    }
                }
            }
        ]

        form._Crear({
            LangModuleKeyInContext: this.moduloName,
            LabelMaxWidth: 120,
            schema: scheme,
            Validation: (_, field, dataForm) => {
                switch (field) {
                    case "Archivo":
                    case "Descripcion":
                        if (!dataForm.Descripcion.trim() && !dataForm.Archivo) {
                            return false;
                        }
                }
                return true;
            }
        }, infoGeneralToForm)

        if (isEdition) {
            let archivoComponent = form._ControlsData.get("Archivo").instance as InputFileControl.InputFile;
            this.UI_UpdateFileControlAndInfoGralData(escuelaInformacionGeneral.Id, archivoComponent, 3, false, true, escuelaInformacionGeneral.Modificacion)
                .then(resFileInfo => {
                    if (resFileInfo.Resultado < 0) {
                        this.ctrlNotification._Mostrar(_L("panelescuelainformaciongeneral.notif_error_file"), "ADVERTENCIA");
                    }
                });

            const ComboLevels = form._ControlsData.get("IdsNiveles").instance as SelectV2;
            const ComboGrades = form._ControlsData.get("IdsGrados").instance as SelectV2;
            const ComboGroups = form._ControlsData.get("IdsGrupos").instance as SelectV2;
            if (!infoGeneralToForm.IdsNiveles || !infoGeneralToForm.IdsNiveles.length) {
                ComboLevels._SelectAll(true);
            }
            if (!infoGeneralToForm.IdsGrados || !infoGeneralToForm.IdsGrados.length) {
                ComboGrades._SelectAll(true);
            }
            if (!infoGeneralToForm.IdsGrupos || !infoGeneralToForm.IdsGrupos.length) {
                ComboGroups._SelectAll(true);
            }
        }

        return form
    }

    private CALC_MinDate(escuelaInformacionGeneral: IEscuelaInfoGralForm): Date {
        if (!escuelaInformacionGeneral?.Inicio) {
            return new Date()
        }

        return escuelaInformacionGeneral.Inicio > new DateV2(new Date())._SetTimeZoneByIdSchool(escuelaInformacionGeneral.IdEscuela)._ToISOString()
            ? new Date()
            : new DateV2(escuelaInformacionGeneral.Inicio)._SetTimeZoneByIdSchool(escuelaInformacionGeneral.IdEscuela)
    }

    private DATA_GetLevelsInSchool() {
        const SchoolId = this.CARDCOLL_GetIdSchool(...this.cardData)
        return Array.from(DataModuloEscolaridad._DiccEscolaridad.values()).filter(escolaridad => (escolaridad.IdEscuela == SchoolId));
    }

    private DATA_GetGradesInLevels(levels: Entidad.IEscolaridad[]) {
        return Array.from(DataModuloGrado._DiccGrado.values())
            .filter(grado => levels.find(escolaridad => escolaridad.Id == grado.IdEscolaridad));
    }

    private DATA_GetGroupsInGrades(grades: Entidad.IGrado[]) {
        return Array.from(DataModuloGrupo._DiccGrupo.values())
            .filter(grupo => (grades.find(grado => grado.IdNivel == grupo.IdNivel)));
    }

    private async SV_AgregarInfoGral(escuela: IEscuela, dataForm: IEscuelaInfoGralForm) {
        let fechaInicio: string = null;

        if (dataForm.Inicio) {
            fechaInicio = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataForm.Inicio))
                ._SetTimeZoneByIdSchool(escuela.IdKinder, true)
                ._ToISOString();
        }

        const Res = await _SvAgregarNuevaInformacionGeneral(<IEscuelaInfoGral>{
            Titulo: dataForm.Titulo,
            Descripcion: dataForm.Descripcion,
            IdEscuela: escuela.IdKinder,
            Inicio: fechaInicio,
            IdsNiveles: dataForm.IdsNiveles,
            IdsGrados: dataForm.IdsGrados,
            IdsGrupos: dataForm.IdsGrupos,
        }, dataForm.Archivo as File)

        if (Res.Resultado > 0) {
            this.UI_UpdateCardData(true);
        }

        return Res;
    }

    private async SV_EditarInfoGeneral(escuela: IEscuela, dataForm: IEscuelaInfoGralForm) {
        let fechaInicio: string = null;

        if (dataForm.Inicio) {
            fechaInicio = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataForm.Inicio))
                ._SetTimeZoneByIdSchool(escuela.IdKinder, true)
                ._ToISOString();
        }

        const Res = await _SvEditarInformacionGeneral(<IEscuelaInfoGral>{
            Id: dataForm.Id,
            Titulo: dataForm.Titulo,
            Descripcion: dataForm.Descripcion,
            IdEscuela: escuela.IdKinder,
            Inicio: fechaInicio,
            IdsNiveles: dataForm.IdsNiveles,
            IdsGrados: dataForm.IdsGrados,
            IdsGrupos: dataForm.IdsGrupos,
        }, dataForm.Archivo as File)

        if (Res.Resultado > 0 || Res.Resultado == -1) {
            this.UI_UpdateCardData(true);
        }

        return Res;
    }

    private SV_EliminarInfoGral(infoGral: IEscuelaInfoGralGrid) {
        return _SvEliminarInformacionGeneral(infoGral.Id);
    }

    private UI_StepContentIcoElement(container: TSelectionHTML<"div">, datum: IEscuelaInfoGralGrid, modulo?: Entidad.CModulo) {
        let ctrlArchivo = container.node()["_infoIcon"] as InputFileControl.InputFile & {
            __idInfoGral?: number;
        }

        if (!ctrlArchivo) {
            container.style("overflow", "visible");
            ctrlArchivo = new InputFileControl.InputFile({
                ControlForm: "none",
                Dimentions: 35,
                DefaultBackgroundImage: UIUtilIconResources.CGeneral.Circular2,
                ShowAreaControlOptions: false,
                SpinnerWidth: 3,
            })

            ctrlArchivo._SpinnerControl.node()._Dim = 25;
            ctrlArchivo._ControlNode.style.margin = "auto";
            ctrlArchivo["__idInfoGral"] = datum.Id;
            ctrlArchivo["__Modificacion"] = datum.Modificacion;
            container.append(() => ctrlArchivo._ControlNode);
            container.node()["_infoIcon"] = ctrlArchivo;
        }

        if (!ctrlArchivo._AuxExtensionFile || (ctrlArchivo["__idInfoGral"] != datum.Id) || (ctrlArchivo["__Modificacion"] != datum.Modificacion)) {
            this.UI_UpdateFileControlAndInfoGralData(datum.Id, ctrlArchivo, 3, true, false, datum.Modificacion);
        }

        ctrlArchivo["__idInfoGral"] = datum.Id;
        ctrlArchivo["__Modificacion"] = datum.Modificacion;

        ctrlArchivo._OnClickImageArea(async () => {
            if (ctrlArchivo["_NoFile"]) {
                return;
            }
            const FullView = new FullViewFile();
            FullView._ShowSpinner();
            const resInfoArchivo = await _SvGetInfoArchivos(datum.Id, datum.Modificacion);
            if (resInfoArchivo.Resultado <= 0) {
                FullView._Destroy();
                this.ctrlNotification._Mostrar(_L("panelescuelainformaciongeneral.notif_error_file"), "ADVERTENCIA");
                return;
            }
            const fileInfo = resInfoArchivo.Datos[0];
            FullView._SetContent([{
                Id: fileInfo.IdArchivo,
                Filename: datum.Titulo + fileInfo.Extension,
                Content: () => _SvGetUrlObtenerArchivo(fileInfo.IdArchivo, 1, datum.Modificacion),
                Download: () => _SvGetUrlObtenerArchivo(fileInfo.IdArchivo, 1, datum.Modificacion),
            }])
        })
    }

    private UI_UpdateFileControlAndInfoGralData(idInfoGral: number, fileControl: InputFileControl.InputFile, size: 1 | 2 | 3, emptyUseDefaultImage = false, isFromForm = false, modificacion: string) {
        return new Promise<DataDRequest.IRequestResponseBase<IInfoArchivo[]>>((resolve, reject) => {
            fileControl._SpinnerLoadVisible = true;
            _SvGetInfoArchivos(idInfoGral, modificacion)
                .then(resInfoFile => {
                    fileControl._SpinnerLoadVisible = false;
                    fileControl["_NoFile"] = false;
                    let styleCircle = false;
                    if (resInfoFile.Resultado <= 0) {
                        if (emptyUseDefaultImage) {
                            fileControl._UrlResource = UIUtilIconResources.CGeneral.Circular2;
                            fileControl["_NoFile"] = true
                        } else {
                            fileControl._Reset();
                        }
                        fileControl._DefaultBackgroundText = "Error";
                        fileControl._ChangeForm("None");
                        resolve(resInfoFile);
                        return;
                    }

                    fileControl._DefaultBackgroundText = "";
                    if (resInfoFile.Datos.length) {
                        const fileInfo = resInfoFile.Datos[0];
                        if (fileInfo.MimeType.startsWith("image")) {
                            fileControl._UrlResource = _SvGetUrlObtenerArchivo(fileInfo.IdArchivo, size, modificacion)
                            styleCircle = true;
                        } else if (isFromForm) {
                            fileControl._UrlResource = _SvGetUrlObtenerArchivo(fileInfo.IdArchivo, size, modificacion)
                        } else {
                            fileControl._Reset();
                        }
                        fileControl._AuxExtensionFile = fileInfo.Extension;
                    } else {
                        if (emptyUseDefaultImage) {
                            fileControl._UrlResource = UIUtilIconResources.CGeneral.Circular2;
                            fileControl["_NoFile"] = true
                        } else {
                            fileControl._Reset();
                        }
                        fileControl._AuxExtensionFile = "nofile";
                    }

                    fileControl._ChangeForm((isFromForm || styleCircle) ? "Semicuadrado" : "None");
                    resolve(resInfoFile);
                })
        })
    }
}